import * as React from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import './NotFoundPage.css';
import { useLocation, useNavigate } from 'react-router';

export default function NotFoundPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const handleDashboardClick = (e) => {
    e.preventDefault();
    navigate('/dashboard');
  };

  return (
    <div className="not-found-card-container">
      <Card>
        <CardContent className="not-found-card-content">
          <div className="not-found-logo-container">
            <img src={process.env.PUBLIC_URL + '/IMPORTAL-2.png'} width="166" height="104" alt="logo" />
          </div>
          <div className="not-found-header-question">
            <Typography style={{ textAlign: 'center' }}>Looks like this page doesn't exist yet.</Typography>
          </div>
          <div className="my-shipments-subheader">
            Check the URL and try again or feel free to head back to the{' '}
            <a className="back-to-dashboard" onClick={handleDashboardClick} href="/dashboard">
              dashboard
            </a>
            .
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
