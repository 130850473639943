import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import SavedCardList from '../plans-and-pricing/SavedCardList';

import './VMCRReviewOrder.css';
import React from 'react';

const VMCRReviewOrder = ({ setActiveStep, setOpen, upgradeRef, open, plan, singlePlanDetail }) => {
  return (
    <Box>
      <div className="vmcr-review-title">{singlePlanDetail.key}</div>
      <div style={{ color: 'grey' }}>
        {singlePlanDetail.advertisedOneTimePrice && (
          <Typography variant="subtitle1">${singlePlanDetail.advertisedOneTimePrice}</Typography>
        )}
        {singlePlanDetail.advertisedMonthlyPrice && (
          <Typography variant="subtitle1">${singlePlanDetail.advertisedMonthlyPrice}/month</Typography>
        )}
        {singlePlanDetail.advertisedYearlyPrice && (
          <Typography variant="subtitle1">${singlePlanDetail.advertisedYearlyPrice}/year</Typography>
        )}
      </div>
      <div className="vmcr-enter-payment-container">
        <Paper
          sx={{
            mt: 2,
            width: '553px',
            background: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div className="vmcr-order-line">Enter your credit card information below, and we'll get rolling.</div>

          <SavedCardList
            setOpen={setOpen}
            open={open}
            plan={plan}
            upgradeRef={upgradeRef}
            setActiveStep={setActiveStep}
          />
        </Paper>
      </div>
    </Box>
  );
};

export default VMCRReviewOrder;
