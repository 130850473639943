import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import React from 'react';
import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import InfoIcon from '@mui/icons-material/Info';
import CountrySelect from '@/components/country-autocomplete/CountryAutocomplete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
  selectedCountryCode?: string;
  onCountryCodeSelected: (countryCode?: string) => void;
  onNext: () => void;
  onBack: () => void;
}

export default function CountryOfOrigin({ selectedCountryCode, onCountryCodeSelected, onNext, onBack }: Props) {
  const theme = useTheme();

  const inputIsValid = (): boolean => {
    return !!selectedCountryCode;
  };

  return (
    <Card className="card-container">
      <CardContent>
        {/*TODO This false was 'custom', hmm? */}
        <div className={`${false && 'custom-content-body'} `}>
          <div className="header-question">
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '36px',
                marginTop: '15px',
              }}
            >
              What's your product's country of origin?
            </Typography>
          </div>
          <div className="unlading-descriptive-text">
            Country of origin means the country of manufacture, production, or growth of any article of foreign origin
            entering the United States.
            <LightTooltip
              placement="right-start"
              title="Country of origin is important to the U.S. government because it can qualify your product for certain trade agreements."
            >
              <div className="tooltip-container">
                <InfoIcon sx={{ fontSize: '16px' }} />
              </div>
            </LightTooltip>
          </div>

          <div className="cargo-value-text-field">
            <CountrySelect selectedCountryCode={selectedCountryCode} handleEvent={onCountryCodeSelected} />
          </div>
          <div className="next-button-container">
            <Button
              fullWidth
              className="back-button-text"
              startIcon={<KeyboardArrowLeftIcon />}
              variant="text"
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              fullWidth
              onClick={onNext}
              className="next-button-green"
              endIcon={<KeyboardArrowRightIcon />}
              variant="contained"
              disabled={!inputIsValid()}
            >
              Next
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
