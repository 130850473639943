import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import './Shipments.css';
import { Route, Routes } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import { Add, GpsFixed } from '@mui/icons-material';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import ShipmentsTable from './ShipmentsTable';
import ShipmentPage from './ShipmentPage';
import CreateShipmentModal from './CreateShipmentModal';
import moment from 'moment';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { useEventBus } from '@/custom-hooks/event-bus/EventBus';
import { EventType, ShipmentChangedEvent } from 'common/eventbus/eventBus';
import { Shipment } from 'common/interfaces/shipment';

export interface IShipmentsContext {
  refreshShipments: () => void;
  loadingStatus?: LoadingStatus;
  loadingText?: string;
  successText?: string;
  errorText?: string;
  setLoadingStatus: (status: LoadingStatus) => void;
  setLoadingText: (text: string) => void;
  setSuccessText: (text: string) => void;
  setErrorText: (text: string) => void;
}

export const ShipmentsContext = createContext<IShipmentsContext>({
  refreshShipments: () => {},
  loadingStatus: LoadingStatus.NOT_LOADING,
  loadingText: '',
  successText: '',
  errorText: '',
  setLoadingStatus: () => {},
  setLoadingText: () => {},
  setSuccessText: () => {},
  setErrorText: () => {},
});

export default function Shipments() {
  const [shipments, setShipments] = useState<Shipment<any>[]>([]);
  const [createShipmentModalOpen, setCreateShipmentModalOpen] = useState(false);
  const [latestReferenceNumber, setLatestReferenceNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const api = useAPI();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const currentMonth = moment().format('MMMM');
  const [monthlyReferenceUsageCount, setMonthlyReferenceUsageCount] = useState(0);

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const eventBus = useEventBus();
  const handleShipmentChanged = useCallback(
    async (event: ShipmentChangedEvent) => {
      // Check if the shipment already exists in the current list
      const hasUpdate = shipments.some((shipment) => shipment._id!.toString() === event.data.shipmentId);

      if (!hasUpdate) return; // If shipment doesn't exist, no need to proceed

      try {
        // Fetch the updated shipment data from the API
        const updatedShipment = await api.getShipmentById(event.data.shipmentId);

        // Update the specific shipment in the state
        setShipments((prevShipments) => {
          return prevShipments.map((shipment) =>
            shipment._id!.toString() === event.data.shipmentId ? updatedShipment.data : shipment
          );
        });

        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText(`${updatedShipment.data.referenceNumber || 'A Shipment'} was Updated`);
      } catch (error) {
        console.error('Error fetching updated shipment:', error);
      }
    },
    [shipments, api]
  );

  useEffect(() => {
    // Subscribe to the event
    // return the result for automatic cleanup the subscription when the component unmounts
    return eventBus.on(EventType.SHIPMENT_CHANGED, handleShipmentChanged);
  }, [eventBus, handleShipmentChanged]);

  const handleCreateShipmentModalClose = () => {
    setCreateShipmentModalOpen(false);
  };

  const handleCreateShipmentModalOpen = () => {
    api.getLatestShipmentReferenceNumber().then((response: any) => {
      setLatestReferenceNumber(response.data.nextReferenceNumber as string);
      setCreateShipmentModalOpen(true);
    });
  };

  const getVizionReferenceUsage = () => {
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getVizionReferenceCountForThisMonth()
      .then((response) => {
        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText('Successfully retrieved Vizion information');
        setMonthlyReferenceUsageCount(response.data.totalReferences);
      })
      .catch((error) => {
        setLoadingStatus(LoadingStatus.ERROR);
        setErrorText('Failed to retrieve Vizion information');
      });
  };

  const refreshShipments = () => {
    setLoadingStatus(LoadingStatus.LOADING);
    setLoadingText('Retrieving shipments...');
    api
      .getShipmentsForBroker()
      .then(function (response) {
        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText('Successfully retrieved shipments');
        setShipments(response.data.userShipments);
      })
      .catch(function (error) {
        setLoadingStatus(LoadingStatus.ERROR);
        console.error('error getting shipments for broker');
        console.error(error); // Consider more advanced error handling or user notifications
      });
  };

  useEffect(() => {
    refreshShipments();
    getVizionReferenceUsage();
  }, []);

  return (
    <ShipmentsContext.Provider
      value={{ refreshShipments, setLoadingStatus, setLoadingText, setErrorText, setSuccessText }}
    >
      <Routes>
        <Route
          index
          element={
            <>
              <div className="product-library-header">
                <div className="business-header-text">
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: '26px',
                    }}
                  >
                    Shipments
                  </Typography>
                  <div className="universal-subheader">
                    Keep track of the customer's products path every step of the way.
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Tooltip
                    title={`Heads up! We've used ${monthlyReferenceUsageCount} of our 300 monthly Vizion references for the month of ${currentMonth}.`}
                  >
                    <GpsFixed sx={{ color: theme.palette.primary.main, fontSize: '24px', marginLeft: '5px' }} />
                  </Tooltip>
                </div>
              </div>
              <div className="shipments-table-container">
                <ShipmentsTable
                  rows={shipments}
                  isLoading={isLoading}
                  createShipmentButton={
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <ImportalPrimaryButton
                        style={{
                          width: '170px',
                          height: '40px',
                          borderRadius: '8px',
                          fontSize: '12px',
                        }}
                        onClick={handleCreateShipmentModalOpen}
                        text="Create Shipment"
                        endIcon={<Add sx={{ fontSize: '12px' }} />}
                      />
                    </div>
                  }
                ></ShipmentsTable>
              </div>
              <CreateShipmentModal
                open={createShipmentModalOpen}
                onClose={handleCreateShipmentModalClose}
                latestReferenceNumber={latestReferenceNumber}
              />
              <DataImportLoader
                loadingState={{ loadingStatus }}
                loadingText={loadingText}
                successText={successText}
                errorText={errorText}
              />
            </>
          }
        />
        <Route path="/*" element={<ShipmentPage />} />
      </Routes>
    </ShipmentsContext.Provider>
  );
}
