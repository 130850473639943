import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import { TableContainer } from '@mui/material';

export interface ActiveJob {
  type: string;
  status: string;
}

interface Props {
  activeJobs: ActiveJob[];
}

export default function ActiveJobsTable({ activeJobs }: Props) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Job Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeJobs.map((activeJob, index) => (
            <TableRow key={index}>
              <TableCell>
                <CircularProgress size={14} style={{ verticalAlign: 'middle' }} />
                <span style={{ marginLeft: '8px' }}>Processing</span>
              </TableCell>
              <TableCell>{activeJob.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
