import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, CardContent, CircularProgress, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ContactUsModal from '@/components/contact-us-modal/ContactUsModal';
import { useAPI } from '@/api/APIContext';
import { datadogRum } from '@datadog/browser-rum';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import './PhoneAuthentication.css';

export default function PhoneAuthentication() {
  const navigate = useNavigate();
  const theme = useTheme();
  const api = useAPI();
  const activeUser = useActiveUser();
  const [digits, setDigits] = useState(['', '', '', '', '']);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLoading = (value) => {
    setIsLoading(value);
  };
  const handleContactImportal = (e) => {
    setOpen(true);
  };

  const handleContactImportalClose = (e) => {
    setOpen(false);
  };

  function Copyright(props: any) {
    const theme = useTheme();
    return (
      <Typography
        variant="body2"
        color={theme.palette.primary.main}
        sx={{ marginTop: '0px' }}
        align="center"
        {...props}
      >
        <Link color="inherit">
          <a style={{ cursor: 'pointer' }} onClick={handleContactImportal}>
            Need Help? Give us a shout
          </a>
        </Link>
      </Typography>
    );
  }

  const authenticateUserCode = (code: any) => {
    if (isNaN(code)) {
      alert('Please enter valid code only digits!');
      console.error(`${code} is not a number!`);
      return;
    }

    let codeObject = { authCode: code };
    handleLoading(true);

    api
      .submitAuthenticationCode(codeObject)
      .then(async (response) => {
        datadogRum.addAction('validate-authentication-code', {});
        api.setToken(response.data.token);

        await activeUser.refreshUser();
        handleLoading(false);
        navigate('/dashboard');
      })
      .catch(function (error) {
        console.error('error submitting auth code');
        console.error(error);
        handleLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let code = Array.from({ length: 5 }, (_, i) => data.get(`digit-${i}`) || '').join('');
    authenticateUserCode(code);
  };

  const autoTab = (e: any, id: string) => {
    if (e.target.value.length === 1) {
      document.getElementById(id)?.focus();
    }
  };

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();
      const pasteCode = event.clipboardData ? event.clipboardData.getData('text') : '';

      const newDigits = pasteCode.split('');
      setDigits(newDigits);

      newDigits.forEach((digit, index) => {
        const inputElement = document.getElementById(`digit-${index}`) as HTMLInputElement | null;
        if (inputElement) {
          inputElement.value = digit;
        }
      });
    };

    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <div>
      <Container sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <img src="importal-2.png" height="90" width="140" alt="logo" />
      </Container>

      <div className="card-container-signup">
        <Card>
          <CardContent>
            <div className="header-question">
              <div className="authentication-title">
                <Typography>An authentication code has been sent to your email</Typography>
              </div>
              <div className="code-form">
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid className="input-grid-container" container justifyContent="center">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Grid
                        sx={{
                          maxWidth: '75px',
                          '@media (max-width: 480px)': {
                            maxWidth: '50px',
                          },
                        }}
                      >
                        <TextField
                          autoComplete="family-name"
                          name={`digit-${index}`}
                          required
                          fullWidth
                          id={`digit-${index}`}
                          onChange={(e) => {
                            autoTab(e, `digit-${index + 1}`);
                          }}
                          autoFocus={index === 0}
                          className="autotab"
                          type="text"
                          inputProps={{ maxLength: 1 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <div className="phone-auth-button-container">
                    <Button
                      type="submit"
                      fullWidth
                      sx={{
                        textTransform: 'none',
                        backgroundColor: '#388E3C',
                        color: '#FDFDFD',
                        width: '100px',
                      }}
                      className="consultation-button"
                      variant="contained"
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress color="success" size={20} /> : 'Submit'}
                    </Button>
                  </div>
                </Box>
              </div>
            </div>
            <Container sx={{ marginBottom: '10px' }}>
              <Copyright />
            </Container>
          </CardContent>
        </Card>
      </div>
      <ContactUsModal open={open} onClose={handleContactImportalClose} />
    </div>
  );
}
