import * as React from 'react';
import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  TextField,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImportalSecondaryButton from '../ImportalSecondaryButton/ImportalSecondaryButton';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';
import {
  ParseProfile,
  ParseProfileType,
  ParseProfileTypeShortDescription,
  ParseProfileTypeLongDescription,
} from 'common/interfaces/documentParsing';

interface ImportalCalFeedbackModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (parseProfile: { feedback: string; parseType: ParseProfileType }) => void;
}

export default function ImportalCalFeedbackModal({ open, onClose, onSubmit }: ImportalCalFeedbackModalProps) {
  const [feedback, setFeedback] = useState<string>('');
  const [parseProfileType, setParseProfileType] = useState<ParseProfileType | undefined>();

  const handleClose = () => {
    onClose();
  };

  const CalFeedbackModalStyles = {
    header: {
      color: '#525256',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
    },
    subheader: {
      display: 'flex',
      justifyContent: 'left',
      marginBottom: '12px',
      marginTop: '2px',
      color: 'gray',
      fontSize: '14px',
    },
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  const handleParseTypeChange = (event: React.MouseEvent<HTMLElement>, parseType: ParseProfileType) => {
    setParseProfileType(parseType);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <div>
          <Typography sx={CalFeedbackModalStyles.header}>Provide Feedback for Cal</Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={CalFeedbackModalStyles.subheader}>
          How did he do on the document parsing? The more context, the better
        </div>
        <div style={{ paddingTop: '16px', display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup value={parseProfileType} exclusive color="primary" onChange={handleParseTypeChange}>
            {Object.keys(ParseProfileType).map((type, index) => {
              return (
                <ToggleButton sx={{ textTransform: 'none' }} value={type}>
                  {ParseProfileTypeShortDescription[type]}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
        <div style={{ paddingTop: '16px', display: 'flex', justifyContent: 'center' }}>
          {parseProfileType && <span>{ParseProfileTypeLongDescription[parseProfileType]}</span>}
        </div>
        <TextField
          sx={{ marginTop: '16px', fontSize: '12px' }}
          fullWidth
          multiline
          rows={6}
          variant="outlined"
          label="Your feedback"
          value={feedback}
          onChange={handleFeedbackChange}
        />
      </DialogContent>
      <DialogActions sx={{ marginTop: '24px' }}>
        <ImportalSecondaryButton text="Cancel" onClick={handleClose} />
        <ImportalPrimaryButton
          text="Submit"
          disabled={!parseProfileType}
          onClick={() => {
            onSubmit({ feedback, parseType: parseProfileType! });
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
