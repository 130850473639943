import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import SignupForm from '@/pages/signup/SignupForm';
import { useNavigate } from 'react-router';
import './SignUp.css';

export default function SignUp() {
  const navigate = useNavigate();
  const handleExistingAccount = () => {
    navigate('/login');
  };

  const theme = useTheme();

  return (
    <div className="signup-full-container">
      <div className="link-logo-container">
        <div>
          <img src="importal.png" className="logo-image" alt="logo" />
        </div>
        <div className="existing-account-wrapper">
          <Typography>
            <Link className="existing-account-link" onClick={handleExistingAccount} variant="body2">
              Already have an account? Sign in
            </Link>
          </Typography>
        </div>
      </div>
      <div className="signup-container">
        <div className="guess-less-container">
          <div className="title-container">
            <div>
              <Typography variant="h3" className="main-title">
                Guess Less, Import More
              </Typography>
              <Typography className="subtitle">
                The ultimate platform for seamless trade compliance. Unlock everything your team needs for a
                friction-less, error-free importing experience.
              </Typography>
            </div>
            <div className="laptop-image-container">
              <img src="ImportalApp.png" className="laptop-image" alt="Importal App Preview" />
            </div>
          </div>
        </div>
        <div className="signup-form-container">
          <SignupForm />
        </div>
      </div>
    </div>
  );
}
