import React, { useMemo } from 'react';
import { ChartConfig } from 'common/interfaces/reports';
import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { CategoricalXAxis } from '@/components/reporting/CategoricalXAxis';
import { NumericYAxis } from '@/components/reporting/NumericYAxis';
import { ImportalHistogramBars } from '@/components/reporting/ImportalHistogramBars';

export interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

/**
 * export interface ChartConfig {
 *     chartType: ReportChartType,
 *     chartName: string,
 *     chartDescriptor: string,
 *     pieConfig?: {
 *         seriesNameKey: string,
 *         seriesValueKey: string
 *     },
 *     histogramConfig?: {
 *         xAxisName: string,
 *         yAxisName: string,
 *         seriesNameKey: string,
 *         seriesValueKey: string
 *     }
 *
 * }
 */

const chartSettings = {
  marginLeft: 11,
  marginTop: 24,
};
export default function ImportalHistogram({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions(chartSettings);

  const chartLayout = {
    yAxisAreaWidth: 80,
    xAxisAreaHeight: 80,
  };

  const seriesData = useMemo(() => {
    if (!chartConfig || !chartConfig.histogramConfig) return [];
    if (!chartConfig.histogramConfig.seriesNameKey) return [];
    if (!chartConfig.histogramConfig.seriesValueKey) return [];

    const seriesNameKey = chartConfig!.histogramConfig!.seriesNameKey;
    const seriesValueKey = chartConfig!.histogramConfig.seriesValueKey;
    return data.map((datum) => ({
      category: datum[seriesNameKey],
      value: datum[seriesValueKey],
    }));
  }, [chartConfig, data]);

  const categories: string[] = useMemo(() => {
    if (!chartConfig || !chartConfig.histogramConfig) return [];
    if (!chartConfig.histogramConfig.seriesNameKey) return [];

    const seriesNameKey = chartConfig!.histogramConfig!.seriesNameKey;
    return data.map((datum) => datum[seriesNameKey]);
  }, [chartConfig, data]);

  const range: number[] = useMemo(() => {
    if (!chartConfig || !chartConfig.histogramConfig) return [];
    if (!chartConfig.histogramConfig.seriesValueKey) return [];

    const seriesValueKey = chartConfig!.histogramConfig.seriesValueKey;
    const range = data.map((datum) => parseInt(datum[seriesValueKey]));

    // @ts-ignore
    return [0, Math.max(...range)];
  }, [chartConfig, data]);

  return (
    <div className="Chart__wrapper" ref={ref} style={{ height: '480px' }}>
      <svg width={dms.width} height={dms.height}>
        <g transform={`translate(${[dms.marginLeft + chartLayout.yAxisAreaWidth, dms.marginTop].join(',')})`}>
          <NumericYAxis
            axisLabel={chartConfig.histogramConfig?.yAxisName || 'y axis'}
            height={dms.boundedHeight - chartLayout.xAxisAreaHeight}
            range={range}
          />
        </g>

        <g transform={`translate(${[dms.marginLeft, dms.marginTop].join(',')})`}>
          <g transform={`translate(${[chartLayout.yAxisAreaWidth, 0].join(',')})`}>
            <ImportalHistogramBars
              width={dms.boundedWidth - chartLayout.yAxisAreaWidth}
              height={dms.boundedHeight - chartLayout.xAxisAreaHeight}
              categories={seriesData}
              range={range}
            />
          </g>

          <g
            transform={`translate(${[chartLayout.yAxisAreaWidth, dms.boundedHeight - chartLayout.xAxisAreaHeight].join(',')})`}
          >
            <CategoricalXAxis
              axisLabel={chartConfig.histogramConfig?.xAxisName || 'x axis'}
              categories={categories}
              width={dms.boundedWidth - chartLayout.yAxisAreaWidth}
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
