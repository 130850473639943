import { Box, Divider, List, ListItem, TextField, useTheme } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DataExportProductCSV, DataExportStatus } from 'common/interfaces/dataExport';
import { mmmddyyHmmDate } from '@/utils/activePlanUtils';
import Button from '@mui/material/Button';
import { ArrowBack, Visibility } from '@mui/icons-material';
import './ExportProductDataModal.css';
import CircularProgress from '@mui/material/CircularProgress';

export interface Props {
  open: boolean;
  onClose: () => void;
  onDataExportSubmitted: (string) => Promise<DataExportProductCSV | undefined>;
}

export default function ExportProductDataModal({ open, onClose, onDataExportSubmitted }: Props) {
  const theme = useTheme();
  const api = useAPI();

  const [dataExports, setDataExports] = useState<DataExportProductCSV[]>([]);
  const [selectedDataExport, setSelectedDataExport] = useState<DataExportProductCSV | undefined>();
  const [showCreateDataExport, setShowCreateDataExport] = useState(false);

  useEffect(() => {
    api
      .getProductCSVDataExports()
      .then(({ data }) => {
        setDataExports(data);
      })
      .catch((err) => {
        console.error('error getting product csv data exports');
        console.error(err);
      });
  }, []);

  const onClickCreateNewDataExport = () => {
    setShowCreateDataExport(true);
  };

  const onClickViewDataExport = (dataExport: DataExportProductCSV) => {
    setSelectedDataExport(dataExport);
  };

  const onClickDeleteExport = (dataExport: DataExportProductCSV) => {
    api
      .deleteDataExport(dataExport._id.toString())
      .then(({}) => {
        const newDataExports = dataExports.filter((item) => item._id !== dataExport._id);
        setDataExports(newDataExports);
      })
      .catch((err) => {
        console.error('error deleting product csv data export');
        console.error(err);
      });
  };

  const onSubmitDataExportForProcessing = async (exportName: string) => {
    try {
      const newDataExport = await onDataExportSubmitted(exportName);
      if (newDataExport) {
        setShowCreateDataExport(false);
        setSelectedDataExport(newDataExport);
        setDataExports([newDataExport, ...dataExports]);
      }
    } catch (err) {
      console.error('error exporting data');
      throw err;
    }
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent
        className="upload-products-modal-dialog-content"
        style={{
          minWidth: 'min( 1000px, calc(100vw - 400px))',
          maxWidth: 'calc(100vw - 200px)',
          minHeight: 'min(600px, calc(100vh - 400px))',
          maxHeight: 'calc(100vh - 200px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        {(selectedDataExport || showCreateDataExport) && (
          <div className={'export-dialog-content-header-row'}>
            <IconButton
              onClick={() => {
                setShowCreateDataExport(false);
                setSelectedDataExport(undefined);
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>
        )}

        <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
          {selectedDataExport && <ViewDataExportModalContents dataExport={selectedDataExport} />}

          {showCreateDataExport && (
            <CreateDataExportModalContents onDataExportSubmitted={onSubmitDataExportForProcessing} />
          )}

          {!selectedDataExport && !showCreateDataExport && (
            <DataExportList
              dataExports={dataExports}
              onClickCreateNewDataExport={onClickCreateNewDataExport}
              onClickViewDataExport={onClickViewDataExport}
              onClickDeleteExport={onClickDeleteExport}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface DataExportListProps {
  dataExports: Array<DataExportProductCSV>;
  onClickCreateNewDataExport: () => void;
  onClickViewDataExport: (dataExport: DataExportProductCSV) => void;
  onClickDeleteExport: (dataExport: DataExportProductCSV) => void;
}

function DataExportList({
  dataExports,
  onClickCreateNewDataExport,
  onClickDeleteExport,
  onClickViewDataExport,
}: DataExportListProps) {
  const completedExports = dataExports.filter((d) => d.status === DataExportStatus.COMPLETED);

  return (
    <>
      <div className={'export-dialog-content-header-row'}>
        <div>
          <h2>Previous Exports</h2>
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <Button
            sx={{ margin: 'auto' }}
            size={'small'}
            className={'right-button-green'}
            onClick={onClickCreateNewDataExport}
          >
            Create New Export
          </Button>
        </div>
      </div>

      <List className={'importal-list'}>
        {completedExports.length > 0 ? (
          completedExports.map((item) => {
            return (
              <ListItem className={'importal-list-item'} style={{ gridTemplateColumns: '1fr 1fr 80px' }}>
                <span>{item.properties.exportName || 'Data Export'}</span>
                <span>{mmmddyyHmmDate(item.createdAt)}</span>
                <div className={'button-group'}>
                  <IconButton
                    onClick={() => {
                      onClickViewDataExport(item);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </div>
              </ListItem>
            );
          })
        ) : (
          <ListItem style={{ justifyContent: 'center' }}>
            <div>No exports have been created yet</div>
          </ListItem>
        )}
      </List>
    </>
  );
}

interface CreateDataExportModalProps {
  onDataExportSubmitted: (exportName: string) => Promise<void>;
}

function CreateDataExportModalContents({ onDataExportSubmitted }: CreateDataExportModalProps) {
  const [exportName, setExportName] = useState<string>('');
  const [createExportError, setCreateExportError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleExportNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportName(event.target.value);
  };

  const onClickSubmitExport = () => {
    setLoading(true);
    onDataExportSubmitted(exportName)
      .catch((err) => {
        setCreateExportError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <TextField value={exportName} onChange={handleExportNameChange} label="Export Name" variant="outlined" />

      {loading && (
        <div style={{ flexGrow: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Processing Data Export. Please wait.</span>
        </div>
      )}

      {createExportError && (
        <div style={{ flexGrow: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ color: 'red' }}>
            Error creating data export. Please try again later or{' '}
            <a href={'mailto:support@importal.com'}>email support</a>.
          </span>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button className={'right-button-green'} onClick={onClickSubmitExport}>
          Submit
        </Button>
      </div>
    </div>
  );
}

interface ViewDataExportModalContentsProps {
  dataExport: DataExportProductCSV;
}

function ViewDataExportModalContents({ dataExport }: ViewDataExportModalContentsProps) {
  const theme = useTheme();
  const api = useAPI();
  const [preview, setPreview] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true); // Set loading to true when fetching data

    api
      .previewDataExportFile(dataExport._id.toString())
      .then(({ data }) => {
        setPreview(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after fetch completed
      });
  }, []);

  const onClickDownloadFile = async () => {
    try {
      // add timestamp to file name
      const response = await api.downloadDataExportFile(dataExport._id.toString());
      const data = response.data; // Assuming it's a file you want to handle as a Blob
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${dataExport.properties.exportName || 'data_export'}.csv`; // Provide the filename extension
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const csvPreviewToTable = (csvPreview: string) => {
    const rows = csvPreview.split('\n');
    return (
      <table id={'data-export-preview-table'}>
        <thead>
          <tr>
            {rows[0].split(',').map((header) => (
              <th style={{ whiteSpace: 'nowrap', paddingRight: '10px' }}>{header + ' '}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(1).map((row) => (
            <tr>
              {row.split(',').map((cell) => (
                <td style={{ paddingRight: '10px' }}>{cell + ' '}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className={'export-dialog-content-header-row'}>
        <div>
          <h2>{dataExport.properties.exportName}</h2>
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <Button sx={{ margin: 'auto' }} size={'small'} className={'right-button-green'} onClick={onClickDownloadFile}>
            Download File
          </Button>
        </div>
      </div>

      <Divider></Divider>
      {isLoading ? (
        <Box style={{ flexGrow: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={60} color="primary" />
        </Box>
      ) : (
        <>
          <br />
          <span>
            <strong>File Preview</strong>
          </span>
          <div
            id={'data-export-preview-container'}
            style={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              borderColor: 'rgba(173,165,165,0.61)',
              borderWidth: '1px',
              borderStyle: 'solid',
              backgroundColor: '#EEEEEE',
              borderRadius: '8px',
              margin: '0 !important',
              overflowX: 'scroll',
            }}
          >
            {preview && csvPreviewToTable(preview)}
          </div>
          <i>
            This is a <strong>preview</strong> of the file, and shows only the first 3 lines of data.
          </i>
        </>
      )}
    </>
  );
}
