import React from 'react';
import Button from '@mui/material/Button';

interface ImportalSecondaryButtonProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  text: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties; // Optional style prop to override default styles
}

const ImportalSecondaryButton: React.FC<ImportalSecondaryButtonProps> = ({
  onClick,
  text,
  type = 'button',
  size = 'medium',
  disabled = false,
  style = {}, // Default empty object for the style prop
}) => {
  const defaultStyles: React.CSSProperties = {
    textTransform: 'none',
    fontSize: '16px',
    fontStyle: 'normal',
    width: '120px',
    fontWeight: 600,
    lineHeight: '24px',
    display: 'flex',
    padding: '8px 20px',
    alignItems: 'center',
    gap: '8px',
    marginRight: '8px',
    color: '#388e3c',
  };

  const hoverStyles: React.CSSProperties = {
    backgroundColor: '#e0e0e0', // Light gray for hover
  };

  return (
    <Button
      type={type}
      size={size}
      onClick={onClick}
      variant="text"
      disabled={disabled}
      style={{ ...defaultStyles, ...style }}
      className="importal-secondary-button"
      onMouseOver={(e) => {
        (e.currentTarget as HTMLElement).style.backgroundColor = hoverStyles.backgroundColor!;
      }}
      onMouseOut={(e) => {
        (e.currentTarget as HTMLElement).style.backgroundColor = 'transparent';
      }}
    >
      {text}
    </Button>
  );
};

export default ImportalSecondaryButton;
