import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { useAPI } from '@/api/APIContext';
import { useLocation } from 'react-router';
import { AxiosResponse } from 'axios';
import { Document } from 'common/interfaces/document';
import ImportalViewDocument from '@/shared-components/ImportalViewDocument/ImportalViewDocument';

function ViewDocumentPage() {
  const api = useAPI();
  const location = useLocation();
  const [document, setDocument] = useState<Document | null>(null);
  const [documentData, setDocumentData] = useState<any>(null);

  const fetchDocumentData = async (documentId: string) => {
    try {
      const documentResponse: AxiosResponse = await api.getDocument(documentId);
      const documentDataResponse: AxiosResponse = await api.getDocumentData(documentId);

      setDocument(documentResponse.data);
      setDocumentData(documentDataResponse.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const documentId = pathParts[pathParts.length - 1];
    if (!documentId) return;
    fetchDocumentData(documentId);
  }, [location]);

  return (
    <Card
      sx={{
        padding: '16px',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {document && documentData && <ImportalViewDocument document={document} documentData={documentData} />}
    </Card>
  );
}

export default ViewDocumentPage;
