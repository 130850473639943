import Checkbox from '@mui/material/Checkbox';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BusinessPageContext } from '@/broker-app/pages/businesses/BusinessPage';
import { useAPI } from '@/api/APIContext';
import ImportalCard from '@/shared-components/ImportalCard';
import Button from '@mui/material/Button';
import { Save } from '@mui/icons-material';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { Report, ReportingCollection, ReportingManifest } from 'common/interfaces/reports';
import { ImportalBreadCrumbs } from '@/shared-components/ImportalBreadCrumbs';
import ReportList from '@/pages/reports/ReportList';
import ReportDefault from '@/pages/reports/ReportDefault';
import ReportAdvanced from '@/pages/reports/ReportAdvanced';

export default function BusinessReportingTab() {
  const { business, reports, refreshReports } = useContext(BusinessPageContext);

  const [reportingManifest, setReportingManifest] = useState<ReportingManifest | undefined>();

  const api = useAPI();

  const [availableCollections, setAvailableCollections] = useState<ReportingCollection[]>([]);

  useEffect(() => {
    api
      .getCollectionsAvailableForReporting()
      .then(({ data: collections }) => {
        setAvailableCollections(collections);
      })
      .catch((err) => {
        console.error('error getting available reporting collections');
        console.error(err);
      });

    api
      .getReportingManifestForBusiness(business?._id?.toString()!)
      .then(({ data: reportingManifest }) => {
        setReportingManifest(reportingManifest);
      })
      .catch((err) => {
        console.error('error getting reporting manifest for business');
        console.error(err);
      });
  }, []);

  const handleReportingEnabledCheckboxClick = () => {
    api
      .enableBusinessForReporting(business!._id!.toString())
      .then(({ data: reportingManifest }) => {
        setReportingManifest(reportingManifest);
      })
      .catch((err) => {
        console.error('error enabling business for reporting');
        console.error(err);
      });
  };

  const saveReportingManifest = () => {
    api
      .updateReportingManifestForBusiness(business!._id!.toString(), reportingManifest!)
      .then(({ data: reportingManifest }) => {
        setReportingManifest(reportingManifest);
      })
      .catch((err) => {
        console.error('error updating reporting manifest for business');
        console.error(err);
      });
  };

  const handleCheckForReportingCollection = (checked: boolean, availableCollection: ReportingCollection) => {
    let updatedCollections = reportingManifest?.collections || [];

    if (checked) {
      updatedCollections.push(availableCollection);
    } else {
      updatedCollections = updatedCollections.filter((collection) => collection.name !== availableCollection.name);
    }

    // @ts-ignore
    setReportingManifest({
      ...reportingManifest,
      collections: updatedCollections,
    });
  };

  const isAvailableCollectionPartOfReportingManifestCollections = (
    availableCollection: ReportingCollection
  ): boolean => {
    return !!reportingManifest?.collections?.some(
      (reportingCollection) => reportingCollection.name === availableCollection.name
    );
  };

  return (
    <>
      <ImportalCard title={'Reporting Settings'} topRightActionButton={<></>}>
        <div className="business-customs-checkbox-container">
          Enabled for Reporting?
          <Checkbox
            checked={!!reportingManifest || false}
            disabled={!!reportingManifest}
            onChange={handleReportingEnabledCheckboxClick}
          />
        </div>
        {reportingManifest && (
          <>
            <h3>Reporting Manifest</h3>
            <div>
              {availableCollections &&
                availableCollections.map((availableCollection, index) => {
                  return (
                    <div className="business-customs-checkbox-container">
                      <Checkbox
                        checked={isAvailableCollectionPartOfReportingManifestCollections(availableCollection)}
                        onChange={(event, checked) => {
                          handleCheckForReportingCollection(checked, availableCollection);
                        }}
                      />
                      <strong>{availableCollection.shortDescription}</strong>: {availableCollection.description}
                    </div>
                  );
                })}
            </div>

            <CardActions style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button startIcon={<Save />} className="right-button-green" onClick={saveReportingManifest}>
                Save
              </Button>
            </CardActions>
          </>
        )}
      </ImportalCard>

      <br />

      <ImportalCard title={'All Reports'}>
        <ImportalBreadCrumbs
          elements={[
            {
              render: ({ popItem, pushItem }) => (
                <>
                  <ReportList
                    reports={reports}
                    onReportSelected={(report?: Report) => {
                      pushItem(report);
                    }}
                  />
                </>
              ),
              label: 'All Reports',
            },
            {
              render: ({ popItem, pushItem, item }) => (
                <ReportDefault
                  report={item}
                  onAdvancedClicked={(report: Report) => {
                    pushItem(report);
                  }}
                />
              ),
              label: (report: Report) => {
                return report.name;
              }, // could be a string or a function
            },
            {
              render: ({ popItem, pushItem, item }) => <ReportAdvanced report={item} />,
              label: 'Advanced',
            },
          ]}
        />
      </ImportalCard>
    </>
  );
}
