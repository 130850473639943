import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import './AuthorizedSigner.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { CustomsOnboarding, CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import { validateEmail } from '@/utils/validateEmail';

const BUSINESS_TYPE_TO_VALID_TITLES: Record<string, string[]> = {
  'Limited Liability Company': ['Registered Owner', 'Managing Member'],
  Partnership: ['Partner', 'General Partner'],
  Corporation: [
    'Vice President',
    'Assistant Vice President',
    'Treasurer',
    'Assistant Treasurer',
    'General Counsel',
    'Assistant General Counsel',
    'Corporate Secretary',
    'Owner',
    'President',
    'Chairman of the Board',
    'CEO',
    'CIO',
    'CFO',
    'COO',
  ],
};

interface Props {
  handleEvent: () => void;
  handleBack: () => void;
  handleClose: () => void;
  handleLoading: (boolean) => void;
  updateCustomsOnboarding: (businessId: string, customsOnboarding: CustomsOnboarding) => void;
  customsOnboarding?: CustomsOnboarding;
}

export default function AuthorizedSigner({
  handleEvent,
  handleBack,
  handleClose,
  handleLoading,
  updateCustomsOnboarding,
  customsOnboarding,
}: Props) {
  const activeUser = useActiveUser();
  const activeBusiness = useActiveBusiness();
  const user = useActiveUser();
  const [isAuthorizedSigner, setIsAuthorizedSigner] = useState(true);
  const [authorizedSignerInfo, setAuthorizedSignerInfo] = useState({
    fullName: '',
    title: '',
    email: '',
  });
  const [mainStakeholderInfo, setMainStakeholderInfo] = useState({
    fullName: `${user.user?.firstName} ${user.user?.lastName}`,
    title: '',
    email: `${user.user?.email}`,
  });

  const [subPage, setSubPage] = useState(0);

  useEffect(() => {
    // load the user's title if it's there
    const customsOnboardingAuthorizedSignerEmail = customsOnboarding?.authorizedSigner?.email;
    const activeUserEmail = activeUser.user?.email;
    if (customsOnboardingAuthorizedSignerEmail && customsOnboardingAuthorizedSignerEmail === activeUserEmail) {
      setMainStakeholderInfo({ ...mainStakeholderInfo, title: customsOnboarding.authorizedSigner?.title || '' });
    }
  }, [activeBusiness.business, customsOnboarding]);

  const isNextEnabled = () => {
    if (isAuthorizedSigner) {
      return !!mainStakeholderInfo.title;
    } else {
      return (
        authorizedSignerInfo.email &&
        validateEmail(authorizedSignerInfo.email) &&
        authorizedSignerInfo.title &&
        authorizedSignerInfo.fullName
      );
    }
  };

  const handleAuthorizedSignerClick = (e) => {
    if (e.target.value === 'Yes') {
      setIsAuthorizedSigner(true);
    } else {
      setIsAuthorizedSigner(false);
    }
  };

  const handleMainStakeholderChange = (e) => {
    const { name, value } = e.target;
    setMainStakeholderInfo({
      ...mainStakeholderInfo,
      [name]: value,
    });
  };

  const handleAuthorizeSignerInfoChange = (e) => {
    const { name, value } = e.target;
    setAuthorizedSignerInfo({
      ...authorizedSignerInfo,
      [name]: value,
    });
  };

  const handleNext = (e) => {
    if (isAuthorizedSigner) {
      updateCustomsOnboarding(activeBusiness.business!._id!.toString(), {
        authorizedSigner: mainStakeholderInfo,
        step: CustomsOnboardingSteps.PHOTO_ID,
      } as CustomsOnboarding);
      handleEvent();
    } else {
      updateCustomsOnboarding(activeBusiness.business!._id!.toString(), {
        authorizedSigner: authorizedSignerInfo,
        mainStakeholder: mainStakeholderInfo,
        step: CustomsOnboardingSteps.PHOTO_ID,
      } as CustomsOnboarding);
      setSubPage(1);
    }
  };

  const getValidTitles = (): string[] => {
    return BUSINESS_TYPE_TO_VALID_TITLES[activeBusiness.business?.businessStructure || ''] || [];
  };

  const renderAuthorizedSignerEmailSection = () => {
    if (!isAuthorizedSigner) {
      return (
        <>
          <div className="authorized-signer-email-header">Authorized Signer Information</div>
          <div className="authorized-signer-email-container">
            <div className="as-form-field-container">
              <div className="business-form-field-label">
                <Typography>Full Name</Typography>
              </div>
              <TextField
                fullWidth
                name="fullName"
                size="small"
                onChange={handleAuthorizeSignerInfoChange}
                value={authorizedSignerInfo.fullName}
              />
            </div>
            <div className="as-form-field-container">
              <div className="business-form-field-label">
                <Typography>Title</Typography>
              </div>
              <FormControl fullWidth>
                <Select
                  id="demo-simple-select"
                  fullWidth
                  name="title"
                  onChange={handleAuthorizeSignerInfoChange}
                  value={authorizedSignerInfo.title}
                  size="small"
                >
                  {getValidTitles().map((title, index) => (
                    <MenuItem key={index + 1} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="as-form-field-container">
              <div className="business-form-field-label">
                <Typography>Email Address</Typography>
              </div>
              <TextField
                fullWidth
                name="email"
                onChange={handleAuthorizeSignerInfoChange}
                size="small"
                value={authorizedSignerInfo.email}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="authorized-signer-title-container">
          <div className="as-form-field-container">
            <div className="business-form-field-label">
              <Typography>Title</Typography>
            </div>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                fullWidth
                name="title"
                onChange={handleMainStakeholderChange}
                value={mainStakeholderInfo.title}
                size="small"
              >
                {getValidTitles().map((title, index) => (
                  <MenuItem key={index + 1} value={title}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      );
    }
  };

  const renderSubPage = (subPage) => {
    switch (subPage) {
      case 0:
        return (
          <div>
            <div className="customs-form-field-container">
              <div className="authorized-signers-text">
                Since your company is a <b>{activeBusiness.business?.businessStructure}</b>, only the following titles
                can be an authorized signer:
              </div>
              <div className="authorized-signers-text">
                <ul className="bulleted-list">
                  {getValidTitles().map((title, index) => (
                    <li key={index}>{title}</li>
                  ))}
                </ul>
              </div>
              <div className="title-question-container">
                <div className="title-question-header-container">
                  <div className="title-question-header">Are you one of these titles?</div>
                  <div className="title-question-subheader">
                    If not, no worries just select no. We'll send them the next steps via email.
                  </div>
                </div>
                <div className="title-question-body">
                  <div className="checkbox-container-title">
                    Yes
                    <Checkbox value="Yes" checked={isAuthorizedSigner} onChange={handleAuthorizedSignerClick} />
                    No
                    <Checkbox value="No" checked={!isAuthorizedSigner} onChange={handleAuthorizedSignerClick} />
                  </div>
                </div>
              </div>
              {renderAuthorizedSignerEmailSection()}
            </div>
            <div className="next-button-container">
              <Button fullWidth className="back-button-text" variant="text" onClick={handleBack}>
                Back
              </Button>
              <Button
                type="submit"
                color="info"
                size="medium"
                onClick={handleNext}
                variant="contained"
                className="next-button-green"
                endIcon={<KeyboardArrowRightIcon />}
                disabled={!isNextEnabled()}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <>
            <div className="customs-onboarding-confirmation">
              <div className="confirmation-title">Email has been sent!</div>
              <div className="confirmation-subtitle">
                Thanks for entering your business's authorized signer information. We'll reach out to{' '}
                {authorizedSignerInfo.fullName} via email with next steps.
              </div>
            </div>
            <div className="next-button-container">
              <Button
                type="submit"
                color="info"
                size="medium"
                onClick={handleClose}
                variant="contained"
                className="next-button-green"
              >
                Close
              </Button>
            </div>
          </>
        );
    }
  };

  return renderSubPage(subPage);
}
