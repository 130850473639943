import React from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import './VMCRNameVariation.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ManifestContext from '../manifest-modal/ManifestProvider';

export default function VMCRNameVariant({ handleNext, handleBack }) {
  const { currentManifestRequest, setCurrentManifestRequest } = React.useContext(ManifestContext);

  const handleAddNameVariantRow = () => {
    const rowToAdd = '';
    setCurrentManifestRequest({
      ...currentManifestRequest,
      nameVariations: [...(currentManifestRequest.nameVariations || []), rowToAdd],
    });
  };

  const handleRemoveNameVariantRow = (indexToRemove) => {
    setCurrentManifestRequest({
      ...currentManifestRequest,
      nameVariations: (currentManifestRequest.nameVariations || []).filter((_, index) => index !== indexToRemove),
    });
  };

  const handleNameVariantChange = (index, value) => {
    const updatedRows = [...(currentManifestRequest.nameVariations || [])];
    updatedRows[index] = value;
    setCurrentManifestRequest({
      ...currentManifestRequest,
      nameVariations: updatedRows,
    });
  };

  const isNextDisabled = (): boolean => {
    return (
      !currentManifestRequest.nameVariations ||
      currentManifestRequest.nameVariations.length < 1 ||
      currentManifestRequest.nameVariations.some((nameVariant) => !nameVariant)
    );
  };

  const renderNameVariantRows = () => {
    return currentManifestRequest.nameVariations?.map((row, index) => (
      <div key={index} className="vmcr-name-variation-row" style={{ justifyContent: 'center' }}>
        <div style={{ width: '50%' }}>
          <TextField
            fullWidth
            onChange={(e) => handleNameVariantChange(index, e.target.value)}
            value={row}
            label="Name Variant"
            inputProps={{ maxLength: 35 }}
          />
        </div>
        <IconButton onClick={(e) => handleRemoveNameVariantRow(index)}>
          <DeleteIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </div>
    ));
  };

  return (
    <div className="card-container">
      <div className="importing-card-container">
        <div className="vmcr-description-title-container">
          <div className="vmcr-description-title">Name Variations</div>
          <Button
            className="vmcr-add-button-text"
            sx={{ textTransform: 'none' }}
            endIcon={<AddIcon />}
            variant="text"
            onClick={handleAddNameVariantRow}
          >
            Add
          </Button>
        </div>
        <div className="vmcr-business-info-container">{renderNameVariantRows()}</div>
        <div className="next-button-container">
          <Button fullWidth className="back-button-text" variant="text" onClick={handleBack}>
            Back
          </Button>
          <Button
            type="submit"
            fullWidth
            sx={{ width: '160px!important' }}
            className="next-button-green"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={handleNext}
            variant="contained"
            disabled={isNextDisabled()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
