import { useMatch } from 'react-router';
import { useAPI } from '@/api/APIContext';
import React, { useContext, useState } from 'react';
import { BusinessUser, BusinessUserRole, Mode } from 'common/interfaces/business';
import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Check, Close, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import './BusinessAdminTabUsersCard.css';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { BusinessPageContext } from './BusinessPage';

export default function BusinessAdminTabUsersCard() {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const { users, planMetadata, refreshUsers, refreshLinks, plan } = useContext(BusinessPageContext);
  const [businessUsers, setBusinessUsers] = useState(users);

  const match = useMatch({
    path: '/broker/dashboard/businesses/:businessId',
  });
  const businessId: string | undefined = match?.params.businessId as string;
  const api = useAPI();

  const handleRemoveBusinessUser = (indexToRemove) => {
    const newBusinessUsers = businessUsers.filter((_, index) => index !== indexToRemove);
    setBusinessUsers(newBusinessUsers);
  };

  const handleAddBusinessUser = () => {
    const updatedUsers = [...businessUsers, { email: '', firstName: '', mode: Mode.ADD, role: 'User' }];
    setBusinessUsers(updatedUsers);
  };

  const handleChangeBusinessUser = (index, key, value) => {
    const updatedUsers = [...businessUsers].map((user, i) => (i === index ? { ...user, [key]: value } : user));
    setBusinessUsers(updatedUsers);
  };

  const handleEditRow = (indexToUpdate) => {
    const updatedUsers = businessUsers.map((businessUser, index) =>
      index === indexToUpdate ? { ...businessUser, mode: Mode.EDIT } : businessUser
    );
    setBusinessUsers(updatedUsers);
  };

  const handleSaveRow = (indexToUpdate) => {
    const businessUserToUpdate = businessUsers[indexToUpdate];

    if (businessUserToUpdate.mode === Mode.ADD) {
      setLoadingStatus(LoadingStatus.LOADING);
      api
        .createBusinessUser(businessId, businessUserToUpdate)
        .then((response) => {
          setLoadingStatus(LoadingStatus.SUCCESS);
          refreshUsers();
          refreshLinks();
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.ERROR);
        });
    } else if (businessUserToUpdate.mode === Mode.EDIT) {
      setLoadingStatus(LoadingStatus.LOADING);
      api
        .updateBusinessUser(businessId, businessUserToUpdate)
        .then((response) => {
          setLoadingStatus(LoadingStatus.SUCCESS);
          refreshUsers();
          refreshLinks();
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.ERROR);
        });
    }

    const updatedUsers = businessUsers.map((businessUser, index) =>
      index === indexToUpdate ? { ...businessUser, mode: Mode.VIEW } : businessUser
    );

    setBusinessUsers(updatedUsers);
  };

  const handleDeleteBusinessUser = (id, indexToDelete) => {
    setLoadingStatus(LoadingStatus.LOADING);

    api
      .deleteBusinessUser(id, businessId)
      .then((response) => {
        setLoadingStatus(LoadingStatus.SUCCESS);
        refreshUsers();
      })
      .catch((error) => {
        setLoadingStatus(LoadingStatus.ERROR);
      });

    const updatedUsers = businessUsers.filter((_, index) => index !== indexToDelete);
    setBusinessUsers(updatedUsers);
  };

  const handleCancelEditRow = (indexToUpdate) => {
    const updatedUsers = businessUsers.map((businessUser, index) =>
      index === indexToUpdate ? { ...businessUser, mode: Mode.VIEW } : businessUser
    );
    setBusinessUsers(updatedUsers);
  };

  const renderRow = (member: BusinessUser, index) => {
    if (member.mode === Mode.EDIT || member.mode === Mode.ADD) {
      return (
        <TableRow key={index}>
          <TableCell>
            <TextField
              fullWidth
              onChange={(e) => handleChangeBusinessUser(index, 'email', e.target.value)}
              value={member.email}
              disabled={member.mode === Mode.EDIT}
              variant="standard"
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              onChange={(e) => handleChangeBusinessUser(index, 'firstName', e.target.value)}
              value={member.firstName}
              variant="standard"
            />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              onChange={(e) => handleChangeBusinessUser(index, 'lastName', e.target.value)}
              value={member.lastName}
              variant="standard"
            />
          </TableCell>
          <TableCell>
            <Select
              id="demo-simple-select"
              value={member.role}
              fullWidth
              size="small"
              onChange={(e) => handleChangeBusinessUser(index, 'role', e.target.value)}
            >
              <MenuItem value={BusinessUserRole.ADMIN}>{BusinessUserRole.ADMIN}</MenuItem>
              <MenuItem value={BusinessUserRole.USER}>{BusinessUserRole.USER}</MenuItem>
            </Select>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <IconButton onClick={() => handleCancelEditRow(index)}>
              <Close />
            </IconButton>
            <IconButton onClick={() => handleSaveRow(index)}>
              <Check />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow key={index}>
          <TableCell>{member.email}</TableCell>
          <TableCell>{member.firstName}</TableCell>
          <TableCell>{member.lastName}</TableCell>
          <TableCell>
            {member._id && planMetadata?.planAdmins?.includes(member._id!.toString()) ? 'Admin' : 'User'}
          </TableCell>
          <TableCell>{member.isActive ? 'Active' : 'Invited'}</TableCell>
          <TableCell>
            <IconButton onClick={() => handleEditRow(index)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDeleteBusinessUser(member._id, index)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <>
      <Card className="my-shipments-card">
        <CardContent style={{ position: 'relative' }}>
          <IconButton
            style={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: 'grey',
            }}
            onClick={handleAddBusinessUser}
          >
            <Add />
          </IconButton>

          <div className="header-question">
            <Typography>Users</Typography>
          </div>
          <div>
            {businessUsers && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>Email</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>First Name</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>Last Name</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>Role</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="user-row">
                    {businessUsers.map((member, index) => renderRow(member, index))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </CardContent>
      </Card>
      <DataImportLoader
        loadingState={{ loadingStatus }}
        loadingText={'Saving user info...'}
        successText={'Successfully saved user info'}
        errorText={'Error saving user info'}
      />
    </>
  );
}
