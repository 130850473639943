import { Container } from '@mui/material';
import ReportList from '@/pages/reports/ReportList';
import React, { useContext, useEffect, useState } from 'react';
import { ReportsPageContext } from '@/pages/reports/ReportsPage';
import { Report, ReportType } from 'common/interfaces/reports';
import { ImportalBreadCrumbs } from '@/shared-components/ImportalBreadCrumbs';
import ReportDefault from '@/pages/reports/ReportDefault';
import ReportAdvanced from '@/pages/reports/ReportAdvanced';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';
import Button from '@mui/material/Button';
import ImportalCard from '@/shared-components/ImportalCard';
import { useAPI } from '@/api/APIContext';

export default function GeneratedReports() {
  const api = useAPI();
  const { reports, refreshReports } = useContext(ReportsPageContext);
  const betaFeatures = useBetaFeatures();
  const [generatedReports, setGeneratedReports] = useState<Report[]>(reports);

  useEffect(() => {
    const generatedReports = reports
      .filter((report) => report.reportType === ReportType.GENERATED)
      .sort((a, b) => new Date(b.lastRun).getTime() - new Date(a.lastRun).getTime());
    setGeneratedReports(generatedReports);
  }, [reports]);

  const onClickAddReport = () => {
    api
      .createReport(ReportType.GENERATED)
      .then(({ data }) => {
        refreshReports();
      })
      .catch((err) => {
        console.error('error creating new Report');
        console.error(err);
      });
  };

  return (
    <Container maxWidth={'xl'} disableGutters>
      <ImportalCard
        title={'Generated Reports'}
        topRightActionButton={
          <>
            {betaFeatures.includes('reporting-advanced') && (
              <>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button variant="contained" onClick={onClickAddReport}>
                    Add Report
                  </Button>
                </div>
              </>
            )}
          </>
        }
      >
        <ImportalBreadCrumbs
          elements={[
            {
              render: ({ popItem, pushItem }) => (
                <>
                  <ReportList
                    reports={generatedReports}
                    onReportSelected={(report?: Report) => {
                      pushItem(report);
                    }}
                  />
                </>
              ),
              label: 'Generated Reports', // could be a string or a function
            },
            {
              render: ({ popItem, pushItem, item }) => (
                <ReportDefault
                  report={item}
                  onAdvancedClicked={(report: Report) => {
                    pushItem(report);
                  }}
                />
              ),
              label: (report: Report) => {
                return report.name;
              }, // could be a string or a function
            },
            {
              render: ({ popItem, pushItem, item }) => <ReportAdvanced report={item} />,
              label: 'Advanced',
            },
          ]}
        />
      </ImportalCard>
    </Container>
  );
}
