import React from 'react';
import {
  ComplianceProfile,
  EntryInvoiceLinePreferenceDescription,
  EntryInvoiceLinesPreference,
  Mode,
} from 'common/interfaces/business';
import { MenuItem, TextField, Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';

export enum FieldType {
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
}

/**
 * @param mode
 * @param value
 * @param options -- should be a Record<Display Value, Stored Value>
 * @param onNewValue
 */
export const renderImportalSelect = (
  mode: Mode,
  value: string,
  options: Record<string, any>,
  onNewValue: (arg0: any) => void
) => {
  if (mode === Mode.EDIT) {
    return (
      <TextField
        select
        fullWidth
        variant="standard"
        value={value}
        onChange={(e) => {
          onNewValue(e.target.value);
        }}
      >
        {Object.keys(options).map((key, index) => {
          return (
            <MenuItem key={index} value={key}>
              {options[key]}
            </MenuItem>
          );
        })}
      </TextField>
    );
  } else if (mode === Mode.VIEW) {
    return <div className="entry-row-text-value">{options[value]}</div>;
  }
};

export const renderImportalField = (mode: Mode, type: FieldType, value: any, onNewValue: (arg0: any) => void) => {
  if (mode === Mode.EDIT) {
    switch (type) {
      case FieldType.TEXT:
        return (
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => {
              onNewValue(e.target.value);
            }}
            value={value || ''}
          />
        );
      case FieldType.CHECKBOX:
        return (
          <TextField
            select
            fullWidth
            variant="standard"
            value={value ? 'Yes' : 'No'}
            onChange={(e) => {
              onNewValue(e.target.value === 'Yes');
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        );
    }
  } else if (mode === Mode.VIEW) {
    switch (type) {
      case FieldType.TEXT:
        return <div className="entry-row-text-value">{value}</div>;
      case FieldType.CHECKBOX:
        return (
          <Typography>
            {value === true ? <Check style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />}
          </Typography>
        );
    }
  }
};

const renderImportalFieldHover = (mode: Mode, type: FieldType, value: any, onNewValue: (arg0: any) => void) => {};

const renderBusinessFields = (cardMode: Mode, value: any, name: string, onValueChanged: (arg0: any) => void) => {
  if (cardMode === Mode.EDIT) {
    switch (name) {
      case 'address':
      case 'additionalState':
      case 'businessStructure':
      case 'ein':
      case 'partners':
      case 'paymentMethodCustoms':
      case 'pointOfContact':
      case 'bondInfo':
      case 'bonds':
      case 'isf':
      case 'customsClearance':
      case 'classification':
      case 'disbursement':
        return (
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => {
              onValueChanged(e.target.value);
            }}
            value={value || ''}
          />
        );
      case 'productLibrary':
      case 'standardOperatingProceduresComplete':
        return (
          <TextField
            select
            fullWidth
            variant="standard"
            value={value ? 'Yes' : 'No'}
            onChange={(e) => {
              onValueChanged(e.target.value === 'Yes');
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        );
      default:
        return null;
    }
  } else {
    switch (name) {
      case 'productLibrary':
      case 'standardOperatingProceduresComplete':
        return (
          <Typography>
            {value === true ? <Check style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />}
          </Typography>
        );
      default:
        return <div className="entry-row-text-value">{value}</div>;
    }
  }
};

export default renderBusinessFields;
