import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {
  Button,
  IconButton,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  styled,
  useTheme,
} from '@mui/material';
import './BrokerCustomsOnboarding.css';
import { useNavigate } from 'react-router';
import BackdropLoader from '../snackbar-loader/SnackbarLoader';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Person3Icon from '@mui/icons-material/Person3';
import BusinessInfo from '../BusinessInfo/BusinessInfo';
import BusinessCustomsInfo from '../business-customs-info/BusinessCustomsInfo';
import CheckIcon from '@mui/icons-material/Check';
import DocumentsAndSignature from '../documents-and-signature/DocumentsAndSignature';
import { useAPI } from '@/api/APIContext';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import BrokerAuthorizedSigner from '../broker-authorized-signer/BrokerAuthorizedSigner';

const steps = ['Business Info', 'Authorized Signer', 'Photo ID & Signature', 'Customs Info'];

interface BrokerCustomsOnboardingPops {
  isShowContactUs?: boolean;
  handleClose?: any;
}

export default function BrokerCustomsOnboarding({ handleClose }: BrokerCustomsOnboardingPops) {
  const navigate = useNavigate();
  const theme = useTheme();
  const api = useAPI();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customsOnboardingInfo, setCustomsOnboardingInfo]: any = useState({});
  const activeBusiness = useActiveBusiness();

  const mapCustomsOnboardingSteps = (customsOnboardingInfo) => {
    switch (customsOnboardingInfo.step) {
      case CustomsOnboardingSteps.BUSINESS_INFO:
        setActiveStep(0);
        break;
      case CustomsOnboardingSteps.AUTHORIZED_SIGNER:
        setCompleted({
          0: true,
        });
        setActiveStep(1);
        break;
      case CustomsOnboardingSteps.PHOTO_ID:
        setCompleted({
          0: true,
          1: true,
        });
        setActiveStep(2);
        break;
      case CustomsOnboardingSteps.SIGN_POA:
        setCompleted({
          0: true,
          1: true,
        });
        setActiveStep(2);
        break;
      case CustomsOnboardingSteps.CUSTOMS_INFO:
        setCompleted({
          0: true,
          1: true,
          2: true,
        });
        setActiveStep(3);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (activeBusiness.business?._id) {
      const businessId = activeBusiness.business?._id.toString();
      getCustomsOnboardingInfoForBusiness(businessId);
    }
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const updateCustomsOnboarding = (businessId: string, customsOnboarding: any) => {
    api
      .updateCustomsOnboarding(businessId, customsOnboarding)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCustomsOnboardingInfoForBusiness = (businessId: string) => {
    api
      .getCustomsOnboardingForBusiness(businessId)
      .then((response) => {
        console.log(response);
        mapCustomsOnboardingSteps(response.data);
        setCustomsOnboardingInfo(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepComponentDutyCalculator = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <BusinessInfo
            handleEvent={handleComplete}
            handleLoading={setIsLoading}
            updateCustomsOnboarding={updateCustomsOnboarding}
            customsOnboarding={customsOnboardingInfo}
          />
        );
      case 1:
        return (
          <BrokerAuthorizedSigner
            handleEvent={handleComplete}
            handleBack={handleBack}
            handleLoading={setIsLoading}
            updateCustomsOnboarding={updateCustomsOnboarding}
            customsOnboarding={customsOnboardingInfo}
          />
        );
      case 2:
        return (
          <DocumentsAndSignature
            handleEvent={handleComplete}
            handleBack={handleBack}
            handleLoading={setIsLoading}
            updateCustomsOnboarding={updateCustomsOnboarding}
            customsOnboarding={customsOnboardingInfo}
          />
        );
      case 3:
        return (
          <BusinessCustomsInfo
            handleEvent={handleComplete}
            handleBack={handleBack}
            handleLoading={setIsLoading}
            updateCustomsOnboarding={updateCustomsOnboarding}
            customsOnboarding={customsOnboardingInfo}
          />
        );
    }
  };

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      0: completed ? <CheckIcon /> : <BusinessIcon />,
      1: completed ? <CheckIcon /> : <BusinessIcon />,
      2: completed ? <CheckIcon /> : <Person3Icon />,
      3: completed ? <CheckIcon /> : <HistoryEduIcon />,
      4: completed ? <CheckIcon /> : <AssuredWorkloadIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
      fontWeight: 600,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient( 95deg,rgb(56,142,60) 0%,rgb(56,142,60) 50%,rgb(56,142,60) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient( 95deg,rgb(56,142,60) 0%,rgb(56,142,60) 50%,rgb(56,142,60) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? 'blue' : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'blue' : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: 'linear-gradient( 136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      fontWeight: '700',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient( 136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)',
    }),
  }));

  return (
    <div className="onboarding-container">
      <div>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <div className="my-shipments-header">Customs Onboarding</div>
        <div className="my-shipments-subheader" style={{ marginBottom: '20px' }}>
          Kickoff and update customs onboarding information for any business
        </div>
        <Stepper alternativeLabel connector={<ColorlibConnector />} nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              key={label}
              onClick={() => setActiveStep(index)}
              sx={{ cursor: 'pointer' }}
              completed={completed[index]}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {(activeStep !== 0 || isLoading) && <BackdropLoader isLoading={isLoading} />}
        {allStepsCompleted() ? (
          <>
            <div className="customs-onboarding-confirmation">
              <div className="confirmation-title">You're all set!</div>
              <div className="confirmation-subtitle">
                Thanks for customs onboarding with us. Our brokers will reach out with next steps.
              </div>
            </div>
            <div className="next-button-container">
              <Button
                type="submit"
                color="info"
                size="medium"
                onClick={handleClose}
                variant="contained"
                className="next-button-green"
              >
                Close
              </Button>
            </div>
          </>
        ) : (
          <div>
            <div>
              <div className="step-component-bi-container">{renderStepComponentDutyCalculator(activeStep)}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
