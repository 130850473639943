import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import './ShipmentTable.css';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';
import { Shipment } from 'common/interfaces/shipment';
import { TableConfig } from 'common/interfaces/importalTable';

const capitalizeAllWords = (str: string) => {
  if (str) {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  } else {
    return '';
  }
};

const RowStatusChip = ({ row }: { row: Shipment<any> }) => {
  return (
    <Chip
      label={capitalizeAllWords(row.internalStatus || 'unknown')}
      sx={{
        backgroundColor: '#77A8F6',
        color: 'white',
        fontWeight: 'bold',
        marginRight: '6px',
        width: '150px',
      }}
    />
  );
};

const Row = ({ row }: { row: Shipment<any> }) => {
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Typography> {row.referenceNumber} </Typography>
        </TableCell>
        <TableCell>
          <Typography>{row.mbl ? row.mbl : ''}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{Array.isArray(row.hbls) && row.hbls.join(',')}</Typography>
        </TableCell>
        <TableCell>
          {row.isfOnFile === 'Yes' ? (
            <CheckCircleIcon style={{ color: 'green' }} />
          ) : (
            <CloseIcon style={{ color: 'red' }} />
          )}
        </TableCell>
        {/*<TableCell>*/}
        {/*    <Typography><RowStatusChip row={row}/></Typography>*/}
        {/*</TableCell>*/}
      </TableRow>
    </React.Fragment>
  );
};

const ShipmentTable = ({ rows, isLoading }) => {
  const [shipmentTableConfig, setShipmentTableConfig] = useState<TableConfig>({
    columns: [
      {
        displayName: 'Reference No.',
        name: 'referenceNumber',
        dataType: 'string',
        sortable: true,
        filterable: true,
      },
      {
        displayName: 'Master Bill',
        name: 'mbl',
        dataType: 'string',
        sortable: true,
        filterable: true,
      },
      {
        displayName: 'House Bills',
        name: 'hbls',
        dataType: 'string',
        sortable: false,
        filterable: false,
      },
      {
        displayName: 'ISF On File',
        name: 'isfOnFile',
        dataType: 'boolean',
        sortable: true,
        filterable: true,
        categorizer: (value: string | boolean) => {
          switch (value) {
            case 'true':
              return 'true';
            case 'false':
              return 'false';
            case true:
              return 'true';
            case false:
              return 'false';
            default:
              return '';
          }
        },
      },
      // {
      //     displayName: 'Customs Cleared',
      //     name: 'customsCompleted',
      //     dataType: 'boolean',
      //     sortable: true,
      //     filterable: true,
      //     categorizer: (value: string | boolean) => {
      //         switch (value) {
      //             case true:
      //                 return 'true'
      //             case false:
      //                 return 'false'
      //             default:
      //                 return ''
      //         }
      //     }
      // },
      // {
      //     displayName: 'Status',
      //     name: 'internalStatus',
      //     dataType: 'string',
      //     sortable: true,
      //     filterable: true,
      //     categorizer: externalStatusFromInternalStatus
      // },
    ],
    sortConfig: [],
    filterConfig: {},
  });

  return (
    <ImportalTable
      hasRowExpand={false}
      data={rows}
      rowGenerator={(row) => <Row row={row} />}
      tableConfig={shipmentTableConfig}
      setTableConfig={setShipmentTableConfig}
      loading={isLoading}
      useSettings={false}
    />
  );
};
export default ShipmentTable;
