import dayjs from 'dayjs';
import { FeatureName, UsagePlanMetadata } from 'common/interfaces/plan';
import moment from 'moment';

export const activePlanUtils = (planMetadata?: UsagePlanMetadata) => {
  return planMetadata?.planUsage.find((feature) => feature.featureName === FeatureName.SEARCHES)?.usage || 0;
};

export const planIsExhaustedForFeature = (featureName: FeatureName, planMetadata?: UsagePlanMetadata): boolean => {
  const feature = planMetadata?.planUsage.find((f) => f.featureName === featureName);
  if (feature && feature.limit) {
    return feature.usage >= feature.limit;
  }
  return false;
};

/*
 * Determines if a user has access to a specific feature in a plan.
 *
 * This function evaluates access based on two conditions:
 * 1. The feature exists in the plan metadata and the feature's usage is not exhausted.
 * 2. Custom onboarding access is allowed and the user has completed the necessary onboarding steps.
 *
 * @param {FeatureName} featureName - The name of the feature for which access is being checked.
 * @param {UsagePlanMetadata | undefined} planMetadata - Metadata about the user's plan,
 * including usage information for various features. Optional.
 * @param {boolean | undefined} canCustomsGiveAccess - Indicates if custom onboarding can grant access
 * to the feature. Optional; defaults to `false` if not provided.
 * @param {boolean | undefined} hasCustomsOnboardingAccess - Indicates if the user has completed custom
 * onboarding and is eligible for access. Optional; defaults to `false` if not provided.
 *
 * @returns {boolean} - Returns `true` if the user has access to the feature,
 * either because the feature is available in the plan or through custom onboarding.
 * Returns `false` otherwise.
 */

export const userHasAccessToFeature = (
  featureName: FeatureName,
  planMetadata?: UsagePlanMetadata,
  canCustomsGiveAccess?: boolean,
  hasCustomsOnboardingAccess?: boolean
): boolean => {
  const feature = planMetadata?.planUsage.find((f) => f.featureName === featureName);
  return (
    (Boolean(feature) && !planIsExhaustedForFeature(featureName, planMetadata)) ||
    (Boolean(canCustomsGiveAccess) && Boolean(hasCustomsOnboardingAccess))
  );
};

export const formatMMMDDYYYYDate = (dateString: string) => moment(dateString).format('MMMM Do, YYYY');
export const mmmddyyyyDate = (dateString: string) => dayjs(dateString).format('MMM DD YYYY');
export const mmmddyyHmmDate = (dateString: string) => dayjs(dateString).format('MMM DD YY h:mm A');
