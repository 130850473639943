import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import WhatAreYouImporting from '../what-are-you-importing/WhatAreYouImporting';
import './HtsClassification.css';
import UploadProductImages from '../upload-product-images/UploadProductImages';
import CloseIcon from '@mui/icons-material/Close';
import BackdropLoader from '../snackbar-loader/SnackbarLoader';
import HtsConfirmation from '../hts-confirmation/HtsConfirmation';
import {
  Classification,
  ClassificationPopulated,
  ClassificationStatus,
  ClassificationType,
  convertPopulatedToClassification,
} from 'common/interfaces/classification';
import { useAPI } from '@/api/APIContext';
import { Document } from 'common/interfaces/document';

const steps = ['Tell us about your business', 'Sign on to do business with us', "You're in"];

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function HtsClassification(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const fetchClassification = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.getMostRecentUnsubmittedClassification();
      if (data.classification) {
        setClassification(data.classification);
      }
    } catch (err) {
      console.error('error getting most recent unsubmitted classification for user');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  useEffect(() => {
    // hmm, in here we should make sure to either have the draft OR start a classification
    fetchClassification();
  }, [fetchClassification]);

  const [classification, setClassification] = useState<ClassificationPopulated | undefined>();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  function createClassification(classification) {
    classification.status = ClassificationStatus.DRAFT;
    classification.classificationType = ClassificationType.USER_DESCRIPTION;
    setIsLoading(true);
    api
      .createClassification(classification)
      .then(({ data }) => {
        setClassification(data);
      })
      .catch((error) => {
        console.error('error creating classification');
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function updateClassification(classification: ClassificationPopulated) {
    setIsLoading(true);
    const classificationToUpdate: Classification = convertPopulatedToClassification(
      classification as ClassificationPopulated
    );
    api
      .updateClassificationWithId(classificationToUpdate!._id.toString(), classificationToUpdate!)
      .then(({ data }) => {
        setClassification(data);
      })
      .catch(function (error) {
        console.error('error updating classification');
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    onClose();
  };

  const steps = [
    {
      label: 'string',
      element: (
        <WhatAreYouImporting
          description={classification?.description}
          onDescriptionChanged={(description: string) => {
            setClassification({ ...(classification as ClassificationPopulated), description });
          }}
          materialComposition={classification?.materialComposition}
          onMaterialCompositionChanged={(materialComposition: string) => {
            setClassification({ ...(classification as ClassificationPopulated), materialComposition });
          }}
          onNextClicked={() => {
            if (classification?._id) {
              updateClassification(classification!);
            } else {
              createClassification(classification);
            }
            handleNext();
          }}
        />
      ),
    },
    {
      label: 'string',
      element: (
        <UploadProductImages
          onNextClicked={(photos: Document[]) => {
            classification!.documents = photos;
            classification!.status = ClassificationStatus.CONSUMER_SUBMITTED_PENDING;
            updateClassification(classification!);
            handleNext();
          }}
        />
      ),
    },
    {
      label: 'string',
      element: <HtsConfirmation handleEvent={handleComplete} />,
    },
  ];

  const getElements = (): React.JSX.Element[] => {
    return steps.map((step) => step['element']);
  };

  return (
    <Dialog maxWidth="md" fullWidth={true} onClose={handleClose} open={open}>
      <DialogTitle sx={{ fontSize: '24px' }}>
        Request HTS Classification
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className="step-title-container">
        <div>
          Step {activeStep + 1} / {steps.length}
        </div>
      </div>
      {(activeStep !== 0 && activeStep !== 1) || (isLoading && <BackdropLoader isLoading={isLoading} />)}
      {getElements()[activeStep]}
    </Dialog>
  );
}
