import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import * as ls from 'local-storage';
import { datadogRum } from '@datadog/browser-rum';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { AxiosError } from 'axios';
import { CustomsOnboarding, CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';

interface ActiveCustomsOnboardingContextProps {
  children: ReactNode;
}

export type IActiveCustomsOnboardingContext = {
  customsOnboarding?: CustomsOnboarding;
  hasCompletedOnboarding: boolean;
  refreshCustomsOnboarding: () => void;
  updateCustomsOnboarding: (businessId: string, customsOnboarding: any) => void;
};
const ActiveCustomsOnboardingContext = createContext<IActiveCustomsOnboardingContext>({
  customsOnboarding: undefined,
  hasCompletedOnboarding: false,
  refreshCustomsOnboarding: () => {},
  updateCustomsOnboarding: (businessId: string | undefined, customsOnboarding: any) => {},
});

export const ActiveCustomsOnboardingProvider: React.FC<ActiveCustomsOnboardingContextProps> = ({ children }) => {
  const activeUser = useActiveUser();
  const api = useAPI();

  const [activeCustomsOnboarding, setActiveCustomsOnboarding] = useState<CustomsOnboarding | undefined>(undefined);

  const getCustomsOnboarding = useCallback(() => {
    api
      .getActiveCustomsOnboarding()
      .then(({ data: customsOnboarding }) => {
        // TODO fix this
        // TODO: remove active business on login & logout?
        datadogRum.setUserProperty('customsOnboarding', customsOnboarding);
        setActiveCustomsOnboarding(customsOnboarding);
        ls.set('active-customs-onboarding', customsOnboarding);
      })
      .catch((err: AxiosError) => {
        console.error('error getting customs onboarding for user', err);
        if (err.response?.data && (err.response.data as any).error) {
          const error = (err.response.data as any).error;
          if (error.endsWith('no longer exists.')) {
            // Clear the caches if error message ends with 'no longer exists'
            datadogRum.setUserProperty('customsOnboarding', null);
            setActiveCustomsOnboarding(undefined);
            ls.remove('active-customs-onboarding');
          }
        }
      });
  }, [api]);

  useEffect(() => {
    setActiveCustomsOnboarding(ls.get('active-customs-onboarding'));

    if (activeUser.user && activeUser.user?.business) {
      getCustomsOnboarding();
    } else {
      setActiveCustomsOnboarding(undefined);
      ls.remove('active-customs-onboarding');
    }
  }, [activeUser.user, getCustomsOnboarding]);

  const refreshCustomsOnboarding = useCallback(() => {
    getCustomsOnboarding();
  }, [getCustomsOnboarding]);

  const hasCompletedOnboarding = (): boolean => {
    return !!(activeCustomsOnboarding && activeCustomsOnboarding.step === CustomsOnboardingSteps.COMPLETE);
  };

  const updateCustomsOnboarding = (businessId: string, customsOnboarding: any) => {
    api
      .updateCustomsOnboarding(businessId, customsOnboarding)
      .then((response) => {
        refreshCustomsOnboarding();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <ActiveCustomsOnboardingContext.Provider
      value={{
        customsOnboarding: activeCustomsOnboarding,
        hasCompletedOnboarding: hasCompletedOnboarding(),
        refreshCustomsOnboarding: refreshCustomsOnboarding,
        updateCustomsOnboarding: updateCustomsOnboarding,
      }}
    >
      {children}
    </ActiveCustomsOnboardingContext.Provider>
  );
};

export const useActiveCustomsOnboarding = (): IActiveCustomsOnboardingContext => {
  const activeCustomsOnboarding = useContext(ActiveCustomsOnboardingContext);
  if (!activeCustomsOnboarding) {
    throw new Error('useActiveCustomsOnboarding must be used within an ActiveCustomsOnboardingProvider');
  }
  return activeCustomsOnboarding;
};
