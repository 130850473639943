import React from 'react';
import { Button, Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './InfoAlert.css';

interface InfoAlertProps {
  message: string | React.ReactNode;
  onClose: () => void;
  onActionClick?: () => void;
  actionText?: string;
}

const InfoAlert: React.FC<InfoAlertProps> = ({ message, onClose, onActionClick, actionText }) => {
  return (
    <Card className="info-alert-container">
      <div className="info-text-container">
        <div className="info-outlined-icon">
          <InfoOutlinedIcon />
        </div>
        <div>{message}</div>
      </div>
      <div className="info-action-container">
        {onActionClick && actionText ? (
          <>
            <Button onClick={onActionClick} className="info-upgrade-button" variant="text">
              {actionText}
            </Button>
            <IconButton onClick={onClose}>
              <CloseIcon className="info-close-button" />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={onClose} className="info-close-button-wrapper">
            <CloseIcon className="info-close-button" />
          </IconButton>
        )}
      </div>
    </Card>
  );
};

export default InfoAlert;
