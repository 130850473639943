import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Card, CircularProgress, IconButton, InputAdornment, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isEmailValid } from 'common/utilities/emailValidator';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import './SignupForm.css';
import { Check, Visibility, VisibilityOff } from '@mui/icons-material';
import WelcomeHeader from '@/shared-components/WelcomeHeader/WelcomeHeader';

export default function SignupForm() {
  const navigate = useNavigate();
  const api = useAPI();
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [randomIndex, setRandomIndex] = useState(0);
  const [termsConditions, setTermsConditions] = useState(true);

  const [emailError, setEmailError] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const formatPhoneNumber = (number) => {
    let numberToReturn = number;
    numberToReturn = numberToReturn.replace(/[-\s()]/g, '');
    return numberToReturn;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userToSubmit = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      phone: formatPhoneNumber(data.get('phone')),
      password: data.get('password'),
    };

    registerUser(userToSubmit);
  };

  const testimonials = [
    {
      quote: 'Very intuitive and straightforward',
      source: 'Head of Operations, Growing apparel group',
    },
    {
      quote: 'I wish I had this tool my whole career.',
      source: 'Head of Production, Large apparel brand',
    },
    {
      quote:
        'Importal helps our team quickly and easily compare vendors for new product lines and the customs costs associated so we always know what our landed cost is before we buy.',
      source: 'VP of Supply Chain, Growing apparel company',
    },
    {
      quote: "I can't do my job without this tool.",
      source: 'Operations Director, Freight forwarding company',
    },
    {
      quote:
        'Importal helps our team quickly and easily compare vendors for new product lines and the customs costs associated so we always know what our landed cost is before we buy.',
      source: 'VP of Supply Chain, Growing apparel company',
    },
    {
      quote: 'Great tool for helping us accurately project our duty liability for outstanding purchase orders.',
      source: 'Operations Manager, Growing apparel company',
    },
  ];

  useEffect(() => {
    setRandomIndex(getRandomIndex(testimonials.length));
  }, []);

  function getRandomIndex(arrayLength) {
    return Math.floor(Math.random() * arrayLength);
  }

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  const handleFormChange = (event: React.FormEvent<HTMLFormElement>) => {
    const email = event.currentTarget['email']?.value;
    if (email && !isEmailValid(email)) {
      setEmailError('Invalid Email');
    } else {
      setEmailError('');
    }
  };

  const formatPhoneNumberOnChange = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumberOnChange(event.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleTermsConditionsChange = (event) => {
    setTermsConditions(!event.target.checked);
  };

  const registerUser = (user) => {
    handleLoading(true);
    api
      .registerUser(user)
      .then(function (response) {
        navigate('/authenticate-phone');
      })
      .catch((error) => {
        console.error('error registering user');
        console.error(error);

        // check for axios error response
        setErrorText(error.response?.data?.message || 'Unknown Error');
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const renderTestimonialQuote = (testimonial) => {
    return (
      <>
        <Typography>{testimonial.quote}</Typography>
        <div className="quote">
          <div>- {testimonial.source} </div>
        </div>
      </>
    );
  };

  return (
    <Card>
      <Box>
        <WelcomeHeader>
          <Typography variant="h4">Sign up for a free trial</Typography>
        </WelcomeHeader>
        <Box component="form" onChange={handleFormChange} onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <div className="sign-up-and-features-container">
            <div className="sign-up-container">
              <div className="name-row">
                <div className="signup-form-field-container">
                  <div className="business-form-field-label">
                    <Typography sx={{ fontWeight: '600' }}>First Name</Typography>
                  </div>
                  <div>
                    <TextField autoComplete="given-name" name="firstName" required fullWidth id="firstName" autoFocus />
                  </div>
                </div>
                <div className="signup-form-field-container">
                  <div className="business-form-field-label">
                    <Typography sx={{ fontWeight: '600' }}>Last Name</Typography>
                  </div>
                  <div>
                    <TextField required fullWidth id="lastName" name="lastName" autoComplete="family-name" />
                  </div>
                </div>
              </div>
              <div className="email-password-row">
                <div className="signup-form-field-container">
                  <div className="business-form-field-label">
                    <Typography sx={{ fontWeight: '600' }}>Email</Typography>
                  </div>
                  <div>
                    <TextField
                      error={!!emailError}
                      helperText={emailError}
                      required
                      fullWidth
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                    />
                  </div>
                </div>
                <div className="signup-form-field-container">
                  <div className="business-form-field-label">
                    <Typography sx={{ fontWeight: '600' }}>Password</Typography>
                  </div>
                  <TextField
                    type={hidePassword ? 'password' : 'text'}
                    required
                    fullWidth
                    id="password"
                    name="password"
                    autoComplete="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={hidePassword ? 'show password' : 'hide password'}>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setHidePassword(!hidePassword);
                              }}
                            >
                              {hidePassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="signup-form-field-container business-phone-container">
                <div className="business-form-field-label">
                  <Typography sx={{ fontWeight: '600' }}>Business Phone</Typography>
                </div>
                <div>
                  <TextField
                    required
                    fullWidth
                    name="phone"
                    type="phone"
                    id="phone"
                    onChange={handlePhoneNumberChange}
                    value={phoneNumber}
                    autoComplete="business-phone"
                  />
                </div>
              </div>
              <div className="terms-conditions-container">
                <input type="checkbox" id="acceptTerms" onChange={handleTermsConditionsChange} />
                <Typography sx={{ fontSize: '12px', color: '#525256' }}>
                  By signing up for an account, you agree to our{' '}
                  <a target="_blank" href="https://www.importal.com/terms-of-use">
                    terms and conditions
                  </a>{' '}
                  and{' '}
                  <a target="_blank" href="https://www.importal.com/privacy-policy">
                    privacy policy.
                  </a>
                </Typography>
              </div>

              <Grid container justifyContent="center">
                <div>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      marginTop: '24px!important',
                      fontWeight: '700',
                      textTransform: 'none',
                      paddingLeft: '32px',
                      paddingRight: '32px',
                      fontSize: '24px!important',
                    }}
                    className="signup-button-green"
                    variant="contained"
                    disabled={isLoading || termsConditions}
                  >
                    {isLoading ? <CircularProgress color="success" size={20} /> : 'Sign Up'}
                  </Button>
                </div>
              </Grid>
              <Grid className="error-text">{errorText}</Grid>
            </div>
            <div className="features-container">
              <Card className="testimonials-card">{renderTestimonialQuote(testimonials[randomIndex])}</Card>
              <div className="key-features-header">Key Features</div>
              <div className="features-row-container">
                <div>
                  <div className="feature-row">
                    <div className="feature-row-text">General Duty Rates</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">Merchandise Processing Fee</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">Harbor Maintenance Fee</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">China 301 Rates</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">China 301 Exclusions</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">Cotton Fee</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">Import Licenses</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                  <div className="feature-row">
                    <div className="feature-row-text">...and much more!</div>
                    <div className="feature-row-check">
                      <Check sx={{ color: 'green' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Card>
  );
}
