import { useMemo, useState } from 'react';
import * as d3 from 'd3';

interface Props {
  width: number;
  height: number;
  categories: { category: string; value: number }[];
  range: number[];
}

export const ImportalHistogramBars = ({ width = 1, height = 1, categories = [], range = [10, 290] }: Props) => {
  const [hoveredBar, setHoveredBar] = useState<number | null>(null);

  const histoBars: {
    barHeight: number;
    barWidth: number;
    barXCenter: number;
    barLabel: string;
    barValue: number;
  }[] = useMemo(() => {
    const xScale = d3
      .scaleBand()
      .domain(categories.map((d) => d.category))
      .range([0, width])
      .padding(0.1);

    const yScale = d3.scaleLinear().domain(range).range([0, height]);

    return categories.map((d) => ({
      barHeight: yScale(d.value),
      barWidth: xScale.bandwidth() / 3,
      barXCenter: xScale(d.category)! + xScale.bandwidth() / 2,
      barLabel: d.category,
      barValue: d.value,
    }));
  }, [width, height, categories, range]);

  return (
    <>
      {histoBars.map(({ barHeight, barWidth, barXCenter, barLabel, barValue }, index) => (
        <g
          key={index}
          transform={`translate(${barXCenter - barWidth / 2}, ${height - barHeight})`}
          onMouseEnter={() => setHoveredBar(index)}
          onMouseLeave={() => setHoveredBar(null)}
        >
          <rect x={0} y={0} width={barWidth} height={barHeight} fill="steelblue" />
          <text x={barWidth / 2} y={-5} textAnchor="middle" fontSize="10px">
            {barLabel}
          </text>
          {hoveredBar === index && (
            <text x={barWidth / 2} y={barHeight / 2} textAnchor="middle" fontSize="12px" fill="black" fontWeight="bold">
              {barValue}
            </text>
          )}
        </g>
      ))}
    </>
  );
};
