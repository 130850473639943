import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Fab from '@mui/material/Fab';

interface MobileMenuToggleButtonProps {
  toggleSideMenu: () => void;
}

const MobileMenuToggleButton: React.FC<MobileMenuToggleButtonProps> = ({ toggleSideMenu }) => {
  return (
    <div className="mobile-menu-toggle-button">
      <Fab
        color="primary"
        aria-label="menu"
        onClick={toggleSideMenu}
        style={{
          position: 'absolute',
          bottom: '16px',
          left: '16px',
        }}
      >
        <MenuIcon />
      </Fab>
    </div>
  );
};

export default MobileMenuToggleButton;
