import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Tooltip, Link } from '@mui/material';
import './BrokerClassificationsTable.css';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';
import { ColumnConfig, TableConfig } from 'common/interfaces/importalTable';
import { Classification, ClassificationStatusToLongText } from 'common/interfaces/classification';
import { useLocation, useNavigate } from 'react-router-dom';
import { renderClassificationStatus } from '@/utils/classificationUtils';

interface RowProps {
  row: Classification;
}

const Row = (props: RowProps) => {
  const { row } = props;
  const navigate = useNavigate();

  const onClassificationLinkClicked = (classification: Classification) => {
    navigate(`/broker/dashboard/hts-classifications/${classification._id}`);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className="cellBorder">
        <TableCell component="th" scope="row">
          <Link
            sx={{ textDecoration: 'none', cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              onClassificationLinkClicked(row);
            }}
          >
            <Typography>{row.referenceNumber}</Typography>
          </Link>
        </TableCell>
        <TableCell>
          <Tooltip title={row.description || ''}>
            <Typography>{row.description ? row.description : '-'}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography>{row.materialComposition ? row.materialComposition : '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{row.intendedUse ? row.intendedUse : '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{row.finalHTSCode || '--'}</Typography>
        </TableCell>
        <TableCell>
          <Tooltip title={ClassificationStatusToLongText[row.status]}>{renderClassificationStatus(row.status)}</Tooltip>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const BrokerClassificationsTable = ({ rows, isLoading }) => {
  const [brokerClassificationsTableConfig, setBrokerClassificationsTableConfig] = useState<TableConfig>({
    columns: [
      {
        displayName: 'Reference #',
        name: 'referenceNumber',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Description',
        name: 'description',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Material Composition',
        name: 'materialComposition',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Intended Use',
        name: 'intendedUse',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'HTS Code',
        name: 'finalHTSCode',
        dataType: 'string',
        sortable: false,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Status',
        name: 'status',
        dataType: 'string',
        sortable: false,
        filterable: false,
        isVisible: true,
      },
    ],
    sortConfig: [],
    filterConfig: {},
  });

  return (
    <div className="broker-classifications-table-container">
      <ImportalTable
        data={rows}
        rowGenerator={(row: Classification, index) => <Row key={`row-${row.referenceNumber}-${index}`} row={row} />}
        tableConfig={brokerClassificationsTableConfig}
        setTableConfig={setBrokerClassificationsTableConfig}
        loading={isLoading}
        hasRowExpand={false}
        useSettings={true}
      />
    </div>
  );
};

export default BrokerClassificationsTable;
