import { Chip } from '@mui/material';
import {
  ClassificationStatus,
  ClassificationStatusColor,
  ClassificationStatusToShortText,
} from 'common/interfaces/classification';

export const renderClassificationStatus = (status: ClassificationStatus) => {
  return (
    <div className="status-chip-container">
      <Chip
        label={ClassificationStatusToShortText[status]}
        sx={{
          backgroundColor: ClassificationStatusColor[status],
          color: 'white',
          fontWeight: 'bold',
          paddingLeft: '5px',
          paddingRight: '5px',
          height: '26px',
        }}
      />
    </div>
  );
};
