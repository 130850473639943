import ReportChart from '@/pages/reports/ReportChart';
import ReportTable from '@/pages/reports/ReportTable';
import { CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import { Report, ReportChartType } from 'common/interfaces/reports';
import { useAPI } from '@/api/APIContext';
import { ReportsPageContext } from '@/pages/reports/ReportsPage';
import { set } from 'lodash';

export interface Props {
  report: Report;
}

export default function ReportAdvanced({ report }: Props) {
  const api = useAPI();

  const { refreshReports } = useContext(ReportsPageContext);
  const [localReport, setLocalReport] = useState<Report>(report);

  const [localQuery, setLocalQuery] = useState<string>(
    JSON.stringify(report.reportConfig?.aggregationPipeline, null, 2)
  );
  const [localChartConfig, setLocalChartConfig] = useState<string>(
    JSON.stringify(report.reportConfig?.chartConfig, null, 2)
  );
  const [localColumns, setLocalColumns] = useState<string>(JSON.stringify(report.reportConfig?.columns, null, 2));

  const [reportData, setReportData] = useState<any[]>([]);
  const [testQueryLoading, setTestQueryLoading] = useState(false);

  const [queryParseError, setQueryParseError] = useState<string>();
  const [chartConfigParseError, setChartConfigParseError] = useState<string>();
  const [columnsParseError, setColumnsParseError] = useState<string>();

  const updateLocalReport = (key: string, newValue: any) => {
    // using lodash set to get to deep nested properties (key can be reportConfig.aggregationPipeline for example)
    setLocalReport(set({ ...localReport }, key, newValue));
  };

  useEffect(() => {
    api
      .getReportData(report._id.toString())
      .then(({ data: reportData }) => {
        setReportData(reportData);
      })
      .catch((err) => {
        console.error('error getting report data');
        console.error(err);
      });
  }, [report]);

  const saveReport = async () => {
    try {
      const { data: updatedReport } = await api.updateReport(report._id.toString(), localReport);
      if (updatedReport.reportConfig && updatedReport.reportConfig.aggregationPipeline) {
        await api.runReport(report._id.toString());
      }

      setLocalReport(updatedReport);
      refreshReports();
    } catch (err) {
      console.error('error updating report');
      console.error(err);
    }
  };

  const onTestQuery = async () => {
    try {
      setTestQueryLoading(true);
      const response = await api.testReportQuery(report._id.toString(), localReport.reportConfig!.aggregationPipeline);
      setReportData(response.data);
    } catch (error) {
      console.error('error running report query');
      console.error(error);
    } finally {
      setTestQueryLoading(false);
    }
  };

  return (
    <div>
      <h3>Report Name</h3>
      <TextField
        value={localReport.name}
        onChange={(event) => updateLocalReport('name', event.target.value)}
      ></TextField>

      <h3>Query</h3>
      <TextField
        fullWidth
        multiline={true}
        rows={12}
        value={localQuery}
        onChange={(event) => {
          setLocalQuery(event.target.value);
          try {
            setQueryParseError(undefined);
            const queryAsObject = JSON.parse(event.target.value);
            if (queryAsObject) {
              updateLocalReport('reportConfig.aggregationPipeline', queryAsObject);
            }
          } catch (err) {
            console.error('error parsing query string');
            console.error(err);
            setQueryParseError('invalid json');
          }
        }}
      />
      {queryParseError && (
        <ul style={{ color: 'red' }}>
          <li>{queryParseError}</li>
        </ul>
      )}
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button variant="contained" color="primary" disabled={!!queryParseError} onClick={onTestQuery}>
          {testQueryLoading ? <CircularProgress color={'inherit'} size={18} /> : 'Test Query'}
        </Button>
      </div>

      <h3>Raw Query Data</h3>
      <ReportTable data={reportData}></ReportTable>

      <h3>Report Table Columns</h3>
      <TextField
        fullWidth
        multiline={true}
        rows={12}
        value={localColumns}
        onChange={(event) => {
          setLocalColumns(event.target.value);
          try {
            setColumnsParseError(undefined);
            const columns = JSON.parse(event.target.value);
            if (columns) {
              updateLocalReport('reportConfig.columns', columns);
            }
          } catch (err) {
            console.error('error parsing columns');
            console.error(err);
            setColumnsParseError('invalid json');
          }
        }}
      />
      {columnsParseError && (
        <ul style={{ color: 'red' }}>
          <li>{columnsParseError}</li>
        </ul>
      )}

      <h3>Report Table</h3>
      <ReportTable data={reportData} columnConfig={localReport.reportConfig?.columns}></ReportTable>

      <h3>Chart Config</h3>
      <TextField
        fullWidth
        multiline={true}
        rows={12}
        value={localChartConfig}
        onChange={(event) => {
          setLocalChartConfig(event.target.value);
          try {
            setChartConfigParseError(undefined);
            const chartConfig = JSON.parse(event.target.value);
            if (chartConfig) {
              updateLocalReport('reportConfig.chartConfig', chartConfig);
            }
          } catch (err) {
            console.error('error parsing chart config');
            console.error(err);
            setChartConfigParseError('invalid json');
          }
        }}
      />
      {chartConfigParseError && (
        <ul style={{ color: 'red' }}>
          <li>{chartConfigParseError}</li>
        </ul>
      )}

      {localReport.reportConfig?.chartConfig &&
        localReport.reportConfig!.chartConfig.chartType !== ReportChartType.NO_CHART && (
          <>
            <h3>Chart</h3>
            <ReportChart chartConfig={localReport.reportConfig!.chartConfig} data={reportData}></ReportChart>
          </>
        )}

      <Button onClick={saveReport}>Save</Button>
    </div>
  );
}
