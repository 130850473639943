import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import * as ls from 'local-storage';
import { datadogRum } from '@datadog/browser-rum';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';

interface BetaFeaturesContextProps {
  children: ReactNode;
}

const BetaFeaturesContext = createContext<string[]>([]);

export const BetaFeaturesProvider: React.FC<BetaFeaturesContextProps> = ({ children }) => {
  const api = useAPI();
  const activeUser = useActiveUser();

  const [activeBetaFeatures, setActiveBetaFeatures] = useState([]);

  const getBetaFeatures = useCallback(() => {
    api
      .getActiveBetaFeaturesForUser()
      .then(({ data: betaFeatures }) => {
        datadogRum.setUserProperty('beta-features', betaFeatures);

        setActiveBetaFeatures(betaFeatures);
        ls.set('active-beta-features', betaFeatures);
      })
      .catch((err) => {
        console.error('error getting beta features for user', err);
      });
  }, [api]);

  useEffect(() => {
    setActiveBetaFeatures(ls.get('active-beta-features') || []);

    if (activeUser.user && Object.keys(activeUser.user).length > 0) {
      getBetaFeatures();
    }
  }, [activeUser.user, getBetaFeatures]);

  return <BetaFeaturesContext.Provider value={activeBetaFeatures}>{children}</BetaFeaturesContext.Provider>;
};

export const useBetaFeatures = (): string[] => {
  const betaFeatures = useContext(BetaFeaturesContext);
  if (!betaFeatures) {
    throw new Error('useBetaFeatures must be used within an BetaFeatureProvider');
  }
  return betaFeatures;
};
