import React from 'react';
import IconButton from '@mui/material/IconButton';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FloatingTooltip from '@/shared-components/FloatingTooltip/FloatingTooltip';
import { Mode } from 'common/interfaces/business';

type CardName = 'businessInfo' | 'businessCompliance' | 'businessBilling';

interface RenderModeIconButtonProps {
  setModeFunction: any;
  mode: Mode;
  cardName: CardName;
  handleCancelEditBusiness: (cardName: CardName) => void;
  handleUpdateBusiness: (cardName: CardName) => void;
  handleChangeMode: (setModeFunction: any, mode: Mode, cardName: CardName) => void;
}

const renderModeIconButton: React.FC<RenderModeIconButtonProps> = ({
  setModeFunction,
  mode,
  cardName,
  handleCancelEditBusiness,
  handleUpdateBusiness,
  handleChangeMode,
}: RenderModeIconButtonProps) => {
  if (mode === Mode.EDIT) {
    return (
      <div>
        <FloatingTooltip title="Cancel" placement="top">
          <IconButton onClick={() => handleCancelEditBusiness(cardName)} style={{ color: 'red' }}>
            <Close />
          </IconButton>
        </FloatingTooltip>
        <FloatingTooltip title="Save changes" placement="top">
          <IconButton onClick={() => handleUpdateBusiness(cardName)} style={{ color: 'green' }}>
            <Check />
          </IconButton>
        </FloatingTooltip>
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex' }}>
        <FloatingTooltip title="Edit" placement="top">
          <IconButton onClick={() => handleChangeMode(setModeFunction, Mode.EDIT, cardName)} style={{ color: 'grey' }}>
            <EditIcon />
          </IconButton>
        </FloatingTooltip>
      </div>
    );
  }
};

export default renderModeIconButton;
