import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ContactUsModal.css';

export interface SimpleDialogProps {
  open: boolean;
  onClose: (e?: any) => void;
}

export default function ContactUsModal(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="contact-us-dialog-container">
      <Dialog maxWidth="md" onClose={handleClose} open={open}>
        <div>
          <div>
            <div className="my-shipments-header">We'd love to hear from you.</div>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          </div>
          <div style={{ marginTop: '12px' }} className="my-shipments-subheader">
            Our goal is to make the complex and confusing world of imports and customs brokerage easy for you. No
            robots, automated machines telling you to dial pound, or anything like that. We are a business founded by
            individuals with a passion for service, and those same founders will be the ones taking your calls.
          </div>
          <div>
            <div className="contact-cta-container">
              <div className="contact-cta-item">
                <div>Phone</div>
                <div>
                  <a href="tel:310-619-6904">
                    <Button
                      type="submit"
                      fullWidth
                      variant="text"
                      className="contact-button"
                      sx={{ textTransform: 'none' }}
                    >
                      310-619-6904
                    </Button>
                  </a>
                </div>
              </div>

              <div className="contact-cta-item">
                <div>Email</div>
                <div>
                  <a href="mailto:founders@importal.com">
                    <Button
                      type="submit"
                      fullWidth
                      variant="text"
                      className="contact-button"
                      sx={{ textTransform: 'none' }}
                    >
                      founders@goimportal.com
                    </Button>
                  </a>
                </div>
              </div>

              <div className="contact-cta-item">
                <div>Set up a 1x1 Meeting</div>
                <div>
                  <a href="https://app.apollo.io/#/meet/GravesCowsar/30-min">
                    <Button
                      type="submit"
                      fullWidth
                      variant="text"
                      className="contact-button"
                      sx={{ textTransform: 'none' }}
                    >
                      Book on Apollo
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
