import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FullOnboarding from '../full-onboarding/FullOnboarding';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

const FullOnBoardingModal = ({ open, handleClose }) => {
  const [isShowContactUs, setIsShowContactUs] = React.useState(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FullOnboarding handleClose={handleClose} isShowContactUs={isShowContactUs}></FullOnboarding>
        </Box>
      </Modal>
    </div>
  );
};
export default FullOnBoardingModal;
