import React from 'react';
import { Button, Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './WarningAlert.css';

interface WarningAlertProps {
  message: string;
  onClose: () => void;
  onActionClick?: () => void;
  actionText?: string;
}

const WarningAlert: React.FC<WarningAlertProps> = ({ message, onClose, onActionClick, actionText }) => {
  return (
    <Card className="warning-alert-container">
      <div className="warning-alert-text-container">
        <div>
          <InfoOutlinedIcon />
        </div>
        <div>{message}</div>
      </div>
      <div className="warning-alert-action-container">
        {onActionClick && actionText ? (
          <>
            <Button className="warning-alert-action-button" variant="text" onClick={onActionClick}>
              {actionText}
            </Button>
            <IconButton onClick={onClose}>
              <CloseIcon className="warning-alert-close-button" />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={onClose}>
            <CloseIcon className="warning-alert-close-button" />
          </IconButton>
        )}
      </div>
    </Card>
  );
};

export default WarningAlert;
