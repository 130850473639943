import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import { Event, EventType } from 'common/eventbus/eventBus';

interface ActiveUserContextProps {
  children: ReactNode;
}

export type Unsubscribe = () => void;
export const DefaultUnsubscribe: Unsubscribe = () => {};

// Define the context interface for the EventBus
export type IEventBusContext = {
  on: (eventType: EventType, callback: (event: Event<any>) => void) => Unsubscribe;
  sendMessage: (message: any) => void;
};

const EventBusContext = createContext<IEventBusContext>({
  on: () => DefaultUnsubscribe,
  sendMessage: () => {},
});

// The provider component that gives access to the EventBus context
export const EventBusProvider: React.FC<ActiveUserContextProps> = ({ children }) => {
  const api = useAPI();
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const eventListeners = useRef<{ [key in EventType]?: ((event: Event<any>) => void)[] }>({});
  const [websocketNeedsSetup, setWebsocketNeedsSetup] = useState(true);

  useEffect(() => {
    if (!websocketNeedsSetup) return;

    api
      .getWebsocketConnection()
      .then((websocket) => {
        setWebsocket(websocket);
        setWebsocketNeedsSetup(false);
      })
      .catch((err) => {
        console.error(err);
        console.error('error opening websocket connection for event bus');
      });
  }, [api, websocketNeedsSetup]);

  // The `on` method for subscribing to events
  const on = (eventType: EventType, callback: (event: Event<any>) => void): Unsubscribe => {
    if (!eventListeners.current[eventType]) {
      eventListeners.current[eventType] = [];
    }
    eventListeners.current[eventType]!.push(callback);
    console.debug(`Subscribed to ${eventType}`);

    // Return an unsubscribe function
    return () => {
      eventListeners.current[eventType] = eventListeners.current[eventType]!.filter(
        (listener) => listener !== callback
      );
      console.debug(`Unsubscribed from ${eventType}`);
    };
  };

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (message) => {
        const data = JSON.parse(message.data) as Event<any>;
        const eventType = data.eventType as EventType;

        if (eventListeners.current[eventType]) {
          eventListeners.current[eventType]!.forEach((callback) => callback(data));
        }
      };

      websocket.onopen = () => {
        console.debug('WebSocket connected');
      };

      websocket.onclose = () => {
        setWebsocketNeedsSetup(true);
        console.debug('WebSocket disconnected');
      };

      websocket.onerror = (error) => {
        setWebsocketNeedsSetup(true);
        console.error('EventBus.WebSocket error', error);
      };
    }
  }, [websocket]);

  // Method to send a message via WebSocket
  const sendMessage = (message: any) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open');
    }
  };

  return <EventBusContext.Provider value={{ on, sendMessage }}>{children}</EventBusContext.Provider>;
};

// Custom hook to use the EventBus
export const useEventBus = (): IEventBusContext => {
  return useContext(EventBusContext);
};
