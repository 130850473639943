import React, { useContext, useState } from 'react';
import { PackingListLineItem, PackingList, RawParsedPackingList } from 'common/interfaces/documentParsing';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import { Box, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';
import { ModifyModeState, renderEntryPrepField } from '@/utils/shipmentUtils';

interface PackingListParsedViewProps {
  packingList: PackingList;
  onPackingListChanged: (arg0: PackingList) => void;
}

const packingListLineItemsStyles = {
  headerCellStyles: {
    backgroundColor: 'lightgray',
    padding: '8px!important',
    marginRight: '2px',
    fontSize: '12px!important',
    fontWeight: '700',
    color: 'black',
  },
  icon: {
    fontSize: '14px',
  },
  itemsTableContainer: {
    marginTop: '16px',
    '& .MuiTableCell-root': {
      padding: '4px!important',
    },
  },
  itemsTableRow: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
    backgroundColor: 'white',
  },
  parsedViewHeader: {
    marginBottom: '2px',
    marginTop: '0px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSubheader: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#525256',
  },
  parsedViewSubheaderContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSummarySection: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    marginTop: '16px',
    gap: '24px',
  },
  parsedViewSummarySectionColumn: {
    flex: 1,
    position: 'relative' as 'relative',
  },
  parsedViewSummarySectionColumnHeader: {
    fontWeight: 'bold',
    color: 'black',
  },
  importerAddressSpan: {
    marginRight: '3px',
  },
  fieldContainer: {
    fontSize: '12px',
    color: '#525256',
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  editIconButton: {
    position: 'absolute' as 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
  },
};

const managePackingListLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'PO #',
      viewComponent: (item: PackingListLineItem) => <>{item.poNumber}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.poNumber}
          onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
    {
      header: 'SKU',
      viewComponent: (item: PackingListLineItem) => <>{item.sku}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.sku}
          onChange={(e) => setItem({ ...item, sku: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
    {
      header: 'Description',
      viewComponent: (item: PackingListLineItem) => <>{item.description}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.description}
          onChange={(e) => setItem({ ...item, description: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item: PackingListLineItem) => <>{item.unitQuantity}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.unitQuantity}
          onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
    {
      header: '# Cartons',
      viewComponent: (item: PackingListLineItem) => <>{item.cartonCount}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.cartonCount}
          onChange={(e) => setItem({ ...item, cartonCount: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
    {
      header: 'Net Wt.',
      viewComponent: (item: PackingListLineItem) => <>{item.netWeight}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.netWeight}
          onChange={(e) => setItem({ ...item, netWeight: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
    {
      header: 'Gross Wt.',
      viewComponent: (item: PackingListLineItem) => <>{item.grossWeight}</>,
      editComponent: (item: PackingListLineItem, setItem: (arg0: PackingListLineItem) => void) => (
        <TextField
          value={item.grossWeight}
          onChange={(e) => setItem({ ...item, grossWeight: e.target.value })}
          fullWidth
          variant="standard"
          size="small"
        />
      ),
    },
  ],
};

const PackingListParsedView: React.FC<PackingListParsedViewProps> = ({ packingList, onPackingListChanged }) => {
  const { loadingStatus } = useContext(EntryPrepTabContext);

  const initialModifyMode: ModifyModeState<RawParsedPackingList> = {
    importerOfRecordName: { editing: false, hovering: false },
    importerOfRecordStreet: { editing: false, hovering: false },
    importerOfRecordCity: { editing: false, hovering: false },
    importerOfRecordState: { editing: false, hovering: false },
    importerOfRecordZip: { editing: false, hovering: false },
    importerOfRecordCountry: { editing: false, hovering: false },
    supplierName: { editing: false, hovering: false },
    supplierStreet: { editing: false, hovering: false },
    supplierCity: { editing: false, hovering: false },
    supplierState: { editing: false, hovering: false },
    supplierZip: { editing: false, hovering: false },
    supplierCountry: { editing: false, hovering: false },
    invoiceDate: { editing: false, hovering: false },
    invoiceNumber: { editing: false, hovering: false },
    totalNetWeight: { editing: false, hovering: false },
    totalGrossWeight: { editing: false, hovering: false },
    totalCartonCount: { editing: false, hovering: false },
    countryOfOrigin: { editing: false, hovering: false },
    additionalProperties: { editing: false, hovering: false },
    lineItems: { editing: false, hovering: false }, // Note: `lineItems` may need specific handling if each item should have `editing` and `hovering` states
  };

  const [modifyMode, setModifyMode] = useState<ModifyModeState<RawParsedPackingList>>(initialModifyMode);

  return (
    <Box style={styles.parsedViewContainer}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', color: 'black', gap: '8px', marginBottom: '24px' }}
      >
        <div style={{ fontSize: '12px' }}>
          <b>Invoice Number</b>
          <div style={{ color: '#525256' }}>
            {renderEntryPrepField(
              'invoiceNumber',
              packingList.invoiceNumber || 'No Invoice Number',
              modifyMode,
              setModifyMode,
              <TextField variant="standard" value={packingList.invoiceNumber} fullWidth />,
              <span>{packingList.invoiceNumber || 'No Invoice Number'}</span>
            )}
          </div>
        </div>
        <div>
          <h4 style={styles.parsedViewHeader}>Packing List</h4>
          <div style={styles.parsedViewSubheader}>
            <div>
              {renderEntryPrepField(
                'importerOfRecordName',
                packingList.importerOfRecordName as string,
                modifyMode,
                setModifyMode,
                <TextField
                  variant="standard"
                  value={packingList.importerOfRecordName}
                  fullWidth
                  InputProps={{ style: { fontSize: 12 } }}
                />,
                <div style={styles.parsedViewSubheaderContent}>{packingList.importerOfRecordName}</div>
              )}
              <div style={styles.parsedViewSubheaderContent}>
                {renderEntryPrepField(
                  'importerOfRecordStreet',
                  packingList.importerOfRecordStreet as string,
                  modifyMode,
                  setModifyMode,
                  <TextField
                    variant="standard"
                    value={packingList.importerOfRecordStreet}
                    fullWidth
                    InputProps={{ style: { fontSize: 12 } }}
                  />,
                  <span style={styles.importerAddressSpan}>{packingList.importerOfRecordStreet},</span>
                )}
                {renderEntryPrepField(
                  'importerOfRecordCity',
                  packingList.importerOfRecordCity as string,
                  modifyMode,
                  setModifyMode,
                  <TextField
                    variant="standard"
                    value={packingList.importerOfRecordCity}
                    fullWidth
                    InputProps={{ style: { fontSize: 12 } }}
                  />,
                  <span style={styles.importerAddressSpan}>{packingList.importerOfRecordCity},</span>
                )}{' '}
                {renderEntryPrepField(
                  'importerOfRecordState',
                  packingList.importerOfRecordState as string,
                  modifyMode,
                  setModifyMode,
                  <TextField
                    variant="standard"
                    value={packingList.importerOfRecordState}
                    fullWidth
                    InputProps={{ style: { fontSize: 12 } }}
                  />,
                  <span style={styles.importerAddressSpan}>{packingList.importerOfRecordState}, </span>
                )}{' '}
                {renderEntryPrepField(
                  'importerOfRecordZip',
                  packingList.importerOfRecordZip as string,
                  modifyMode,
                  setModifyMode,
                  <TextField
                    variant="standard"
                    value={packingList.importerOfRecordZip}
                    fullWidth
                    InputProps={{ style: { fontSize: 12 } }}
                  />,
                  <span>{packingList.importerOfRecordZip}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ fontSize: '12px' }}>
          <b>Invoice Date</b>
          <div style={{ color: '#525256' }}>
            {renderEntryPrepField(
              'invoiceDate',
              formatMMMDDYYYYDate(packingList.invoiceDate?.toString()) || 'No Invoice Date',
              modifyMode,
              setModifyMode,
              <TextField
                variant="standard"
                value={formatMMMDDYYYYDate(packingList.invoiceDate?.toString()) || ''}
                fullWidth
              />,
              <span>{formatMMMDDYYYYDate(packingList.invoiceDate?.toString()) || 'no invoice date'}</span>
            )}
          </div>
        </div>
      </div>
      <div style={styles.parsedViewSummarySection}>
        <div style={styles.parsedViewSummarySectionColumn}>
          <div style={styles.parsedViewSummarySectionColumnHeader}>Supplier Information</div>
          {renderEntryPrepField(
            'supplierName',
            packingList.supplierName as string,
            modifyMode,
            setModifyMode,
            <TextField
              variant="standard"
              value={packingList.supplierName}
              fullWidth
              InputProps={{ style: { fontSize: 12 } }}
            />,
            <span>{packingList.supplierName || 'No Supplier Name'}</span>
          )}
          {renderEntryPrepField(
            'supplierStreet',
            packingList.supplierStreet as string,
            modifyMode,
            setModifyMode,
            <TextField
              variant="standard"
              value={packingList.supplierStreet}
              fullWidth
              InputProps={{ style: { fontSize: 12 } }}
            />,
            <span>{packingList.supplierStreet}</span>
          )}
          {renderEntryPrepField(
            'supplierCity',
            packingList.supplierCity as string,
            modifyMode,
            setModifyMode,
            <TextField
              variant="standard"
              value={packingList.supplierCity}
              fullWidth
              InputProps={{ style: { fontSize: 12 } }}
            />,
            <span>{packingList.supplierCity}</span>
          )}
          {renderEntryPrepField(
            'supplierState',
            packingList.supplierState as string,
            modifyMode,
            setModifyMode,
            <TextField
              variant="standard"
              value={packingList.supplierState}
              fullWidth
              InputProps={{ style: { fontSize: 12 } }}
            />,
            <span>{packingList.supplierState}</span>
          )}
          {renderEntryPrepField(
            'supplierZip',
            packingList.supplierZip as string,
            modifyMode,
            setModifyMode,
            <TextField
              variant="standard"
              value={packingList.supplierZip}
              fullWidth
              InputProps={{ style: { fontSize: 12 } }}
            />,
            <span>{packingList.supplierZip}</span>
          )}
          {renderEntryPrepField(
            'supplierCountry',
            packingList.supplierCountry as string,
            modifyMode,
            setModifyMode,
            <TextField
              variant="standard"
              value={packingList.supplierCountry}
              fullWidth
              InputProps={{ style: { fontSize: 12 } }}
            />,
            <span>{packingList.supplierCountry}</span>
          )}
        </div>
      </div>

      <ManageItems<PackingListLineItem>
        manageItemsConfig={managePackingListLineItemsConfig}
        items={packingList.lineItems}
        getDefaultItem={() => ({
          poNumber: '',
          sku: '',
          description: '',
          materialComposition: '',
          unitQuantity: '',
          unitValue: '',
          currencyCode: '',
          totalValue: '',
          countryOfOrigin: '',
          additionalProperties: {},
          containerNumber: '',
          htsno: '',
          unitWeight: '',
          netWeight: '',
          grossWeight: '',
          cartonCount: '',
        })}
        itemName=""
        styles={packingListLineItemsStyles}
        useDeleteConfirmationModal={true}
        onItemsUpdated={(items) => {
          onPackingListChanged({ ...packingList, lineItems: items });
        }}
        parentLoadingStatus={loadingStatus}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: 'black',
          fontSize: '12px',
          padding: '6px',
          marginTop: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid lightgrey',
          borderRadius: '4px',
        }}
      >
        <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
        <div>TOTAL (TODO)</div>
      </div>
    </Box>
  );
};

export default PackingListParsedView;
