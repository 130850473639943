import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText, IconButton, InputAdornment } from '@mui/material';
import { RoundedTextfield } from '../common-components/RoundedTextfield';
import './ChangePassword.css';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import * as ls from 'local-storage';
import CloseIcon from '@mui/icons-material/Close';

const validationSchema = yup.object({
  oldPassword: yup.string().trim().min(8, 'Password minimum 8 characters').required('Password is required'),
  newPassword: yup.string().trim().min(8, 'Password minimum 8 characters').required('Password is required'),
  // .oneOf([yup.ref("password"), ""], "Passwords must match")
  // .required("Confirm password is required"),
});

const ChangePassword = ({ openDialogue, open, setSelectedModule, handleLoading }) => {
  const [update, setUpdate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setUpdate(true);
      handleLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/change-password`, values, {
          headers: { Authorization: ls.get('token') },
        })
        .then(function (response) {
          handleLoading(false);
        })
        .catch(function (error) {
          console.error('error changing password');
          console.error(error);
          handleLoading(false);
        });
    },
  });

  const handleDashboard = () => {
    openDialogue();
    setSelectedModule('home');
    setUpdate(false);
  };

  return (
    <Dialog className="change-pwd-wrapper" open={open} onClose={openDialogue}>
      <DialogTitle>Change Password</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={openDialogue}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {!update ? (
            <div className="card-content">
              <RoundedTextfield
                id="oldPassword"
                label="Old Password"
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={formik.handleChange}
                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                InputProps={{
                  endAdornment: (
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev: boolean) => !prev)}>
                      <InputAdornment position="end">
                        {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </InputAdornment>
                    </div>
                  ),
                }}
              />
              <RoundedTextfield
                id="newPassword"
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                InputProps={{
                  endAdornment: (
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev: boolean) => !prev)}>
                      <InputAdornment position="end">
                        {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </InputAdornment>
                    </div>
                  ),
                }}
              />
            </div>
          ) : (
            <DialogContentText className="title-text">Your password has been successfully updated!</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <div className="btn-group">
            {!update ? (
              <Button type="submit" color="info" className="btn-custom" variant="contained">
                Update
              </Button>
            ) : (
              <Button type="submit" onClick={handleDashboard} color="info" variant="contained" className="btn-custom">
                Return to dash
              </Button>
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePassword;
