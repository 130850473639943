import { Button, Card, IconButton } from '@mui/material';
import React, { cloneElement } from 'react';
import './CardWithIconMessageAndCallToAction.css';
import CloseIcon from '@mui/icons-material/Close';
import InfoAlert from '@/components/info-alert/InfoAlert';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';

export interface Props {
  closable: boolean;
  handleClose?: () => void;
  headerText: string;
  messageBody: string;
  backgroundColor: string;
  textColor: string;
  actionButtonText: string;
  onClickActionButton?: () => void;
  cardIcon?: React.JSX.Element;
}
export function CardWithIconMessageAndCallToAction({
  closable,
  handleClose,
  headerText,
  messageBody,
  textColor,
  backgroundColor,
  cardIcon,
  onClickActionButton,
}: Props) {
  const label = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          padding: '0px 8px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="label">
          {cardIcon && (
            <span style={{ marginRight: '8px', display: 'flex', justifyContent: 'center' }}>
              {React.cloneElement(cardIcon, { style: { fontSize: '24px' } })}
            </span>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '8px' }}>
            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{headerText}</span>
          </div>
        </div>

        <IconButton onClick={() => onClickActionButton && onClickActionButton()}>
          <EditIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconButton>
      </div>
    );
  };

  return (
    <Chip
      label={label()}
      onDelete={closable ? handleClose : undefined}
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px',
        width: 'fit-content',
        height: 'none',
        maxWidth: 'none',
        margin: '2px',
        '& .MuiChip-label': {
          width: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px',
        },
      }}
    />
  );
}
