import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface FloatingTooltipProps extends TooltipProps {
  styles?: {
    fontSize?: string;
    backgroundColor?: string;
    maxWidth?: string;
  };
}

const FloatingTooltip = styled(({ className, styles = {}, ...props }: FloatingTooltipProps) => (
  <Tooltip {...props} placement="bottom-end" classes={{ popper: className }} />
))(({ theme, styles }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles?.backgroundColor || 'charcoal',
    fontSize: styles?.fontSize || '13px',
    maxWidth: styles?.maxWidth || '375px',
  },
  [`& .${tooltipClasses.popper}`]: {
    zIndex: theme.zIndex.tooltip,
    position: 'absolute',
  },
}));

export default FloatingTooltip;
