import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Button, Paper, Typography, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { OfferedPurchaseAny } from 'common/interfaces/plan';
import SavedCardList from '../../../plans-and-pricing/SavedCardList';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './ReviewOrder.css';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '16px',
};

export interface Props {
  purchase: OfferedPurchaseAny;
}

const ReviewOrder = ({ setActiveStep, setOpen, open, purchase, hasActiveSubscription }) => {
  const upgradeRef = useRef<HTMLButtonElement>(document.createElement('button'));

  const [discount, setDiscount] = useState<number | null>(() => {
    const storedDiscount = localStorage.getItem('discount');
    return storedDiscount ? JSON.parse(storedDiscount) : null;
  });

  useEffect(() => {
    if (discount !== null) {
      localStorage.setItem('discount', JSON.stringify(discount));
    } else {
      localStorage.removeItem('discount');
    }
  }, [discount]);

  const discountedMonthlyPrice = useMemo(() => {
    return discount ? purchase.advertisedMonthlyPrice * (1 - discount / 100) : purchase.advertisedMonthlyPrice;
  }, [discount, purchase.advertisedMonthlyPrice]);

  return (
    <div>
      <IconButton
        onClick={() => setActiveStep('planDetails')}
        sx={{
          position: 'absolute',
          left: 7,
          top: 8,
          color: 'grey',
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <div className="plan-header">{purchase.displayTitle}</div>
      <div style={{ color: 'grey' }}>
        {purchase.advertisedOneTimePrice && (
          <Typography variant="subtitle1" position="relative" top="10px">
            ${purchase.advertisedOneTimePric}
          </Typography>
        )}
        {purchase.advertisedMonthlyPrice && (
          <Typography variant="subtitle1" position="relative" top="10px">
            {discount ? (
              <div className="monthly-prices">
                <div className="advertised-price">${purchase.advertisedMonthlyPrice}/month</div>{' '}
                <div className="discounted-price">${discountedMonthlyPrice.toFixed(2)}/month</div>
              </div>
            ) : (
              <>${purchase.advertisedMonthlyPrice}/month</>
            )}
          </Typography>
        )}
        {purchase.advertisedYearlyPrice && (
          <Typography variant="subtitle1">${purchase.advertisedYearlyPrice}/year</Typography>
        )}
      </div>
      <Paper
        id="modal-modal-description"
        sx={{
          mt: 3,
          boxShadow: `0px 8px 12px 0px rgba(76, 103, 100, 0.03), 
          0px -8px 12px 0px rgba(76, 103, 100, 0.03), 
          8px 0px 12px 0px rgba(76, 103, 100, 0.03), 
          -8px 0px 12px 0px rgba(76, 103, 100, 0.03) !important`,
          boxSizing: 'border-box',
          width: '553px',
          background: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div className="vmcr-order-line">Enter your credit card information below, and we'll get rolling.</div>
        <SavedCardList
          setOpen={setOpen}
          open={open}
          plan={purchase.key}
          upgradeRef={upgradeRef}
          setActiveStep={setActiveStep}
          setDiscount={setDiscount}
        />
      </Paper>

      <Box>
        <div className="next-button-container">
          <Button variant="contained" sx={{ width: '130px!important' }} className="next-button-green" ref={upgradeRef}>
            {hasActiveSubscription ? 'Upgrade' : 'Purchase'}
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default ReviewOrder;
