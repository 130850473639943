import { Box, Tab, Tabs } from '@mui/material';
import React, { useState, useCallback, memo } from 'react';
import { TabPanelProps } from '@mui/joy';

export interface TabConfig {
  title: string;
  element: React.JSX.Element;
}

export interface Props {
  tabsConfig: TabConfig[];
  inCard?: boolean;
  style?: React.CSSProperties;
  tabsOrientation?: 'horizontal' | 'vertical';
}

const defaultStyles = {
  tabContainer: {
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    textTransform: 'none',
    fontSize: '16px',
    borderRadius: '10px',
    marginBottom: '5px',
  },
  activeTab: {
    fontWeight: 700,
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 8px', // more subtle and uniform shadow
    zIndex: 1,
  },
  inactiveTab: {
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: 0,
  },
  tabPanel: {
    boxShadow: `0px 8px 12px 0px rgba(76, 103, 100, 0.03), 
          0px -8px 12px 0px rgba(76, 103, 100, 0.03), 
          8px 0px 12px 0px rgba(76, 103, 100, 0.03), 
          -8px 0px 12px 0px rgba(76, 103, 100, 0.03)!important`,
    borderRadius: '16px',
    padding: '24px',
    margin: '0 8px 12px 8px',
  },
};

// Memoized TabPanel to avoid unnecessary re-renders
const CustomTabPanel = memo(
  (props: TabPanelProps & { index: number; value: number; inCard?: boolean; tabsOrientation?: string }) => {
    const { children, value, index, inCard = false, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={inCard ? defaultStyles.tabPanel : {}}>{children}</Box>}
      </div>
    );
  }
);

// Main ImportalTabs component
export default function ImportalTabs({ tabsConfig, inCard = false, style, tabsOrientation = 'horizontal' }: Props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  }, []);

  return (
    <div style={style}>
      {/* Box component to hold the tabs */}
      <Box sx={defaultStyles.tabContainer}>
        {' '}
        {/* Apply default container styles */}
        <Tabs
          value={selectedTabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ sx: { display: 'none' } }} // Hide default tab indicator
          orientation={tabsOrientation}
        >
          {tabsConfig.map((tabConfig, index) => (
            <Tab
              key={index}
              sx={{
                ...defaultStyles.tab,
                ...(selectedTabIndex === index ? defaultStyles.activeTab : defaultStyles.inactiveTab),
              }} // Apply default tab styles and active/inactive styling
              label={tabConfig.title}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      {/* Render each tab's panel */}
      {tabsConfig.map((tabConfig, index) => (
        <div>
          <CustomTabPanel key={index} value={selectedTabIndex} index={index} inCard={inCard}>
            {tabConfig.element}
          </CustomTabPanel>
        </div>
      ))}
    </div>
  );
}
