import * as React from 'react';
import { useState } from 'react';
import { Button, Dialog, IconButton, Typography } from '@mui/material';
import './ManifestModal.css';
import CloseIcon from '@mui/icons-material/Close';
import VMCRSuppressionInfo from '../suppression-info/VMCRSuppressionInfo';
import VMCRNameVariant from '../vmcr-name-variation/VMCRNameVariation';
import VMCRReview from '../vmcr-review/VMCRReview';
import ManifestContext from './ManifestProvider';
import VMCRPayment from '../vmcr-payment/VMCRPayment';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { VMCR } from 'common/interfaces/vmcr';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { useAPI } from '@/api/APIContext';

const steps = ['Tell us about your business', 'Sign on to do business with us', "You're in", 'test', 'test'];

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function ManifestModal({ onClose, open }: SimpleDialogProps) {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [currentManifestRequest, setCurrentManifestRequest] = useState<VMCR>({
    nameVariations: [''],
  });
  const activeBusiness = useActiveBusiness();
  const activePlan = useActivePlan();

  const handleClose = (event, reason): any => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    onClose();
  };

  const submitVMCR = () => {
    api
      .createVCMR(currentManifestRequest)
      .then(function (response) {
        // setCurrentBusiness(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function VMCRFinished({ onClickClose }) {
    return (
      <>
        <div className="vmcr-confirmation-container">
          <div>
            <div className="my-shipments-header">Privacy is headed your way!</div>
            <div className="my-shipments-subheader">
              Payment was a success and your request has been submitted. We'll take it from here.
            </div>
          </div>
        </div>
        <div className="next-button-container">
          <Button
            type="submit"
            className="next-button-green"
            sx={{ marginTop: '-32px' }}
            variant="contained"
            onClick={(e) => onClickClose()}
          >
            Close
          </Button>
        </div>
      </>
    );
  }

  const getManifestModalSteps = () => {
    if (activePlan.hasAvailableVMCR) {
      return [
        <VMCRSuppressionInfo handleNext={handleNext} handleBack={handleBack} />,
        <VMCRNameVariant handleNext={handleNext} handleBack={handleBack} />,
        <VMCRReview
          handleNext={() => {
            submitVMCR();
            handleNext();
          }}
          handleBack={handleBack}
          currentBusiness={activeBusiness.business}
          isLastStep={true}
        />,
        <VMCRFinished onClickClose={onClose} />,
      ];
    } else {
      return [
        <VMCRSuppressionInfo handleNext={handleNext} handleBack={handleBack} />,
        <VMCRNameVariant handleNext={handleNext} handleBack={handleBack} />,
        <VMCRReview
          handleNext={handleNext}
          handleBack={handleBack}
          currentBusiness={activeBusiness.business}
          isLastStep={false}
        />,
        <VMCRPayment
          handleNext={() => {
            submitVMCR();
            handleNext();
          }}
          handleBack={handleBack}
        />,
        <VMCRFinished onClickClose={onClose} />,
      ];
    }
  };

  return (
    <ManifestContext.Provider value={{ currentManifestRequest, setCurrentManifestRequest }}>
      <Dialog maxWidth="lg" fullWidth={true} onClose={handleClose} open={open}>
        <div className="header-question">
          <Typography>Vessel Manifest Confidentiality Request</Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="vmcr-step-title-container">
          Step {activeStep + 1}/{getManifestModalSteps().length}
        </div>
        {getManifestModalSteps()[activeStep]}
      </Dialog>
    </ManifestContext.Provider>
  );
}
