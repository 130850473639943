import React, { useMemo } from 'react';
import { ChartConfig } from 'common/interfaces/reports';
import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import './ImportalPieChart.css';

/**
 * Example ChartConfig for PIE:
 *
 * "chartConfig": {
 *     "chartType": "PIE",
 *     "chartName": "Distribution of Product Sourcing by Country",
 *     "chartDescriptor": "This chart shows the countries from which we source our products.",
 *     "histogramConfig": {
 *         "seriesNameKey": "countryOfOrigin",
 *         "seriesValueKey": "productCount",
 *     }
 * }
 */

// inspiration: https://heyoka.medium.com/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72

export interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

const chartSettings = {
  marginLeft: 1,
  marginTop: 12,
};

// A helper component to render pie segments
interface PieSegment {
  name: string;
  value: number;
  percentage: number;
  offset: number;
  color: string;
}

interface PieSegmentsProps {
  segments: PieSegment[];
  radius: number;
  strokeWidth: number;
}

function ImportalPieSegments({ segments, radius, strokeWidth }: PieSegmentsProps) {
  return (
    <>
      {segments.map((segment, i) => (
        <circle
          key={i}
          className="donut-segment"
          cx="21"
          cy="21"
          r={radius}
          fill="transparent"
          stroke={segment.color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${segment.percentage} ${100 - segment.percentage}`}
          strokeDashoffset={segment.offset}
          aria-label={`${segment.name}: ${segment.value}`}
        >
          <title>{`${segment.name}: ${segment.value}`}</title>
        </circle>
      ))}
    </>
  );
}

export default function ImportalPieChart({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions(chartSettings);

  const seriesData = useMemo(() => {
    if (!chartConfig?.histogramConfig?.seriesNameKey || !chartConfig?.histogramConfig?.seriesValueKey) return [];
    const { seriesNameKey, seriesValueKey } = chartConfig.histogramConfig;
    return data.map((datum) => ({
      name: datum[seriesNameKey],
      value: parseFloat(datum[seriesValueKey]),
    }));
  }, [chartConfig, data]);

  // Compute total
  const total = useMemo(() => {
    return seriesData.reduce((sum, d) => sum + d.value, 0);
  }, [seriesData]);

  // Compute segments
  // We'll create a donut with segments laid out in a circle.
  // We'll mimic a donut of radius and an inner radius (just a hole).
  // The original code sets a `15.91549430918954` radius for the path
  // We'll keep that radius for consistency.
  const radius = 15.91549430918954;
  const strokeWidth = 3;

  // We'll cycle through some colors. In production, you might want a color scale or a prop:
  const colors = ['#ce4b99', '#b1c94e', '#377bbc', '#fdd835', '#5e35b1', '#fb8c00'];

  const segments = useMemo(() => {
    if (total === 0) return [];

    // We need to place segments in a circle. The strokeDasharray uses percentages of 100.
    // The offset moves each segment around the donut. We'll accumulate an offset.
    let currentOffset = 25; // start offset to replicate original styling
    return seriesData.map((item, index) => {
      const percentage = (item.value / total) * 100;
      const segment: PieSegment = {
        name: item.name || 'Unlabeled',
        value: item.value,
        percentage: percentage,
        offset: currentOffset,
        color: colors[index % colors.length],
      };
      // Next segment offset is currentOffset + percentage
      currentOffset = currentOffset - percentage;
      return segment;
    });
  }, [seriesData, total, colors]);

  return (
    <div className="Chart__wrapper" ref={ref} style={{ height: '600px' }}>
      <figure>
        <svg width={dms.width} height={dms.height}>
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop})`}>
            <rect width={dms.boundedWidth} height={dms.boundedHeight} fill="lavender" />

            <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut" role="img">
              <title id="pie-title">{chartConfig.chartName}</title>
              <desc id="pie-desc">{chartConfig.chartDescriptor}</desc>

              <circle className="donut-hole" cx="21" cy="21" r={radius} fill="#fff" role="presentation"></circle>
              <circle
                className="donut-ring"
                cx="21"
                cy="21"
                r={radius}
                fill="transparent"
                stroke="#d2d3d4"
                strokeWidth={strokeWidth}
                role="presentation"
              ></circle>

              <ImportalPieSegments segments={segments} radius={radius} strokeWidth={strokeWidth} />

              <g className="chart-text">
                <text x="50%" y="50%" className="chart-number">
                  {total}
                </text>
                <text x="50%" y="50%" className="chart-label">
                  {chartConfig.chartName}
                </text>
              </g>
            </svg>

            {/* Legend */}
            <g transform={`translate(0, 12)`}>
              <foreignObject width={dms.boundedWidth} height="200">
                <div className="figure-key">
                  <p className="sr-only">{chartConfig.chartDescriptor}</p>
                  <ul className="figure-key-list" aria-hidden="true" role="presentation">
                    {segments.map((segment, i) => (
                      <li key={i}>
                        <span className="shape-circle" style={{ backgroundColor: segment.color }}></span> {segment.name}{' '}
                        ({segment.value})
                      </li>
                    ))}
                  </ul>
                </div>
              </foreignObject>
            </g>
          </g>
        </svg>
        {/*<figcaption className="figure-key">*/}
        {/*  <p className="sr-only">{chartConfig.chartDescriptor}</p>*/}
        {/*  <ul className="figure-key-list" aria-hidden="true" role="presentation">*/}
        {/*    {segments.map((segment, i) => (*/}
        {/*        <li key={i}>*/}
        {/*      <span*/}
        {/*          className="shape-circle"*/}
        {/*          style={{ backgroundColor: segment.color }}*/}
        {/*      ></span>{" "}*/}
        {/*          {segment.name} ({segment.value})*/}
        {/*        </li>*/}
        {/*    ))}*/}
        {/*  </ul>*/}
        {/*</figcaption>*/}
      </figure>
    </div>
  );
}
