import { EntryInvoice, EntryInvoiceLineItem } from 'common/interfaces/entry';
import React, { useContext, useState } from 'react';
import { CommercialInvoiceLineItem } from 'common/interfaces/documentParsing';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import { Box, Card, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';

interface Props {
  entryInvoice: EntryInvoice;
  onEntryInvoiceChanged?: (arg0: EntryInvoice) => void;
}

export default function EntryInvoiceView({ entryInvoice, onEntryInvoiceChanged }: Props) {
  const { loadingStatus, setLoadingStatus } = useContext(EntryPrepTabContext);
  const [modifyMode, setModifyMode] = useState<{ [key: string]: boolean }>({
    importer: false,
    supplier: false,
    manufacturer: false,
    invoiceNumber: false,
    invoiceDate: false,
  });

  const [editingField, setEditingField] = useState<string | null>(null);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({
    invoiceNumber: entryInvoice.invoiceNumber,
    invoiceDate: formatMMMDDYYYYDate(entryInvoice.invoiceDate.toString()),
  });

  const handleFieldChange = (field: string, value: string) => {
    setFieldValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleEditClick = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseEnter = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseLeave = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: false }));
  };

  const renderCommercialInvoiceField = (field: string, displayValue: string) => {
    const isHovered = modifyMode[field];

    return (
      <div
        onMouseEnter={() => handleMouseEnter(field)}
        onMouseLeave={() => handleMouseLeave(field)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>{displayValue}</div>
        {isHovered && (
          <IconButton
            onClick={() => setModifyMode((prev) => ({ ...prev, [field]: true }))}
            size="small"
            sx={{ padding: '0px', marginLeft: '2px' }}
          >
            <EditIcon style={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <Card>
      <Box style={styles.parsedViewContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'black',
            gap: '8px',
            marginBottom: '24px',
          }}
        >
          <div style={{ fontSize: '12px' }}>
            <b>Invoice Number</b>
            <div style={{ color: '#525256' }}>
              {renderCommercialInvoiceField('invoiceNumber', entryInvoice.invoiceNumber)}
            </div>
          </div>
          <div>
            <h4 style={styles.parsedViewHeader}>Entry Invoice</h4>
            <div style={styles.parsedViewSubheader}>
              Foot Accessory Manufacturing Inc, 1234 Factory Street, Shenzhen, China
            </div>
          </div>
          <div style={{ fontSize: '12px' }}>
            <b>Invoice Date</b>
            <div style={{ color: '#525256' }}>
              {renderCommercialInvoiceField('invoiceDate', formatMMMDDYYYYDate(entryInvoice.invoiceDate.toString()))}
            </div>
          </div>
        </div>

        <div style={styles.parsedViewSummarySection}>
          <div style={styles.parsedViewSummarySectionColumn}></div>
          {['importer', 'supplier', 'manufacturer'].map((section) => (
            <div
              style={styles.parsedViewSummarySectionColumn}
              onMouseEnter={() => handleMouseEnter(section)}
              onMouseLeave={() => handleMouseLeave(section)}
            >
              <div style={styles.parsedViewSummarySectionColumnHeader}>
                {section === 'importer'
                  ? 'Importer of Record'
                  : section === 'supplier'
                    ? 'Supplier Information'
                    : 'Manufacturer Information'}
              </div>
              <div>
                {section === 'importer' ? (
                  <>
                    {entryInvoice.importerOfRecordName}
                    <br /> {entryInvoice.importerOfRecordStreet}
                    <br /> {entryInvoice.importerOfRecordCity}, {entryInvoice.importerOfRecordState}{' '}
                    {entryInvoice.importerOfRecordZip}
                    <br />
                    {entryInvoice.importerOfRecordCountry}
                  </>
                ) : section === 'supplier' ? (
                  <>
                    {entryInvoice.supplierName}
                    <br /> {entryInvoice.supplierStreet}
                    <br /> {entryInvoice.supplierCity}, {entryInvoice.supplierState} {entryInvoice.supplierZip}
                    <br />
                    {entryInvoice.supplierCountry}
                  </>
                ) : (
                  <>
                    {entryInvoice.manufacturerName}
                    <br /> {entryInvoice.manufacturerStreet}
                    <br /> {entryInvoice.manufacturerCity}, {entryInvoice.manufacturerState}{' '}
                    {entryInvoice.manufacturerZip}
                    <br />
                    {entryInvoice.manufacturerCountry}
                  </>
                )}
              </div>
              {modifyMode[section] && (
                <IconButton style={styles.editIconButton} onClick={() => handleEditClick(section)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          ))}
        </div>

        <ManageItems<EntryInvoiceLineItem>
          manageItemsConfig={manageEntryInvoiceLineItemsConfig}
          items={entryInvoice.lineItems}
          getDefaultItem={() => ({
            poNumber: '',
            sku: '',
            description: '',
            materialComposition: '',
            unitQuantity: '',
            unitValue: '',
            currencyCode: '',
            totalValue: '',
            countryOfOrigin: '',
            additionalProperties: {},
            containerNumber: '',
            htsno: '',
            unitWeight: '',
            netWeight: '',
            grossWeight: '',
            isConsolidated: false,
          })}
          itemName=""
          styles={entryInvoiceLineItemsStyles}
          useDynamicColumnHeader={true}
          useDeleteConfirmationModal={true}
          onItemsUpdated={(items) => {
            onEntryInvoiceChanged?.({ ...entryInvoice, lineItems: items });
          }}
          parentLoadingStatus={loadingStatus}
        />
        <div
          style={{
            fontWeight: 'bold',
            color: 'black',
            fontSize: '12px',
            padding: '6px',
            marginTop: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid lightgrey',
            borderRadius: '4px',
          }}
        >
          <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
          <div>{entryInvoice.totalValue}</div>
        </div>
      </Box>
    </Card>
  );
}

const entryInvoiceLineItemsStyles = {
  headerCellStyles: {
    backgroundColor: 'lightgray',
    padding: '8px!important',
    marginRight: '2px',
    fontSize: '12px!important',
    fontWeight: '700',
    color: 'black',
  },
  icon: {
    fontSize: '14px',
  },
  itemsTableContainer: {
    marginTop: '16px',
    '& .MuiTableCell-root': {
      padding: '4px!important',
    },
  },
  itemsTableRow: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  parsedViewHeader: {
    marginBottom: '2px',
    marginTop: '0px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSubheader: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#525256',
  },
  parsedViewSummarySection: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    marginTop: '16px',
    gap: '24px',
  },
  parsedViewSummarySectionColumn: {
    flex: 1,
    position: 'relative' as 'relative',
  },
  parsedViewSummarySectionColumnHeader: {
    fontWeight: 'bold',
    color: 'black',
  },
  fieldContainer: {
    fontSize: '12px',
    color: '#525256',
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  editIconButton: {
    position: 'absolute' as 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
  },
};

const manageEntryInvoiceLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'PO #',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.poNumber}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="PO #"
          value={item.poNumber}
          onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Container #',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.containerNumber}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Container #"
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'SKU',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.sku}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="SKU"
          value={item.sku}
          onChange={(e) => setItem({ ...item, sku: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Description',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.description}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Description"
          value={item.description}
          onChange={(e) => setItem({ ...item, description: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'HTS',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.htsno}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="HTS Code"
          value={item.htsno}
          onChange={(e) => setItem({ ...item, htsno: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitQuantity}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Quantity"
          value={item.unitQuantity}
          onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Unit Value',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitValue}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Unit Value"
          value={item.unitValue}
          onChange={(e) => setItem({ ...item, unitValue: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Total Value',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.totalValue}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Total Value"
          value={item.totalValue}
          onChange={(e) => setItem({ ...item, totalValue: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'COO',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.countryOfOrigin}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="COO"
          value={item.countryOfOrigin}
          onChange={(e) => setItem({ ...item, countryOfOrigin: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Net Wt.',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.netWeight}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Net Weight"
          value={item.netWeight}
          onChange={(e) => setItem({ ...item, netWeight: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Gross Wt.',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.grossWeight}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          label="Gross Wt."
          value={item.grossWeight}
          onChange={(e) => setItem({ ...item, grossWeight: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
  ],
};
