import React, { useEffect, useState } from 'react';
import { Typography, Button, Dialog, Link, useTheme } from '@mui/material';
import FloatingTooltip from './FloatingTooltip/FloatingTooltip';
import CheckIcon from '@mui/icons-material/Check';

interface TermsAndConditionsModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({ open, onClose, onSubmit }) => {
  const items = [
    { name: 'Importal Terms & Conditions', url: 'https://www.importal.com/terms-of-use' },
    { name: 'Importal Privacy Policy', url: 'https://www.importal.com/privacy-policy' },
  ];
  const theme = useTheme();

  const handleCloseTermsAndConditionsModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseTermsAndConditionsModal}>
      <div>
        <div>
          <div style={{ marginBottom: '12px' }} className="header-question">
            <Typography>Heads Up!</Typography>
          </div>
        </div>
        <div style={{ marginBottom: '12px' }} className="pga-description-subheader">
          We updated the important legal stuff, also known as Importal's Privacy Policy and Terms & Conditions. Click
          the links below to read through them and hit I Agree to continue.
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }} className="legal-links-container">
          <div>
            {items.map((item, index) => (
              <Typography key={item.name} sx={{ color: theme.palette.primary.main }}>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  •
                  <Link
                    sx={{
                      marginLeft: '5px',
                      textDecoration: 'none',
                      fontWeight: '600',
                      fontSize: '16px',
                      alignContent: 'center',
                    }}
                    href={item.url}
                    variant="body2"
                    target="_blank"
                  >
                    {item.name}
                  </Link>
                </div>
              </Typography>
            ))}
          </div>
        </div>
        <div className="next-button-container">
          <Button
            type="submit"
            color="info"
            size="medium"
            onClick={onSubmit}
            variant="contained"
            className="next-button-green"
          >
            I Agree
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
