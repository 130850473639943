import React from 'react';
import { Card, CardContent, Divider, Typography } from '@mui/material';
import './Plan.css';
import { useAPI } from '@/api/APIContext';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { AppContext } from '@/App';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';

const Plan = ({ onSubscriptionDialogue, handleLoading }) => {
  const api = useAPI();
  const betaFeatures = useBetaFeatures();
  const activePlan = useActivePlan();
  const activeUser = useActiveUser();
  const activeBusiness = useActiveBusiness();
  const { setOpenPlansModal } = React.useContext(AppContext);

  function capitalizeWords(input) {
    return input
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const CustomTypographyDiv = ({ children }) => (
    <Typography variant="body2" component="div" gutterBottom>
      {children}
    </Typography>
  );

  return (
    <Card className="settings-card-container">
      <CardContent>
        <div style={{ marginBottom: '32px' }} className="billing-header-container">
          <div className="header-question">
            <Typography variant="h4" gutterBottom>
              Plan
            </Typography>
          </div>
        </div>
        <div className="subscription-plan">
          <div className="subscription-universal-subheader">Your Plan</div>
          <Divider />
          <div className="price-wrap">
            <Typography variant="body2" gutterBottom>
              {!activeBusiness.business && (
                <span>
                  {activePlan.plan.title} Plan
                  <br />
                </span>
              )}

              {activeBusiness.business && !betaFeatures.includes('broker-dashboard') && (
                <span>
                  {activePlan.plan.title} Plan as a part of <b>{activePlan.metadata?.businessName}</b>.<br />
                </span>
              )}

              {activeBusiness.business && betaFeatures.includes('broker-dashboard') && (
                <span>
                  {activePlan.plan.title} Plan as a part of{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/broker/dashboard/businesses/${activeBusiness.business._id}`}
                  >
                    <b>{activePlan.metadata?.businessName}</b>
                  </a>
                  .<br />
                </span>
              )}
              <br />
            </Typography>
            <div className="link-list">
              {(activePlan.isNormalProPlan || activePlan.isFreePlan || activePlan.isVMCRPlan) &&
                !activeUser.user?.isSuperuser && (
                  <Typography
                    variant="h6"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenPlansModal(true);
                    }}
                  >
                    Change Plan
                  </Typography>
                )}
              {(activePlan.isNormalProPlan || activePlan.isVMCRPlan) && !activePlan.isPlanCanceled && (
                <Typography variant="h6" onClick={onSubscriptionDialogue}>
                  Cancel
                </Typography>
              )}
            </div>
          </div>

          <div className="subscription-universal-subheader">Status</div>
          <Divider />
          <div className="price-wrap">
            <CustomTypographyDiv>
              {capitalizeWords(activePlan.plan.status)}
              <br />
            </CustomTypographyDiv>

            <div className="plan-next-steps">
              {activePlan.plan.expireDate && activePlan.plan.status === 'active' && (
                <CustomTypographyDiv>
                  Your Plan renews on {formatMMMDDYYYYDate(activePlan.plan.expireDate.toString())}{' '}
                </CustomTypographyDiv>
              )}
              {activePlan.plan.expireDate && activePlan.plan.status === 'trialing' && (
                <CustomTypographyDiv>
                  Your free trial ends on {formatMMMDDYYYYDate(activePlan.plan.expireDate.toString())}
                </CustomTypographyDiv>
              )}
              {activePlan.plan.expireDate && activePlan.plan.status === 'canceled' && (
                <CustomTypographyDiv>
                  You will have access until {formatMMMDDYYYYDate(activePlan.plan.expireDate.toString())}
                </CustomTypographyDiv>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Plan;
