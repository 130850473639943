import React, { forwardRef, useEffect, useState } from 'react';
import ImportalTable, { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';
import { columnConfigsFromData } from 'common/utilities/importalTable';
import { ColumnConfig, TableConfig } from 'common/interfaces/importalTable';

export interface Props {
  columnConfig?: Array<{ name: string; displayName: string; dataType?: string }>;
  data: any[];
}

const ReportTable = forwardRef<ImportalTableHandles, Props>(({ data, columnConfig }, ref) => {
  const addSortAndFilterToColumns = (
    columns: Array<{ name: string; displayName: string; dataType?: string }>
  ): ColumnConfig[] => {
    return columns.map((column) => ({
      name: column.name,
      displayName: column.displayName,
      dataType: column.dataType || 'string',
      sortable: true,
      filterable: true,
      isVisible: true,
    }));
  };

  const createTableConfig = () => {
    return {
      // @ts-ignore
      columns: addSortAndFilterToColumns(columnConfig || columnConfigsFromData(data)),
      sortConfig: [],
      filterConfig: {},
    };
  };

  const [reportTableConfig, setReportTableConfig] = useState<TableConfig>(createTableConfig);

  useEffect(() => {
    const tableConfig = createTableConfig();
    setReportTableConfig(tableConfig);
  }, [data, columnConfig]);

  // all keys below the first level MUST not be object
  const transformDataForReport = (data: any[]) => {
    return data.map((datum) =>
      Object.keys(datum).reduce((cleanedDatum, key) => {
        cleanedDatum[key] = typeof datum[key] === 'object' ? JSON.stringify(datum[key]) : datum[key];
        return cleanedDatum;
      }, {})
    );
  };

  return (
    <ImportalTable
      ref={ref}
      tableConfig={reportTableConfig}
      setTableConfig={setReportTableConfig}
      data={transformDataForReport(data)}
      embeddedStyling={true}
      useSettings={false}
    />
  );
});

export default ReportTable;
