import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormHelperText, Typography } from '@mui/material';

interface StateType {
  name: string;
}

const states: StateType[] = [
  { name: '' },
  { name: 'Alabama' },
  { name: 'Alaska' },
  { name: 'Arizona' },
  { name: 'Arkansas' },
  { name: 'California' },
  { name: 'Colorado' },
  { name: 'Connecticut' },
  { name: 'Delaware' },
  { name: 'Florida' },
  { name: 'Georgia' },
  { name: 'Hawaii' },
  { name: 'Idaho' },
  { name: 'Illinois' },
  { name: 'Indiana' },
  { name: 'Iowa' },
  { name: 'Kansas' },
  { name: 'Kentucky' },
  { name: 'Louisiana' },
  { name: 'Maine' },
  { name: 'Maryland' },
  { name: 'Massachusetts' },
  { name: 'Michigan' },
  { name: 'Minnesota' },
  { name: 'Mississippi' },
  { name: 'Missouri' },
  { name: 'Montana' },
  { name: 'Nebraska' },
  { name: 'Nevada' },
  { name: 'New Hampshire' },
  { name: 'New Jersey' },
  { name: 'New Mexico' },
  { name: 'New York' },
  { name: 'North Carolina' },
  { name: 'North Dakota' },
  { name: 'Ohio' },
  { name: 'Oklahoma' },
  { name: 'Oregon' },
  { name: 'Pennsylvania' },
  { name: 'Rhode Island' },
  { name: 'South Carolina' },
  { name: 'South Dakota' },
  { name: 'Tennessee' },
  { name: 'Texas' },
  { name: 'Utah' },
  { name: 'Vermont' },
  { name: 'Virginia' },
  { name: 'Washington' },
  { name: 'West Virginia' },
  { name: 'Wisconsin' },
  { name: 'Wyoming' },
];

export default function StatesDropdown({ stateError, handleEvent }) {
  const [value, setValue] = useState<StateType | null>(states[0]);
  const [inputValue, setInputValue] = useState('');
  return (
    <div className="business-form-field-container">
      <div className="business-form-field-label">
        <Typography>State of Incorporation</Typography>
      </div>
      <Autocomplete
        id="country-select-demo"
        sx={{ width: 300 }}
        options={states}
        size="small"
        value={value}
        onChange={(event: any, newValue: StateType | null) => {
          setValue(newValue);
          handleEvent(newValue?.name);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          handleEvent(newInputValue);
        }}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            error={!!stateError}
          />
        )}
      />
      {stateError && <FormHelperText error>{stateError}</FormHelperText>}
    </div>
  );
}
