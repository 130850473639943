import React from 'react';
import { Typography, useTheme } from '@mui/material';
import './LegalText.css';

const LegalText = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        color: theme.palette.primary.main,
      }}
      className="legal-text"
    >
      The information being provided is for educational purposes only and is not binding in any way.
    </div>
  );
};

export default LegalText;
