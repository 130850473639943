import { errorMessageMap } from '../utils/errorMessages';
import InfoAlert from '@/components/info-alert/InfoAlert';
import { ComplianceNotification } from 'common/interfaces/complianceSummary';

const complianceNotifications = (notifications, setCloseInfoAlert) => {
  const getFormattedMessage = (errorCode, quantity) => {
    const messageTemplate = errorMessageMap[errorCode] || errorCode;

    const templateData = {
      plural: quantity > 1 ? 's' : '',
      verb: quantity > 1 ? 'are' : 'is',
      verbHasHave: quantity > 1 ? 'have' : 'has',
    };

    const message = messageTemplate
      .replace('{quantity}', quantity)
      .replace('{plural}', templateData.plural)
      .replace('{verb}', templateData.verb)
      .replace('{verbHasHave}', templateData.verbHasHave);

    return message;
  };

  const notificationMessage = notifications
    .map((notification) => getFormattedMessage(notification.errorCode, notification.total))
    .join(', ');

  return (
    <div>
      {notifications.length > 0 && <InfoAlert message={notificationMessage} onClose={() => setCloseInfoAlert(false)} />}
    </div>
  );
};

export default complianceNotifications;
