import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';

interface ImportalButtonGroupProps {
  children: React.ReactNode; // Generic children, allowing any JSX elements
  style?: React.CSSProperties;
}

const ImportalButtonGroup: React.FC<ImportalButtonGroupProps> = ({ children, style = {} }) => {
  const defaultStyles: React.CSSProperties = {
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    ...style,
  };

  return <ButtonGroup sx={defaultStyles}>{children}</ButtonGroup>;
};

export default ImportalButtonGroup;
