import Typography from '@mui/material/Typography';
import './WelcomeHeader.css';

interface WelcomeHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const WelcomeHeader = ({ children, className = '' }: WelcomeHeaderProps): JSX.Element => {
  return <div className={`welcome-header-text ${className}`}>{children}</div>;
};

export default WelcomeHeader;
