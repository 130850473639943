import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { EntryPrepDocForDisplay, EntryPrepDocWithDocument } from 'common/interfaces/documentParsing';
import { DocumentType } from 'common/interfaces/document';
import { useAPI } from '@/api/APIContext';
import EntryPrepDocumentsView from './EntryPrepDocumentsView';
import { ImportalStepper } from '@/shared-components/ImportalStepper/ImportalStepper';
import EntryPrepApplicationFiling from './EntryPrepApplicationFiling';
import { EntryInvoice } from 'common/interfaces/entry';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { useEventBus } from '@/custom-hooks/event-bus/EventBus';
import { DataImportChangedEvent, EventType } from 'common/eventbus/eventBus';

export interface IEntryPrepTabContext {
  entryPrepDocs: Array<EntryPrepDocForDisplay>;
  refreshEntryPrepDocs: () => void;
  loadingStatus: LoadingStatus;
  setLoadingStatus: (arg0: LoadingStatus) => void;
  setLoadingText: (text: string) => void;
  setSuccessText: (text: string) => void;
  setErrorText: (text: string) => void;
}

export const EntryPrepTabContext = createContext<IEntryPrepTabContext>({
  entryPrepDocs: [],
  refreshEntryPrepDocs: () => {},
  loadingStatus: LoadingStatus.NOT_LOADING,
  setLoadingStatus: () => {},
  setLoadingText: () => {},
  setSuccessText: () => {},
  setErrorText: () => {},
});

const { Provider } = EntryPrepTabContext;

interface EntryPrepTabProps {
  shipmentId: string;
}

export default function EntryPrepTab({ shipmentId }: EntryPrepTabProps) {
  const { businessId } = useContext(ShipmentPageContext);
  const api = useAPI();

  const [entryPrepDocs, setEntryPrepDocs] = useState<Array<EntryPrepDocForDisplay>>([]);

  const [entryPrepDocSelections, setEntryPrepDocSelections] = useState<Array<boolean>>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const [entryInvoice, setEntryInvoice] = useState<EntryInvoice | undefined>();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<Record<number, boolean>>({});

  const eventBus = useEventBus();
  const handleEntryPrepDocChanged = useCallback(
    async (event: DataImportChangedEvent) => {
      // Check if the shipment already exists in the current list
      const entryPrepDocWithUpdate = entryPrepDocs.find(
        (entryPrepDoc) => entryPrepDoc.dataImport?._id.toString() === event.data.dataImportId
      );

      if (!entryPrepDocWithUpdate) return; // the data import changed event doesn't apply to any of the entry prep docs that we are looking at

      try {
        // Fetch the updated shipment data from the API
        const { data: updatedEntryPrepDoc } = await api.getEntryPrepDocById(entryPrepDocWithUpdate._id.toString());

        // Update the specific shipment in the state
        setEntryPrepDocs((prevEntryPrepDocs) => {
          return prevEntryPrepDocs.map((entryPrepDoc) =>
            entryPrepDoc._id.toString() === entryPrepDocWithUpdate._id.toString() ? updatedEntryPrepDoc : entryPrepDoc
          );
        });

        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText(`Parse for ${updatedEntryPrepDoc.document.fileName} was Updated`);
      } catch (error) {
        console.error('Error fetching updated entryPrepDoc:', error);
      }
    },
    [entryPrepDocs, api]
  );

  useEffect(() => {
    // Subscribe to the event
    // return the result for automatic cleanup the subscription when the component unmounts
    return eventBus.on(EventType.DATA_IMPORT_CHANGED, handleEntryPrepDocChanged);
  }, [eventBus, handleEntryPrepDocChanged]);

  const refreshEntryPrepDocs = () => {
    setLoadingText('Loading entry prep docs...');
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getEntryPrepDocsForShipment(shipmentId)
      .then(({ data }) => {
        setSuccessText('Successfully loaded entry rep docs');
        setLoadingStatus(LoadingStatus.SUCCESS);
        setEntryPrepDocs(data);
        setEntryPrepDocSelections(data?.map((_) => false));
        setLoadingStatus(LoadingStatus.NOT_LOADING);
      })
      .catch((err) => {
        setLoadingStatus(LoadingStatus.ERROR);
        setErrorText('Error getting entry prep docs');
      });
  };

  useEffect(() => {
    refreshEntryPrepDocs();
  }, [shipmentId, api]);

  const onEntryPrepDocUpdated = async (updatedDocument: EntryPrepDocForDisplay): Promise<any> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.updateEntryPrepDoc(updatedDocument._id.toString(), updatedDocument);
      setLoadingStatus(LoadingStatus.SUCCESS);
      const index = entryPrepDocs.findIndex(
        (entryPrepDoc) => updatedDocument._id.toString() === entryPrepDoc._id.toString()
      );
      const updatedEntryPrepDocs = [...entryPrepDocs];
      updatedEntryPrepDocs[index] = data;
      setEntryPrepDocs(updatedEntryPrepDocs);
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('error updating entry prep doc');
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  const createEntryInvoiceFromSelectedEntryPrepDocs = useCallback(async () => {
    // Extract selected documents
    const selectedEntryPrepDocs: EntryPrepDocWithDocument[] = entryPrepDocs.filter(
      (_, index) => entryPrepDocSelections[index]
    );

    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.convertToEntryInvoice(selectedEntryPrepDocs, businessId);
      setLoadingStatus(LoadingStatus.SUCCESS);
      setEntryInvoice(data);
      handleComplete();
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('Error creating entry invoice from prep docs', err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  }, [api, entryPrepDocs, entryPrepDocSelections]);

  const validEntryPrepDocsAreSelected = (): boolean => {
    const selectedEntryPrepDocs: EntryPrepDocWithDocument[] = entryPrepDocs.filter(
      (_, index) => entryPrepDocSelections[index]
    );
    return selectedEntryPrepDocs.some((doc) =>
      [DocumentType.COMMERCIAL_INVOICE_AND_PACKING_LIST, DocumentType.COMMERCIAL_INVOICE].includes(doc.documentType)
    );
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const getElements = () => steps.map((step) => step.element);

  const steps = [
    {
      label: 'Prepare Documents',
      element: (
        <EntryPrepDocumentsView
          entryPrepDocs={entryPrepDocs}
          onEntryPrepDocUpdated={onEntryPrepDocUpdated}
          entryPrepDocsSelections={entryPrepDocSelections}
          setEntryPrepDocsSelections={setEntryPrepDocSelections}
          onNext={createEntryInvoiceFromSelectedEntryPrepDocs}
          onBack={handleBack}
        />
      ),
    },
    {
      label: 'File Entry Application',
      element: <EntryPrepApplicationFiling entryInvoice={entryInvoice} onNext={handleComplete} onBack={handleBack} />,
    },
  ];

  return (
    <Provider
      value={{
        entryPrepDocs,
        refreshEntryPrepDocs: refreshEntryPrepDocs,
        loadingStatus,
        setLoadingStatus,
        setLoadingText,
        setSuccessText,
        setErrorText,
      }}
    >
      <ImportalStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} completed={completed} />
      {getElements()[activeStep]}
    </Provider>
  );
}
