import React from 'react';
import ManageItems, { ManageItemsConfig } from '../ManageItems/ManageItems';
import { Container } from 'common/interfaces/shipment';
import { TextField } from '@mui/material';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

export default function ManageContainers({
  editable,
  containers,
  onContainersUpdated,
  parentName,
  useDeleteConfirmationModal,
}) {
  const getDefaultContainer = () => ({ containerNumber: '', sealNumber: '' });

  const manageItemsConfig: ManageItemsConfig = {
    columns: [
      {
        header: 'Container Number',
        viewComponent: (item: Container) => <>{item.containerNumber}</>,
        editComponent: (item: Container, setItem: (arg0: Container) => void) => (
          <TextField
            value={item.containerNumber}
            onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
      {
        header: 'Seal Number',
        viewComponent: (item: Container) => <>{item.sealNumber}</>,
        editComponent: (item: Container, setItem: (arg0: Container) => void) => (
          <TextField
            value={item.sealNumber}
            onChange={(e) => setItem({ ...item, sealNumber: e.target.value })}
            fullWidth
            size="small"
          />
        ),
      },
    ],
  };

  return (
    <ManageItems<Container>
      manageItemsConfig={manageItemsConfig}
      items={containers}
      getDefaultItem={getDefaultContainer}
      onItemsUpdated={(items) => {
        onContainersUpdated([...items]);
      }}
      itemName={'containers'}
      editable={editable}
      parentName={parentName}
      useDeleteConfirmationModal={useDeleteConfirmationModal}
      parentLoadingStatus={LoadingStatus.NOT_LOADING}
    />
  );
}
