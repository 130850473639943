import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';

const ProtectedRoute = (props: { children: any }) => {
  const navigate = useNavigate();
  const activeUser = useActiveUser();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkActiveUser = useCallback(() => {
    if (!activeUser.user) {
      setIsLoggedIn(false);
      return navigate('/login');
    }
    setIsLoggedIn(true);
  }, [navigate, activeUser.user]);

  useEffect(() => {
    checkActiveUser();
  }, [activeUser.user, checkActiveUser]);

  return <>{isLoggedIn ? props.children : null}</>;
};
export default ProtectedRoute;
