import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link, Paper } from '@mui/material';
import './BusinessesTable.css';
import { Business, BusinessWithDocuments } from 'common/interfaces/business';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';
import { getStepDescription } from 'common/interfaces/customsOnboarding';
import { useLocation, useNavigate } from 'react-router';
import { Add } from '@mui/icons-material';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import {
  ColumnConfig,
  ColumnSortConfig,
  TableConfig,
  TableFilterConfig,
  TableSortConfig,
} from 'common/interfaces/importalTable';

interface BusinessesTableRowProps {
  row: any;
  onClickEditBusiness: (business: BusinessWithDocuments) => void;
  onClickEditCustomsOnboarding: () => void;
  columnConfig: Array<ColumnConfig>;
}

const Row = ({ row, onClickEditBusiness, onClickEditCustomsOnboarding, columnConfig }: BusinessesTableRowProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const headerCellStyle = {
    whiteSpace: 'nowrap',
    '&:hover': { fontWeight: '600!important' },
  };

  const handleBusinessNameClick = (id: string) => {
    let url = '';
    url = `${location.pathname}/${id}`;
    navigate(url);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell />
        <TableCell>
          <Link
            sx={{ textDecoration: 'none' }}
            component="button"
            onClick={() => {
              handleBusinessNameClick(row._id);
            }}
          >
            <Typography sx={headerCellStyle}> {row.name} </Typography>
          </Link>
        </TableCell>
        <TableCell sx={headerCellStyle}>
          <Typography>{row.businessDomains ? row.businessDomains.toString() : 0}</Typography>
        </TableCell>
        <TableCell sx={headerCellStyle}>
          <Typography>{getStepDescription(row.customsOnboardingStep)}</Typography>
        </TableCell>
        <TableCell />
      </TableRow>
    </React.Fragment>
  );
};

interface BusinessesTableProps {
  rows: BusinessWithDocuments[];
  isLoading: boolean;
  onClickEditBusiness: (business: BusinessWithDocuments) => void;
  onClickEditCustomsOnboarding: () => void;
  onClickCreateBusiness: () => void;
}

const BusinessesTable = ({
  rows,
  isLoading,
  onClickEditBusiness,
  onClickEditCustomsOnboarding,
  onClickCreateBusiness,
}: BusinessesTableProps) => {
  const [businessesTableConfig, setBusinessesTableConfig] = useState<TableConfig>({
    columns: [
      {
        displayName: 'Business Name',
        name: 'name',
        dataType: 'string',
        sortable: true,
        filterable: true,
        isVisible: true,
      },
      {
        displayName: 'Business Domains',
        name: 'businessDomains',
        dataType: 'string',
        sortable: false,
        filterable: true,
        isVisible: true,
      },
      {
        displayName: 'Customs Onboarded Status',
        name: 'customsOnboardingStep',
        dataType: 'string',
        sortable: false,
        filterable: true,
        categorizer: getStepDescription,
        isVisible: true,
      },
    ],
    sortConfig: [],
    filterConfig: {},
  });

  return (
    <div className="broker-businesses-table-container">
      <ImportalTable
        data={rows}
        loading={isLoading}
        rowGenerator={(row: any, index: number) => (
          <Row
            key={`row-${index}`}
            onClickEditBusiness={onClickEditBusiness}
            onClickEditCustomsOnboarding={onClickEditCustomsOnboarding}
            row={row}
            columnConfig={businessesTableConfig.columns}
          />
        )}
        topRightElement={<></>}
        tableConfig={businessesTableConfig}
        setTableConfig={setBusinessesTableConfig}
        topRightButton={
          <ImportalPrimaryButton
            style={{ width: '170px', borderRadius: '8px', height: '40px', fontSize: '12px' }}
            onClick={onClickCreateBusiness}
            text="Create Business"
            endIcon={<Add />}
          />
        }
        useSettings={false}
      />
    </div>
  );
};
export default BusinessesTable;
