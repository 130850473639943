import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ImportalCard from '@/shared-components/ImportalCard';
import ReportDraft from '@/pages/reports/ReportDraft';
import { Report, ReportingManifest, ReportState } from 'common/interfaces/reports';
import { useAPI } from '@/api/APIContext';
import { Card, CardContent, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import ReportAdvanced from '@/pages/reports/ReportAdvanced';
import { ImportalBreadCrumbs } from '@/shared-components/ImportalBreadCrumbs';

export default function CreateNewReport() {
  const api = useAPI();
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);
  const [reportRequestError, setReportRequestError] = useState<string | undefined>();
  const [reportRequestText, setReportRequestText] = useState<string | undefined>();
  const [reportDraft, setReportDraft] = useState<Report | undefined>();

  const [reportingManifest, setReportingManifest] = useState<ReportingManifest | undefined>();

  const longPollForReport = async () => {
    try {
      await new Promise((resolve, reject) => {
        setTimeout(resolve, 4000);
      });
      const { data: report } = await api.getReport(reportDraft!._id.toString());
      setReportDraft(report);
    } catch (err) {
      console.error('error getting report');
      console.error(err);
    }
  };

  useEffect(() => {
    if (reportDraft && [ReportState.PROCESSING, ReportState.SUBMITTED].includes(reportDraft!.state)) {
      longPollForReport();
    }
  }, [reportDraft]);

  useEffect(() => {
    api
      .getReportingManifestForCurrentUser()
      .then(({ data }) => {
        setReportingManifest(data);
      })
      .catch((err) => {
        console.error('error getting reporting manifest for current user');
        console.error(err);
      });
  }, []);

  const onCreateNewReport = () => {
    setLoading(true);
    setReportRequestError(undefined);
    api
      .requestReport(reportRequestText!)
      .then(({ data: report }) => {
        setReportDraft(report);
      })
      .catch((err) => {
        console.error('error requesting report');
        console.error(err);
        if ((err as AxiosError).response?.data) {
          //@ts-ignore
          const { error } = err.response.data;
          setReportRequestError(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ImportalCard title={'Create New Report'} topRightActionButton={<></>}>
        {reportRequestError && (
          <ul>
            <li style={{ color: 'red' }}>{reportRequestError}</li>
          </ul>
        )}

        <TextField
          fullWidth
          placeholder="I want to see a report of"
          multiline={true}
          rows={4}
          value={reportRequestText || ''}
          onChange={(event) => {
            setReportRequestText(event.target.value);
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'right', marginTop: theme.spacing(1) }}>
          <Button variant="contained" color="primary" disabled={!reportRequestText} onClick={onCreateNewReport}>
            {loading ? <CircularProgress color={'inherit'} size={18} /> : 'Try it'}
          </Button>
        </div>

        <h2>Data Sources</h2>
        <List>
          {reportingManifest?.collections.map((collection) => (
            <ListItem key={collection.name}>
              <ListItemText primary={collection.shortDescription} secondary={collection.description} />
            </ListItem>
          ))}
        </List>
      </ImportalCard>

      <br />
      {reportDraft && (
        <Card>
          <CardContent>
            <ImportalBreadCrumbs
              elements={[
                {
                  render: ({ popItem, pushItem, item }) => (
                    <ReportDraft
                      report={reportDraft}
                      onAdvancedClicked={() => {
                        pushItem(reportDraft);
                      }}
                    />
                  ),

                  label: 'Report Draft',
                },
                {
                  render: ({ popItem, pushItem, item }) => <ReportAdvanced report={reportDraft} />,
                  label: 'Advanced',
                },
              ]}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
}
