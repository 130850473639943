import BusinessAdminTabUsersCard from '@/broker-app/pages/businesses/BusinessAdminTabUsersCard';
import BusinessAdminTabLinksCard from '@/broker-app/pages/businesses/BusinessAdminTabLinksCard';

export default function BusinessAdminTab() {
  return (
    <>
      <BusinessAdminTabUsersCard />
      <BusinessAdminTabLinksCard />
    </>
  );
}
