import React from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';

interface Props {
  freightForwarderEmail?: string;
  onFreightForwarderEmailSet: (freightForwarderEmail?: string) => void;
  onNext: () => void;
}

export default function FreightForwarderContact({ freightForwarderEmail, onFreightForwarderEmailSet, onNext }: Props) {
  const theme = useTheme();

  const handleEmail = ({ target }) => {
    onFreightForwarderEmailSet(target.value);
  };

  return (
    <Box>
      <div className="header-question">
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '36px' }}>Freight Forwarder Contact</Typography>
      </div>
      <div className="unlading-descriptive-text">Please provide an email address for your freight forwarder</div>
      <div className="cargo-value-text-field">
        <TextField
          id="email"
          type="email"
          variant="outlined"
          placeholder="Email"
          InputLabelProps={{
            shrink: true,
          }}
          value={freightForwarderEmail}
          onChange={handleEmail}
        />
      </div>
      <div className="next-button-container">
        <Button
          onClick={onNext}
          fullWidth
          className="next-button-green"
          variant="contained"
          disabled={!freightForwarderEmail}
        >
          Next
        </Button>
      </div>
    </Box>
  );
}
