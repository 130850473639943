import React, { useEffect, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import ShipmentTable from '@/components/home-component/ShipmentTable';
import './Shipments.css';
import { useAPI } from '../../api/APIContext';

export default function Shipments() {
  const [shipments, setShipments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPI();
  const theme = useTheme();

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const getNumericSuffix = (refNumber) => {
    if (refNumber == null || refNumber === '') {
      return NaN; // Handle null or empty string case
    }
    const numericSuffix = parseInt(refNumber.split('-')[1]);
    return isNaN(numericSuffix) ? NaN : numericSuffix; // Handle invalid numeric suffix case
  };

  const referenceNumberSort = (a, b) => {
    const suffixA = getNumericSuffix(a.refNumber);
    const suffixB = getNumericSuffix(b.refNumber);

    // Handle NaN cases
    if (isNaN(suffixA) && isNaN(suffixB)) {
      return 0;
    } else if (isNaN(suffixA)) {
      return 1;
    } else if (isNaN(suffixB)) {
      return -1;
    }

    // Sort UNLD prefix reference numbers by suffix numerically
    if (a.refNumber.startsWith('UNLD') && b.refNumber.startsWith('UNLD')) {
      return suffixA - suffixB;
    }

    // Sort PORT prefix reference numbers by suffix numerically
    if (a.refNumber.startsWith('PORT') && b.refNumber.startsWith('PORT')) {
      return suffixA - suffixB;
    }

    // UNLD prefix entries come first
    return a.refNumber.startsWith('UNLD') ? -1 : 1;
  };

  useEffect(() => {
    handleLoading(true);
    api.getShipmentsForUser().then((response) => {
      setShipments(response.data.userShipments?.sort(referenceNumberSort));
      handleLoading(false);
    });
  }, []);

  return (
    <>
      <div className="main-page-header">
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '26px' }}>Shipments</Typography>
      </div>
      <div className="universal-subheader">Keep track of your product's path every step of the way.</div>
      <div className="shipments-table-container">
        <ShipmentTable rows={shipments} isLoading={isLoading}></ShipmentTable>
      </div>
    </>
  );
}
