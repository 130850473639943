import { Alert, CircularProgress, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface LoadingState {
  loadingStatus: LoadingStatus;
  error?: any;
}

export enum LoadingStatus {
  NOT_LOADING = 'NOT_LOADING',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface Props {
  loadingState: LoadingState;
  loadingText: string;
  successText: string;
  errorText?: string;
}

const DataImportLoader = ({ loadingState, loadingText, successText, errorText }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    setIsOpen(true);
  }, [loadingState.loadingStatus]);

  const renderErrorMessage = () => {
    switch (typeof loadingState.error) {
      case 'string':
        return loadingState.error || errorText || 'Error';
      case 'object':
        return loadingState.error.message || errorText || 'Error';
      default:
        return errorText;
    }
  };

  return (
    <>
      <Snackbar
        open={isOpen && loadingState.loadingStatus === LoadingStatus.LOADING}
        autoHideDuration={100000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          icon={<CircularProgress color="inherit" size={20} />}
          variant="filled"
          sx={{ width: '100%', backgroundColor: '#c0dcc2!important', color: '#525256!important' }}
        >
          {loadingText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isOpen && loadingState.loadingStatus === LoadingStatus.SUCCESS}
        autoHideDuration={3000}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {successText}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isOpen && loadingState.loadingStatus === LoadingStatus.ERROR}
        autoHideDuration={3000}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
          {renderErrorMessage()}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DataImportLoader;
