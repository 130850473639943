import React, { useState, useEffect } from 'react';
import { Paper, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TimePeriod } from 'common/interfaces/reports';

interface Props {
  timePeriod: TimePeriod;
  onTimePeriodSelected: (timePeriod: TimePeriod) => void;
  onCompleteSelection: () => void; // New prop for parent trigger
}

export default function ImportalDateRangePicker({ timePeriod, onTimePeriodSelected, onCompleteSelection }: Props) {
  const theme = useTheme();
  const [localStartDate, setLocalStartDate] = useState<Date | null>(timePeriod.periodStartDate);
  const [localEndDate, setLocalEndDate] = useState<Date | null>(timePeriod.periodEndDate);
  const [timePeriodSelectionError, setTimePeriodSelectionError] = useState<string | null>();

  useEffect(() => {
    // Update local state if timePeriod prop changes
    setLocalStartDate(timePeriod.periodStartDate);
    setLocalEndDate(timePeriod.periodEndDate);
  }, [timePeriod]);

  const validateDateSelections = (startDate: Date | null, endDate: Date | null): boolean => {
    if (!startDate || !endDate) {
      setTimePeriodSelectionError('Please select both start and end dates');
      return false;
    } else if (startDate > endDate) {
      setTimePeriodSelectionError('Start date cannot be after end date');
      return false;
    } else {
      setTimePeriodSelectionError(null);
      return true;
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    setLocalStartDate(date);
    if (validateDateSelections(date, localEndDate)) {
      onTimePeriodSelected({ ...timePeriod, periodStartDate: date, periodEndDate: localEndDate });
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    setLocalEndDate(date);
    if (validateDateSelections(localStartDate, date)) {
      onTimePeriodSelected({ ...timePeriod, periodStartDate: localStartDate, periodEndDate: date });
    }
  };

  const handleEndDateKeyDown = (event: React.KeyboardEvent) => {
    if ((event.key === 'Tab' || event.key === 'Enter') && localStartDate && localEndDate) {
      onCompleteSelection(); // Trigger parent action on Tab or Enter
    }
  };

  const paperStyles = {
    borderRadius: '8px!important',
    height: '40px',
    minWidth: '150px',
    paddingLeft: '10px',
    paddingRight: '16px',
    boxShadow: theme.shadows[3],
    backgroundColor: 'white',
  };

  return (
    <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
      <Paper sx={paperStyles}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                InputProps: {
                  sx: {
                    fontSize: '12px',
                    '& .MuiInputBase-input': {
                      fontSize: '12px',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                  },
                },
                InputLabelProps: { sx: { fontSize: '12px' } },
              },
            }}
            label="Start Date"
            value={localStartDate}
            onChange={handleStartDateChange}
          />
        </LocalizationProvider>
      </Paper>
      <Paper sx={paperStyles}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            slotProps={{
              textField: {
                size: 'small',
                onKeyDown: handleEndDateKeyDown,
                InputProps: {
                  sx: {
                    fontSize: '12px',
                    '& .MuiInputBase-input': {
                      fontSize: '12px',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: '18px',
                    },
                  },
                },
                InputLabelProps: { sx: { fontSize: '12px' } },
              },
            }}
            label="End Date"
            value={localEndDate}
            onChange={handleEndDateChange}
          />
        </LocalizationProvider>
      </Paper>
    </div>
  );
}
