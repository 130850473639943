import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Switch, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Save } from '@mui/icons-material';

import './EditProductFieldsModal.css';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { UserSettingName } from 'common/interfaces/user';

interface Props {
  open: boolean;
  onClose: () => void;
  onTableSettingsEdited: () => void;
}

export default function EditTableSettingsModal({ open, onClose, onTableSettingsEdited }: Props) {
  const theme = useTheme();
  const activeUser = useActiveUser();

  const toggleTableDensity = (event: React.ChangeEvent<HTMLInputElement>) => {
    activeUser.setUserSetting(UserSettingName.PRODUCT_LIBRARY_DENSE_MODE, event.target.checked);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={(event, reason) => {
          onClose();
        }}
      >
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <DialogContent>
          <div className="my-shipments-header">Table Settings</div>
          <div style={{ padding: theme.spacing(2), display: 'grid' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" component="div">
                Dense Mode:
              </Typography>
              <Switch
                checked={activeUser.getUserSetting(UserSettingName.PRODUCT_LIBRARY_DENSE_MODE)}
                onChange={toggleTableDensity}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
