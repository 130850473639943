import {
  CMQLine,
  WN1CMQLine,
  WR1CMQLine,
  WR4CMQLine,
  WR5CMQLine,
  WSCCMQLine,
  WSDCMQLine,
} from 'common/interfaces/aceDataSource';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import moment from 'moment-timezone';
import './CMQRendering.css';

interface CargoManifestQueryResultsProps {
  parsedCargoManifest: CMQLine[];
  rawCargoManifest: string;
  billType: string;
}

function MultilineText({ text }) {
  const lines = text.split('\n');
  return (
    <div>
      {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  );
}

export function CargoManifestResults({
  parsedCargoManifest,
  rawCargoManifest,
  billType,
}: CargoManifestQueryResultsProps) {
  const [alignment, setAlignment] = React.useState('parsed');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  const resultsToReturn: any = [
    <div className="cmq-button-group">
      <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
        <ToggleButton sx={{ textTransform: 'none', color: '#525256', width: '80px' }} value="raw">
          Raw
        </ToggleButton>
        <ToggleButton sx={{ textTransform: 'none', color: '#525256', width: '80px' }} value="parsed">
          Parsed
        </ToggleButton>
      </ToggleButtonGroup>
    </div>,
  ];
  if (alignment === 'raw') {
    resultsToReturn.push(
      <div className="raw-cmq-container">
        <MultilineText text={rawCargoManifest} />
      </div>
    );
  } else if (alignment === 'parsed') {
    if (billType === 'OceanTruckRail') {
      resultsToReturn.push(renderOceanTruckRailBill(parsedCargoManifest));
    } else if (billType === 'Airway') {
      resultsToReturn.push(renderAirwayBill(parsedCargoManifest));
    }
  }
  return resultsToReturn;
}

function parseDateAndTime(dateStr: string, timeStr: string, timezone: string = 'America/New_York'): any {
  // Format: MMDDYYHHmm (24-hour format for hours)
  const format = 'MMDDYYHHmm';
  const dateTimeStr = dateStr + timeStr;
  // Parse the datetime in the given timezone, using 24-hour format
  const dateTime = moment.tz(dateTimeStr, format, timezone);
  const easternTimeFormatted = dateTime.format('MM/DD/YY h:mm A');
  return easternTimeFormatted;
}

function parseDateAndTimev2(dateStr: string, timeStr: string = '', timezone: string = 'America/New_York'): any {
  // Determine format based on whether a time string is provided
  let format = timeStr ? 'MMDDYYHHmm' : 'MMDDYY';
  const dateTimeStr = dateStr + timeStr;

  // Parse the datetime in the given timezone
  const dateTime = moment.tz(dateTimeStr, format, timezone);
  const formattedOutput = dateTime.format('MM/DD/YY' + (timeStr ? ' h:mm A' : ''));

  return formattedOutput;
}

const renderOceanTruckRailBill = (parsedCargoManifest: CMQLine[]) => {
  return (
    <div className="parsed-cmq-container">
      {parsedCargoManifest.map((line, index) => (
        <>
          {line.lineType === 'WR1' && renderWR1CMQLine(line as WR1CMQLine)}
          {line.lineType === 'WN1' && renderWN1CMQLine(line as WN1CMQLine)}
          {line.lineType === 'WR4' && renderWR4CMQLine(line as WR4CMQLine)}
        </>
      ))}
    </div>
  );
};

const renderBillOfLadingType = (line: WR4CMQLine) => {
  switch (getBillOfLadingType(line)) {
    case 'MASTER':
      return 'Master Bill';
    case 'HOUSE':
      return 'House Bill';
    default:
      return '';
  }
};

export enum BILL_OF_LADING_TYPE {
  HOUSE = 'HOUSE',
  MASTER = 'MASTER',
}

const getBillOfLadingType = (line: WR4CMQLine): BILL_OF_LADING_TYPE => {
  if (line.billOfLadingType === 'M') {
    return BILL_OF_LADING_TYPE.MASTER;
  } else {
    return BILL_OF_LADING_TYPE.HOUSE;
  }
};

const renderBillOfLadingNumber = (line: WR4CMQLine) => {
  if (getBillOfLadingType(line) === BILL_OF_LADING_TYPE.MASTER) {
    return `${line.issuerCodeOfMasterBillNumber}${line.masterBillNumber}`;
  } else {
    return `${line.issuerCodeOfHouseBillNumber}${line.houseBillNumber}`;
  }
};

// render the BOL Quantity -- check for a WN0 on the WR4 which would have master/house amended quantity
const renderManifestQuantity = (line: WR4CMQLine) => {
  let manifestQuantity =
    'Quantity: ' + `${(line as WR4CMQLine).manifestQuantity} ${(line as WR4CMQLine).unit}`.replace(/^0+/, '');

  if (Array.isArray(line.wn0Lines) && line.wn0Lines.length > 0 && getBillOfLadingType(line) === 'MASTER') {
    manifestQuantity =
      manifestQuantity +
      ', Amended Master Quantity: ' +
      `${(line as WR4CMQLine).wn0Lines[0].masterBillAmendedQuantity} ${(line as WR4CMQLine).unit}`.replace(/^0+/, '');
  } else if (Array.isArray(line.wn0Lines) && line.wn0Lines.length > 0 && getBillOfLadingType(line) === 'HOUSE') {
    manifestQuantity =
      manifestQuantity +
      ', Amended House Quantity: ' +
      `${(line as WR4CMQLine).wn0Lines[0].houseBillAmendedQuantity} ${(line as WR4CMQLine).unit}`.replace(/^0+/, '');
  }

  return manifestQuantity;
};

const renderModeOfTransportation = (wr4CMQLine: WR4CMQLine) => {
  return {
    '1': 'Ocean',
    '2': 'Rail',
    '3': 'Truck',
  }[wr4CMQLine.modeOfTransportationCode];
};

const renderAirwayBill = (parsedCargoManifest: CMQLine[]) => {
  return (
    <div className="parsed-cmq-container">
      {parsedCargoManifest.map((line, index) => (
        <>
          {line.lineType === 'WR1' && renderWR1CMQLineAirway(line as WR1CMQLine)}
          {line.lineType === 'WSD' && renderWSDCMQLineAirway(line as WSDCMQLine)}
          {line.lineType === 'WN1' && renderWN1CMQLine(line as WN1CMQLine)}
          {line.lineType === 'WSC' && renderWSCCMQLineAirway(line as WSCCMQLine)}
        </>
      ))}
    </div>
  );
};

const renderWR1CMQLine = (wr1CMQLine: WR1CMQLine) => {
  return (
    <>
      <div className="cmq-line-header wr1-line">
        <div>
          <div>
            <div className="cmq-line-header-prefix">Date of Arrival</div>
            <div className="wr1-header-value">{parseDateAndTimev2(wr1CMQLine.dateOfArrival)}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Port of Entry</div>
            <div className="wr1-header-value">{wr1CMQLine.districtPortOfEntry}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Carrier Code</div>
            <div className="wr1-header-value">{wr1CMQLine.carrierCode}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Voyage / Flight Number</div>
            <div className="wr1-header-value">{wr1CMQLine.voyageFlightTripManifestNumber}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const renderWR4CMQLine = (wr4CMQLine: WR4CMQLine) => {
  return (
    <>
      <div className="cargo-manifest-results-container">
        <div className="cmq-text-header">{renderBillOfLadingType(wr4CMQLine)}</div>
        <div className="cmq-text-subheader">
          <div>{renderBillOfLadingNumber(wr4CMQLine)}</div>
          <div>{renderManifestQuantity(wr4CMQLine)}</div>
        </div>

        <div className="cmq-text-subheader">
          <div>Mode of Transportation: {renderModeOfTransportation(wr4CMQLine)}</div>
        </div>
        {wr4CMQLine.inBondNumber && (
          <div className="cmq-text-subheader">
            <div>In-Bond number: {wr4CMQLine.inBondNumber}</div>
          </div>
        )}
        {wr4CMQLine.wr5Lines && wr4CMQLine.wr5Lines.length > 0 && (
          <table className={'wr5-line-table'}>
            <thead>
              <tr className={'wr5-line-table-header-row'}>
                <th>Date / Time</th>
                <th>Code</th>
                <th>Description</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>{wr4CMQLine.wr5Lines.map(renderWR5CMQLine)}</tbody>
          </table>
        )}
      </div>
    </>
  );
};

const renderWN1CMQLine = (wn1CMQLine: WN1CMQLine) => {
  return (
    <div className="cargo-manifest-results-container">
      <div className="cmq-text-header">In-Bond Entry Number: {wn1CMQLine.inBondEntryNumber}</div>

      <div className="cmq-line-header">
        <div>
          <div>
            <div className="cmq-line-header-prefix">Manifested Port of Unlading/Import</div>
            <div className="wr1-header-value">{wn1CMQLine.manifestedPortOfUnladingImport || '--'}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Actual Port of Unlading/Import</div>
            <div className="wr1-header-value">{wn1CMQLine.actualPortOfUnladingImport || '--'}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Departure Port</div>
            <div className="wr1-header-value">{wn1CMQLine.vesselDeparturePort || '--'}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Departure Date</div>
            <div className="wr1-header-value">{parseDateAndTimev2(wn1CMQLine.vesselDepartureDate) || '--'}</div>
          </div>
        </div>
      </div>
      <div className="cmq-line-header">
        <div>
          <div>
            <div className="cmq-line-header-prefix">In Bond Originating Port</div>
            <div className="wr1-header-value">{wn1CMQLine.inBondOriginatingPort || '--'}</div>
          </div>
          <div>
            <div className="cmq-line-header-prefix">Manifested In-Bond Destination Port</div>
            <div className="wr1-header-value">{wn1CMQLine.manifestedInBondDestinationPort || '--'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderWR1CMQLineAirway = (wr1CMQLine: WR1CMQLine) => {
  return (
    <>
      <div className="cmq-line-header">
        <div>
          <div className="cmq-line-header-prefix">Date of Arrival:</div>
          <div className="wr1-header-value">{parseDateAndTimev2(wr1CMQLine.dateOfArrival)}</div>
        </div>
        <div>
          <div className="cmq-line-header-prefix">Port of Entry:</div>
          <div className="wr1-header-value">{wr1CMQLine.districtPortOfEntry}</div>
        </div>
        <div>
          <div className="cmq-line-header-prefix">Carrier Code:</div>
          <div className="wr1-header-value">{wr1CMQLine.carrierCode}</div>
        </div>
        <div>
          <div className="cmq-line-header-prefix">Voyage / Flight Number:</div>
          <div className="wr1-header-value">{wr1CMQLine.voyageFlightTripManifestNumber}</div>
        </div>
      </div>
    </>
  );
};

const renderWSDCMQLineAirway = (wsdCMQLineAirway: WSDCMQLine) => {
  return (
    <>
      <div className="shipment-page-breakdown-container-header" key="headre-duty-title-pga-one">
        <div style={{ minWidth: '150px' }}>Date / Time</div>
        <div style={{ minWidth: '60px' }}>Code</div>
        <div>Description</div>
      </div>
      <div className="shipment-breakdown-container-row">
        <div style={{ minWidth: '150px' }}>
          {parseDateAndTime(wsdCMQLineAirway.dispositionActionDate, wsdCMQLineAirway.dispositionActionTime)}
        </div>
        <div style={{ minWidth: '60px' }}>{wsdCMQLineAirway.dispositionCode}</div>
        <div>{wsdCMQLineAirway.narrativeMessage}</div>
      </div>
    </>
  );
};

const renderWSCCMQLineAirway = (wscCMQLine: WSCCMQLine) => {
  return (
    <div className="shipment-breakdown-container-row">
      {!wscCMQLine.houseAirWaybillNumber && (
        <>
          <span>Airway Bill Number: {wscCMQLine.airWaybillNumber}</span>
          <span>Manifest Quantity: {wscCMQLine.manifestQuantity1 + wscCMQLine.manifestQuantity2}</span>
        </>
      )}
      {wscCMQLine.houseAirWaybillNumber && (
        <>
          <span>House Airway Bill Number: {wscCMQLine.houseAirWaybillNumber}</span>
          <span>Manifest Quantity: {wscCMQLine.manifestQuantity1 + wscCMQLine.manifestQuantity2}</span>
        </>
      )}
      {(wscCMQLine.boardedQuantity1 || wscCMQLine.boardedQuantity2) && (
        <span>Boarded Quantity: {wscCMQLine.boardedQuantity1 + wscCMQLine.boardedQuantity2}</span>
      )}
    </div>
  );
};

const renderWR5CMQLine = (wr5CMQLine: WR5CMQLine, index: number) => {
  return (
    <tr className={'wr5-line-table-row'}>
      <td>{parseDateAndTime(wr5CMQLine.dispositionActionDate, wr5CMQLine.dispositionActionTime)}</td>
      <td>{wr5CMQLine.dispositionActionCode}</td>
      <td>{wr5CMQLine.narrativeMessage}</td>
      <td>{wr5CMQLine.quantity || '--'}</td>
    </tr>
  );
};
