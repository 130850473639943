import React, { CSSProperties } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from '@mui/material';

interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  onClose,
  onConfirm,
  message,
  confirmButtonText,
  cancelButtonText,
}) => {
  const theme = useTheme();

  const getCardStyling = (): CSSProperties => ({
    alignItems: 'center',
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card style={getCardStyling()}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {message}
          </Typography>
        </CardContent>

        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="secondary" onClick={onClose} style={{ margin: '10px' }}>
            {cancelButtonText}
          </Button>
          <Button variant="contained" color="warning" onClick={onConfirm} style={{ margin: '10px' }}>
            {confirmButtonText}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ConfirmModal;
