import ReportChart from '@/pages/reports/ReportChart';
import ReportTable from '@/pages/reports/ReportTable';
import { Report, ReportChartType } from 'common/interfaces/reports';
import React, { useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { mmmddyyyyDate } from '@/utils/activePlanUtils';
import { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';

export interface Props {
  report: Report;
  onAdvancedClicked: (report: Report) => void;
}

export default function ReportDefault({ report, onAdvancedClicked }: Props) {
  const api = useAPI();
  const betaFeatures = useBetaFeatures();

  const [reportData, setReportData] = useState<any[]>([]);

  const importalTableRef = React.createRef<ImportalTableHandles>();

  const getReportData = () => {
    api
      .getReportData(report._id.toString())
      .then(({ data: reportData }) => {
        setReportData(reportData);
      })
      .catch((err) => {
        console.error('error getting report data');
        console.error(err);
      });
  };
  useEffect(() => {
    getReportData();
  }, [report]);

  // Menu Component boiler plate
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickReRunReport = () => {
    api
      .runReport(report._id.toString())
      .then(() => {})
      .catch((err) => {
        console.error('error running report');
        console.error(err);
      });
  };

  const onClickDownloadReportData = async () => {
    if (!importalTableRef.current) return;

    const tableConfig = importalTableRef.current.getTableConfig();

    try {
      // add timestamp to file name
      const response = await api.downloadReportData(report._id.toString(), tableConfig);
      const data = response.data;
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${report.name || 'report'}.csv`; // Provide the filename extension
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const onClickDeleteReport = () => {
    api
      .deleteReport(report._id.toString())
      .then(({ data }) => {
        // TODO pop report, refresh report list
      })
      .catch((err) => {
        console.error('error deleting report');
        console.error(err);
      });
  };

  return (
    <>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {report.name}
          <br />
          Run on: <i>{mmmddyyyyDate(report.lastRun?.toString())}</i>
        </div>
        <div>
          <div id={'action-menu'} className="right-button-container">
            <Button
              endIcon={<ArrowDropDownIcon />}
              size={'small'}
              className="right-button-green"
              onClick={handleMenuClick}
            >
              Actions
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem
                onClick={() => {
                  onClickReRunReport();
                  handleMenuClose();
                }}
              >
                Re-Run Report
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickDownloadReportData();
                  handleMenuClose();
                }}
              >
                Download Report Data
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickDeleteReport();
                  handleMenuClose();
                }}
              >
                Delete Report
              </MenuItem>

              {betaFeatures && (
                <MenuItem
                  onClick={() => {
                    onAdvancedClicked(report);
                    handleMenuClose();
                  }}
                >
                  Advanced View
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <br />

      {report.reportConfig?.chartConfig && (
        <>
          {report.reportConfig?.chartConfig.chartType !== ReportChartType.NO_CHART && (
            <>
              <ReportChart chartConfig={report.reportConfig!.chartConfig} data={reportData}></ReportChart>
              <br />
            </>
          )}

          <ReportTable
            ref={importalTableRef}
            data={reportData}
            columnConfig={report.reportConfig!.columns}
          ></ReportTable>
        </>
      )}
    </>
  );
}
