import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import * as ls from 'local-storage';
import { DEFAULT_USER_SETTINGS, User, UserSettingName, UserSettings } from 'common/interfaces/user';
import md5 from 'crypto-js/md5';
import { datadogRum } from '@datadog/browser-rum';

interface ActiveUserContextProps {
  children: ReactNode;
}

export type IActiveUserContext = {
  user: User | undefined;
  getUserSetting: (settingName: UserSettingName) => any | undefined;
  setUserSetting: (settingName: UserSettingName, value: any) => void;
  refreshUser: () => Promise<void>;
};
const ActiveUserContext = createContext<IActiveUserContext>({
  user: undefined,
  getUserSetting: (settingName) => undefined,
  setUserSetting: (settingName, value) => {},
  refreshUser: () => {
    return new Promise(() => {});
  },
});

export const ActiveUserProvider: React.FC<ActiveUserContextProps> = ({ children }) => {
  const api = useAPI();
  const storedUser = ls.get('user') as User | undefined;
  const [activeUser, setActiveUser] = useState<User | undefined>(storedUser);
  const [userSettings, setUserSettings] = useState(DEFAULT_USER_SETTINGS);

  const getUser = useCallback(async () => {
    try {
      const { data: user } = await api.getActiveUser();

      setActiveUser(user);
      ls.set('user', user);

      if (!user) return;
      const emailHash = md5(user.email);
      datadogRum.setUser({ id: emailHash, email: user.email });
    } catch (err) {
      console.error('error getting user', err);
    }
  }, [api]);

  const getUserSettings = useCallback(async () => {
    try {
      const { data: userSettings } = await api.getUserSettings();
      setUserSettings(userSettings);
    } catch (err) {
      console.error('error getting user settings', err);
    }
  }, [api]);

  useEffect(() => {
    getUser();
    getUserSettings();
  }, [getUser]);

  const getUserSetting = (settingName: UserSettingName): any | undefined => {
    return userSettings[settingName];
  };

  const setUserSetting = (settingName: UserSettingName, value: any): void => {
    const updatedUserSettings = { ...userSettings, [settingName]: value };
    api
      .setUserSettings(updatedUserSettings)
      .then(({ data }) => {
        setUserSettings(data);
      })
      .catch((err) => {
        console.error('error updating user settings');
        console.error(err);
      });
  };

  return (
    <ActiveUserContext.Provider
      value={{
        user: activeUser,
        getUserSetting,
        setUserSetting,
        refreshUser: getUser,
      }}
    >
      {children}
    </ActiveUserContext.Provider>
  );
};

export const useActiveUser = (): IActiveUserContext => {
  return useContext(ActiveUserContext);
};
