import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Select, styled, TextField } from '@mui/material';
import { Document, DocumentType, DocumentTypeToText } from 'common/interfaces/document';
import { useAPI } from '@/api/APIContext';
import { Mode } from 'common/interfaces/business';
import Dropzone from 'react-dropzone';
import './ManageDocuments.css';
import ManageItems, { ManageItemsConfig, ManageItemsHandles } from '@/shared-components/ManageItems/ManageItems';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

const Input = styled('input')({
  display: 'none',
});

interface ManageDocumentsProps {
  documents: Document[];
  onDocumentsUpdated: (documents: Document[]) => void;
  parentName?: string;
  allowedDocumentTypes: DocumentType[];
  useDeleteConfirmationModal?: boolean;
  parentLoadingStatus: LoadingStatus;
}

// Function to get filtered document type keys based on a given filter
const getFilteredDocumentTypeKeys = (filter: DocumentType[]): (keyof typeof DocumentType)[] => {
  return Object.keys(DocumentType).filter((key) =>
    filter.includes(DocumentType[key as keyof typeof DocumentType])
  ) as (keyof typeof DocumentType)[];
};

export default function ManageDocuments({
  documents = [],
  onDocumentsUpdated,
  parentName,

  allowedDocumentTypes,
  useDeleteConfirmationModal,
  parentLoadingStatus,
}: ManageDocumentsProps) {
  const api = useAPI();

  const manageItemsRef = React.createRef<ManageItemsHandles<Document>>();

  const [localDocumentsFittingFilter, setLocalDocumentsFittingFilter] = useState<Array<Document>>([]);
  const [localDocumentsNotFittingFilter, setLocalDocumentsNotFittingFilter] = useState<Array<Document>>([]);

  useEffect(() => {
    setLocalDocumentsFittingFilter(documents.filter((doc) => allowedDocumentTypes.includes(doc?.type)));
    setLocalDocumentsNotFittingFilter(documents.filter((doc) => !doc || !allowedDocumentTypes.includes(doc.type)));
  }, [documents]);

  const localOnDocumentsUpdated = (updatedDocuments: Document[]) => {
    setLocalDocumentsFittingFilter(updatedDocuments);

    // need to de-filter documents back together
    onDocumentsUpdated([...localDocumentsNotFittingFilter, ...updatedDocuments]);
  };

  const renderUploadButtonText = (document: Document): string => {
    if (document.file) {
      return document.file.name;
    } else if (document.url) {
      return document.fileName as string;
    } else {
      return 'Upload';
    }
  };

  const handleViewDocumentClick = (e: any, document: Document) => {
    e.preventDefault();
    window.open(`/documents/${document._id}`, '_blank');
  };

  const handleFileUploads = (files: File[]) => {
    // Create a new array of documents by mapping over the files array
    const newDocuments = files.map((file) => ({
      type: DocumentType.MASTER_BILL_OF_LADING,
      mode: Mode.ADD,
      file: file,
    }));

    if (manageItemsRef.current) {
      manageItemsRef.current.addItems(newDocuments as Document[]);
    }
  };

  const createDocument = async (item: Document) => {
    try {
      const { data } = await api.createDocument(item, item.file as File);
      return data;
    } catch (err) {
      console.error('error uploading file with type:', item.type);
      console.error(err);
    }
  };

  const updateDocument = async (item: Document) => {
    try {
      const { data } = await api.updateDocument(item!._id.toString(), item, item.file);
      return data;
    } catch (err) {
      console.error('error uploading file with type:', item.type);
      console.error(err);
    }
  };

  const manageItemsConfig: ManageItemsConfig = {
    beforeItemAdded: createDocument,
    beforeItemUpdated: updateDocument,
    saveDisabled: (item: Document) => {
      return item._id ? false : !item.file;
    },
    columns: [
      {
        header: 'Type',
        viewComponent: (item) => <>{DocumentTypeToText[item.type as DocumentType]}</>,
        editComponent: (item, setItem) => (
          <>
            <Select
              id="demo-simple-select"
              fullWidth
              size="small"
              onChange={(e) => setItem({ ...item, type: e.target.value })}
              value={item.type || ''}
              variant="standard"
            >
              {getFilteredDocumentTypeKeys(allowedDocumentTypes).map((key) => (
                <MenuItem key={key} value={DocumentType[key]}>
                  {DocumentTypeToText[key]}
                </MenuItem>
              ))}
            </Select>
          </>
        ),
      },
      {
        header: 'File',
        customStyle: {
          maxWidth: '250px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
        viewComponent: (item) => (
          <>
            {item.url ? (
              <a target="_blank" rel="noreferrer" href="/" onClick={(e) => handleViewDocumentClick(e, item)}>
                {item.fileName}
              </a>
            ) : (
              <></>
            )}
          </>
        ),
        editComponent: (item, setItem) => (
          <>
            <Button
              fullWidth
              variant="text"
              sx={{
                textTransform: 'none',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              component="label"
            >
              {renderUploadButtonText(item)}
              <Input
                type="file"
                hidden
                accept="*"
                multiple
                onChange={(e) => {
                  const file = e.target.files ? e.target.files[0] : undefined;
                  if (!file) return;
                  setItem({ ...item, file });
                }}
              />
            </Button>
          </>
        ),
      },
      {
        header: 'Notes',
        viewComponent: (item) => <>{item.notes}</>,
        editComponent: (item, setItem) => (
          <>
            <TextField
              value={item.notes}
              onChange={(e) => setItem({ ...item, notes: e.target.value })}
              fullWidth
              size="small"
              variant="standard"
            />
          </>
        ),
      },
    ],
  };

  return (
    <>
      <Dropzone onDrop={(acceptedFiles) => handleFileUploads(acceptedFiles)} noClick>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <ManageItems<Document>
              ref={manageItemsRef}
              manageItemsConfig={manageItemsConfig}
              parentName={parentName}
              items={localDocumentsFittingFilter}
              onItemsUpdated={localOnDocumentsUpdated}
              getDefaultItem={() => {
                return {
                  type: DocumentType.MASTER_BILL_OF_LADING,
                } as Document;
              }}
              itemName={'Documents'}
              useDeleteConfirmationModal={useDeleteConfirmationModal}
              parentLoadingStatus={parentLoadingStatus}
              styles={{
                myShipmentsHeader: {
                  color: '#525256',
                  fontSize: '24px',
                  fontWeight: '700',
                },
                itemsTableContainer: {
                  padding: '10px', // Adds padding around the main content table
                },
              }}
            />
          </div>
        )}
      </Dropzone>
    </>
  );
}
