import React, { useState } from 'react';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import { CommercialInvoiceWithDocument } from 'common/interfaces/documentParsing';
import ImportalCard from '@/shared-components/ImportalCard';
import ImportalStickySidebarMenu, {
  ImportalStickySidebarStep,
} from '@/shared-components/ImportalStickySidebarMenu/ImportalStickySidebarMenu';
import { EntryHeaderInfo, EntryManifestBolInfo } from 'common/interfaces/entry';
import EntryHeaderInfoCard from '../entries/EntryHeaderInfo';
import CommercialInvoiceParsedView from './CommercialInvoiceParsedView';
import EntryManifestBolInfoCard from '../entries/EntryManifestBolInfo';
import EntryInvoiceView from '@/broker-app/pages/shipments/EntryInvoiceView';

interface EntryPrepApplicationFilingProps {
  entryInvoice: any;
  onNext: () => void;
  onBack: () => void;
}

export default function EntryPrepApplicationFiling({ entryInvoice, onNext, onBack }: EntryPrepApplicationFilingProps) {
  const [entryPrepApplicationFilingSteps, setEntryPrepApplicationFilingSteps] = useState<ImportalStickySidebarStep[]>([
    { id: 1, name: 'Header Info', completed: false, selected: false },
    { id: 2, name: 'Manifest/BOL Info', completed: false, selected: false },
    { id: 3, name: 'Supplier/Manufacturer Info', completed: false, selected: false },
    { id: 4, name: 'Invoice/Entry Lines', completed: false, selected: false },
  ]);

  const handleSelectStep = (id: number) => {
    setEntryPrepApplicationFilingSteps((prevSteps) =>
      prevSteps.map((step) => ({
        ...step,
        selected: step.id === id, // Only select the clicked step
      }))
    );
  };

  const dummyEntryHeaderInfo: EntryHeaderInfo = {
    entryType: 'Type A',
    freightCharges: '$500.00',
    grossWeight: '1500 kg',
    totalEntryValue: '$25,000.00',
    descriptionOfMerchandise:
      'High-quality consumer electronics, including headphones, smart home devices, and accessories, designed for durability and enhanced user experience.',
    locationOfGoods: 'Warehouse A',
    carrierCode: 'C123',
    voyageFlightNumber: 'V987',
    portOfEntry: 'Los Angeles',
    portOfUnlading: 'Long Beach',
    stateOfDestination: 'California',
    modeOfTransportation: { code: 10, description: 'Vessel, Non-container' },
    estimatedEntryDate: '2024-11-01',
    dateOfImport: '2024-11-05',
    itDate: '2024-11-10',
    estimatedArrivalDate: '2024-11-02',
    teamNumber: 'T456',
    statementDate: '2024-12-01',
    processingPort: 'San Francisco',
    paymentType: { code: 2, description: 'Statement Group by Broker Filer Code' },
    customerReferenceNumber: 'CRN-7890',
    brokerReferenceNumber: 'BRN-1234',
    cargoReleaseProcessing: 'Standard',
    preparerPortCode: 'PPC-5678',
    examPortCode: 'EPC-1234',
  };

  const dummyEntryManifestBolInfo: EntryManifestBolInfo = {
    splitShipmentAndOrNonAmsBills: 'Yes',
    examSite: 'Port of Entry',
    transferCartman: 'Cartman Transfer Co.',
    transferBond: 'Bonded Transfer Services',
    bolLineItems: [
      {
        masterScac: 'MSCA',
        masterBillNumber: 'MB12345',
        houseScac: 'HSCA',
        houseBillNumber: 'HB54321',
        subhouseBillNumber: 'SHB67890',
        quantity: '10',
        units: 'Boxes',
      },
      {
        masterScac: 'MSCB',
        masterBillNumber: 'MB54321',
        houseScac: 'HSCB',
        houseBillNumber: 'HB98765',
        subhouseBillNumber: 'SHB12345',
        quantity: '20',
        units: 'Cartons',
      },
    ],
    containerLineItems: [
      {
        containerNumber: 'C123456',
        containerSize: '40ft',
        containerType: 'Refrigerated',
        sealNumber: 'SEAL789',
        poNumber: 'PO1234',
        poDescriptor: 'Electronics',
        weight: '1000',
        weightUnit: 'kg',
        quantity: '5',
        units: 'Units',
      },
      {
        containerNumber: 'C654321',
        containerSize: '20ft',
        containerType: 'Standard',
        sealNumber: 'SEAL321',
        poNumber: 'PO4321',
        poDescriptor: 'Furniture',
        weight: '2000',
        weightUnit: 'kg',
        quantity: '10',
        units: 'Units',
      },
    ],
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <ImportalStickySidebarMenu
            steps={entryPrepApplicationFilingSteps}
            handleLineItemClick={(id) => handleSelectStep(id)}
          />
        </div>
        <div>
          <EntryHeaderInfoCard entryHeaderInfo={dummyEntryHeaderInfo} />
          <EntryManifestBolInfoCard entryManifestBolInfo={dummyEntryManifestBolInfo} />
        </div>
      </div>
      <div>
        <EntryInvoiceView
          entryInvoice={entryInvoice}
          onEntryInvoiceChanged={() => {
            console.log('on entry invoice changed');
          }}
        />
      </div>
      <div className="next-button-container">
        <ImportalSecondaryButton onClick={onBack} text="Back" />
        <ImportalPrimaryButton onClick={onNext} text="Next" />
      </div>
    </>
  );
}
