import React from 'react';
import { Card, Box, Typography, Grid } from '@mui/material';
import { EntryHeaderInfo } from 'common/interfaces/entry';

const styles = {
  container: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  header: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    paddingBottom: '2px',
    borderBottom: '1px solid #D1D1D1',
    marginBottom: '8px',
  },
  subheader: {
    fontSize: '12px',
    color: '#525256',
    marginBottom: '16px',
  },
  fieldLabel: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'black',
  },
  fieldValue: {
    fontSize: '12px',
    color: '#525256',
    marginBottom: '8px',
  },
};

interface EntryHeaderInfoProps {
  entryHeaderInfo: EntryHeaderInfo;
}

const EntryHeaderInfoCard: React.FC<EntryHeaderInfoProps> = ({ entryHeaderInfo }) => {
  return (
    <Card sx={styles.container}>
      <Typography sx={styles.header}>Header Info</Typography>
      <div>
        <Typography sx={styles.fieldLabel}>Description of Merchandise</Typography>
        <Typography sx={styles.fieldValue}>{entryHeaderInfo.descriptionOfMerchandise}</Typography>
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography sx={styles.fieldLabel}>Entry Type</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.entryType}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Freight Charges</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.freightCharges}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Gross Weight</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.grossWeight}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Total Entry Value</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.totalEntryValue}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Location of Goods</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.locationOfGoods}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Port of Entry</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.portOfEntry}</Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography sx={styles.fieldLabel}>Mode of Transportation</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.modeOfTransportation.description}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Estimated Entry Date</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.estimatedEntryDate}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Date of Import</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.dateOfImport}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Payment Type</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.paymentType.description}</Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EntryHeaderInfoCard;
