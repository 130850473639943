import { useState } from 'react';

// This function isn't a hook itself but uses a hook to capture hover state and provide it to the render prop function.
const HoverState = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const bindHover = {
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };

  // Call the render prop function with the current hover state and bindings
  return children(isHovered, bindHover);
};

export default HoverState;
