import { useState, useEffect } from 'react';

const isMobile = () => {
  const isMobileScreen = window.matchMedia('(max-width: 768px)').matches;

  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  const isMobileDevice =
    /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream);

  return isMobileScreen || isMobileDevice;
};

const useIsMobile = (onMobileChange?: (isMobile: boolean) => void) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileDeviceDetected = isMobile();
      setIsMobileDevice(isMobileDeviceDetected);

      if (onMobileChange) {
        onMobileChange(isMobileDeviceDetected);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onMobileChange]);

  return isMobileDevice;
};

export default useIsMobile;
