import { useMatch, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { Card } from '@mui/material';

export default function LoadSession() {
  const navigate = useNavigate();
  const api = useAPI();
  const activeUser = useActiveUser();

  const [loadSessionErrorText, setLoadSessionErrorText] = useState('');

  const match = useMatch({
    path: '/session/:sessionId',
  });

  useEffect(() => {
    const sessionId = match?.params.sessionId;
    if (sessionId) {
      api
        .loadSession(sessionId)
        .then(async ({ data: session }) => {
          api.setToken(session.token);
          await activeUser.refreshUser();

          navigate(session.navigateTo);
        })
        .catch((err) => {
          if (err.response && err.response.status === 410) {
            // Handle "410 Gone" status code, session is expired
            setLoadSessionErrorText('Session expired');
          } else if (err.response && err.response.status === 404) {
            setLoadSessionErrorText('Session not found.');
          } else {
            console.error('error loading onboarding session');
            console.error(err);
          }
        });
    }
  }, []);

  return (
    <>
      <Card
        sx={{
          padding: '16px',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="no-results-container">
          {loadSessionErrorText ? <p>{loadSessionErrorText}</p> : <p>Loading session, please wait...</p>}
        </div>
      </Card>
    </>
  );
}
