import React, { createContext, useContext, useEffect, useState } from 'react';
import { Classification, ClassificationStatusToShortText } from 'common/interfaces/classification';
import { BusinessPageContext } from '@/broker-app/pages/businesses/BusinessPage';

import { useAPI } from '@/api/APIContext';
import ImportalCard from '@/shared-components/ImportalCard';
import { ImportalBreadCrumbs } from '@/shared-components/ImportalBreadCrumbs';
import { Report } from 'common/interfaces/reports';
import Link from '@mui/material/Link';
import { mmmddyyHmmDate } from '@/utils/activePlanUtils';
import ImportalSimpleTable from '@/shared-components/ImportalSimpleTable';
import { BrokerViewClassification } from '@/shared-components/Classification/BrokerViewClassification';
import BrokerClassificationsTable from '../hts-classifications/BrokerClassificationsTable';

export interface IClassificationsTabContext {
  classifications: Classification[];
  refreshClassifications: () => void;
}

export const ClassificationsTabContext = createContext<IClassificationsTabContext>({
  classifications: [],
  refreshClassifications: () => {},
});

const { Provider } = ClassificationsTabContext;

export default function ClassificationRequestsTab() {
  const { business } = useContext(BusinessPageContext);
  const api = useAPI();
  const [classificationRequests, setClassificationRequests] = useState<Classification[]>([]);

  const refreshClassifications = () => {
    api
      .getClassificationsForBusiness(business!._id!.toString())
      .then(({ data }) => {
        setClassificationRequests(data);
      })
      .catch((err) => {
        console.error(err);
        console.error('error getting classification requests');
      });
  };

  useEffect(() => {
    refreshClassifications();
  }, []);

  return (
    <Provider
      value={{
        classifications: classificationRequests,
        refreshClassifications,
      }}
    >
      <ImportalCard
        title={'HTS Classifications'}
        subtitle={
          <>
            {business?.name} has {classificationRequests.length} active HTS Classifications.
          </>
        }
      >
        <ImportalBreadCrumbs
          elements={[
            {
              render: ({ popItem, pushItem }) => (
                <>
                  <BrokerClassificationsTable isLoading={false} rows={classificationRequests || []} />
                  {/* <ImportalSimpleTable
                            data={classificationRequests || []}
                            columns={['Request No.', 'Status', 'Submitted By', 'Created Date']}
                            renderListItem={(datum: Classification, index) =>
                                <>
                                    <Link sx={{textDecoration: 'none', cursor: 'pointer'}} component="a"
                                          onClick={(e) => {
                                              e.preventDefault();
                                              pushItem({_id: datum._id, referenceNumber: datum.referenceNumber});
                                          }}>
                                        <span>{datum.referenceNumber || "Report"}</span>
                                    </Link>
                                    <span>{ClassificationStatusToShortText[datum.status]}</span>
                                    <span>{datum.user.toString()}</span>
                                    <span>{mmmddyyHmmDate(datum.createdAt)}</span>
                                </>
                            }
                        /> */}
                </>
              ),
              label: 'Classification Requests',
            },
            {
              render: ({ popItem, pushItem, item }) => (
                <BrokerViewClassification
                  classificationRequestId={item._id}
                  onClassificationChanged={refreshClassifications}
                />
              ),
              label: (item: { _id: string; referenceNumber: string }) => {
                return item.referenceNumber;
              },
            },
          ]}
        />
      </ImportalCard>
    </Provider>
  );
}
