import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import * as ls from 'local-storage';
import md5 from 'crypto-js/md5';

if (
  process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true' &&
  process.env.REACT_APP_DATADOG_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'importal',
    env: process.env.REACT_APP_DATADOG_ENV ?? 'unknown',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '0.1.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  interface UserModel {
    email: string;
  }
  const loggedInUser: UserModel = ls.get('active-user');
  if (loggedInUser) {
    const emailHash = md5(loggedInUser.email);
    datadogRum.setUser({ id: emailHash });
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
