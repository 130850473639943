import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  Autocomplete,
  Badge,
  BadgeProps,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { Search, Settings } from '@mui/icons-material';
import { styled } from '@mui/system';
import {
  createTableFilterFunction,
  createTableSortFunction,
  FilterFunction,
  noFilter,
  noSort,
  SortFunction,
} from 'common/utilities/importalTable';
import { ColumnConfig, ColumnSortConfig, SortDirection, TableConfig } from 'common/interfaces/importalTable';
import './ImportalTable.css';
import ImportalTableSettingsModal from '../ImportalTableSettingsModal/ImportalTableSettingsModal';
import HoverState from '../HoverState';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalButtonGroup from '../ImportalButtonGroup/ImportalButtonGroup';
import ImportalDateRangePicker from '../ImportalDateRangePicker';
import moment from 'moment';

const SortOrderBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    padding: '4px',
    transform: 'translate(0px, 0px) scale(0.7)',
  },
}));

interface Props<T> {
  data: Array<T>;
  rowGenerator?: (rowData: T, index: number) => React.JSX.Element;
  tableConfig: TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
  topRightElement?: React.JSX.Element;
  embeddedStyling?: boolean;
  loading?: boolean;
  defaultRowsPerPage?: number;
  hasRowExpand?: boolean;
  topRightButton?: React.JSX.Element;
  useSearch?: boolean;
  useSettings?: boolean;
  predefinedViews?: TableConfig[];
  openModal?: () => void;
  isProductModal?: boolean;
}

export interface ImportalTableHandles {
  getTableConfig: () => TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
}

const DefaultRowGenerator = (rowData: any, index: number, columnConfig: ColumnConfig[]) => {
  return (
    <TableRow key={`default-row-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell />
      {columnConfig
        .filter((config) => config.isVisible !== false)
        .map((config) => (
          <TableCell key={config.name}>{rowData[config.name]}</TableCell>
        ))}
    </TableRow>
  );
};

const ImportalTable = forwardRef<ImportalTableHandles, Props<any>>(
  (
    {
      data = [],
      rowGenerator,
      tableConfig,
      setTableConfig,
      topRightElement,
      embeddedStyling = false,
      loading = false,
      defaultRowsPerPage = 20,
      hasRowExpand = true,
      topRightButton,
      useSearch = true,
      useSettings = false,
      predefinedViews = [],
      openModal,
      isProductModal = false,
    },
    ref
  ) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [filterFunction, setFilterFunction] = useState<FilterFunction>(() => noFilter);
    const [sortFunction, setSortFunction] = useState<SortFunction>(() => noSort);
    const [sortedAndFilteredData, setSortedAndFilteredData] = useState<any[]>([]);
    const [tableSettingsModalOpen, setTableSettingsModalOpen] = useState(false);
    const [selectedFilteringColumn, setSelectedFilteringColumn] = useState<ColumnConfig | null>(null);
    const [selectedFilteringValues, setSelectedFilteringValues] = useState<Array<any>>([]);
    const [viewCounts, setViewCounts] = useState<Record<string, number>>({});

    const handleSettingsClick = () => {
      if (isProductModal) {
        openModal?.();
      } else {
        handleTableSettingsModalOpen();
      }
    };

    const handleTableSettingsModalOpen = () => {
      setTableSettingsModalOpen(true);
    };

    const handleTableSettingsModalClose = () => {
      setTableSettingsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      getTableConfig() {
        return tableConfig;
      },
      setTableConfig(tableConfig: TableConfig) {
        setTableConfig(tableConfig);
      },
    }));

    useEffect(() => {
      if (data.length > 0) {
        const counts = countDataForViews(predefinedViews, data, tableConfig.subKeyForData);
        setViewCounts(counts);
      }
    }, [data, predefinedViews, tableConfig.subKeyForData]);

    useEffect(() => {
      setFilterFunction(() =>
        createTableFilterFunction(tableConfig.filterConfig, tableConfig.subKeyForData, tableConfig.columns)
      );
    }, [tableConfig.filterConfig, tableConfig.subKeyForData]);

    useEffect(() => {
      setSortFunction(() =>
        createTableSortFunction(tableConfig.columns, tableConfig.sortConfig, tableConfig.subKeyForData)
      );
    }, [tableConfig.sortConfig, tableConfig.columns, tableConfig.subKeyForData]);

    useEffect(() => {
      if (data && data.length > 0) {
        let filteredData = data.filter(filterFunction).sort(sortFunction);

        if (searchQuery) {
          filteredData = filteredData.filter((row) =>
            Object.values(tableConfig.subKeyForData ? row[tableConfig.subKeyForData] : row).some((value) =>
              String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
          );
        }

        setSortedAndFilteredData(filteredData);
      }
    }, [data, sortFunction, filterFunction, searchQuery]);

    const countDataForViews = (
      predefinedViews: TableConfig[],
      data: any[],
      subKeyForData?: string
    ): Record<string, number> => {
      const viewCounts: Record<string, number> = {};

      predefinedViews.forEach((view) => {
        const filterFunction = createTableFilterFunction(view.filterConfig, subKeyForData, view.columns);
        const filteredData = data.filter(filterFunction);
        viewCounts[view.viewName as string] = filteredData.length;
      });

      return viewCounts;
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
      setPage(0); // Reset to first page when search query changes
    };

    const handleChangePage = useMemo(
      () => (event: unknown, newPage: number) => {
        setPage(newPage);
      },
      [setPage]
    );

    const handleChangeRowsPerPage = useMemo(
      () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      },
      [setRowsPerPage, setPage]
    );

    const getUniqueValuesForColumn = (column: ColumnConfig): string[] => {
      const columnValues = sortedAndFilteredData.map((row) => {
        const rowData = tableConfig.subKeyForData ? row[tableConfig.subKeyForData] : row;
        return String(rowData[column.name] || '');
      });

      return Array.from(new Set(columnValues)).filter((value) => {
        return value.trim() !== '' && !tableConfig.filterConfig[column.name]?.allowedValues?.includes(value);
      });
    };

    const handleSelectedFilterColumnChange = (event: React.ChangeEvent<{}>, selectedCol: ColumnConfig | null) => {
      if (selectedCol) {
        setSelectedFilteringColumn(selectedCol);
      } else {
        // If the first Autocomplete is cleared, also clear the second Autocomplete
        setSelectedFilteringColumn(null);
        setSelectedFilteringValues([]); // Here is where we clear the second Autocomplete
      }
    };

    const handleViewClick = (view: TableConfig, e?: React.MouseEvent<HTMLElement>) => {
      setTableConfig(view);
    };

    const handleFilterValueAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if ((event.key === 'Tab' || event.code === 'Enter') && selectedFilteringColumn && selectedFilteringValues) {
        const newFilterConfig = {
          ...tableConfig.filterConfig,
          [selectedFilteringColumn.name]: {
            allowedValues: selectedFilteringValues,
            columnName: selectedFilteringColumn.name,
            displayName: selectedFilteringColumn.displayName,
          },
        };
        setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });

        handleAddFilterChip(
          selectedFilteringColumn.name,
          selectedFilteringColumn.displayName as string,
          selectedFilteringValues
        );

        setSelectedFilteringColumn(null);
        setSelectedFilteringValues([]);
      }
    };

    const handleAddFilterChip = (columnName: string, displayName: string, values: Array<any>) => {
      const newFilterConfig = { ...tableConfig.filterConfig };
      newFilterConfig[columnName] = {
        allowedValues: values,
        columnName: columnName,
        displayName: displayName,
      };
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const handleValueChange = (event: React.SyntheticEvent, values: Array<any>) => {
      if (!event) return;
      const isTabKey = (event as React.KeyboardEvent).key === 'Tab';
      setSelectedFilteringValues(values);

      if (isTabKey && selectedFilteringColumn && values.length > 0) {
        const newFilterConfig = {
          ...tableConfig.filterConfig,
          [selectedFilteringColumn.name]: {
            allowedValues: values,
            columnName: selectedFilteringColumn.name,
            displayName: selectedFilteringColumn.displayName,
          },
        };
        setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });

        handleAddFilterChip(selectedFilteringColumn.name, selectedFilteringColumn.displayName as string, values);
      }
    };

    // Function to remove a chip and its associated filter
    const handleDeleteFilterChip = (columnName: string, value: string) => {
      const newFilterConfig = { ...tableConfig.filterConfig };

      // Filter out the specific value from allowedValues
      const filteredValues = newFilterConfig[columnName].allowedValues.filter((val) => val !== value);

      if (filteredValues.length === 0) {
        // Remove the filter entirely if no values are left
        delete newFilterConfig[columnName];
      } else {
        // Update the allowedValues with the remaining ones
        newFilterConfig[columnName] = {
          ...newFilterConfig[columnName],
          allowedValues: filteredValues,
        };
      }

      // Set the updated filterConfig in tableConfig
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const onClickColumnSort = (columnName: string) => {
      const matchesName = (sortConfig: ColumnSortConfig) => sortConfig.columnName === columnName;
      const newTableSortConfig = [...tableConfig.sortConfig];

      const currentSortDirection = tableConfig.sortConfig?.find(matchesName);
      if (currentSortDirection && currentSortDirection.direction === SortDirection.asc) {
        // set to desc
        newTableSortConfig[newTableSortConfig.findIndex(matchesName)]['direction'] = SortDirection.desc;
      } else if (currentSortDirection && currentSortDirection.direction === SortDirection.desc) {
        // remove entirely
        newTableSortConfig.splice(newTableSortConfig.findIndex(matchesName), 1);
      } else {
        // set to asc
        newTableSortConfig.push({
          columnName: columnName,
          displayName: tableConfig.columns.find((config: ColumnConfig) => config.name === columnName)?.displayName,
          direction: SortDirection.asc,
        });
      }

      setTableConfig({ ...tableConfig, sortConfig: newTableSortConfig });
    };

    const generateDateRange = (startDate: Date, endDate: Date): string[] => {
      const dates: string[] = [];
      let currentDate = moment(startDate);

      while (currentDate.isSameOrBefore(endDate, 'day')) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }

      return dates;
    };

    const isDateColumn = (column: ColumnConfig) => column.dataType === 'date';

    const renderFilterValueField = () => {
      if (!selectedFilteringColumn) return null;

      if (isDateColumn(selectedFilteringColumn)) {
        return (
          <ImportalDateRangePicker
            timePeriod={{
              periodStartDate: selectedFilteringValues[0] || null,
              periodEndDate: selectedFilteringValues[1] || null,
            }}
            onTimePeriodSelected={({ periodStartDate, periodEndDate }) => {
              if (periodStartDate && periodEndDate) {
                // Set selected filtering values to be the raw date objects
                setSelectedFilteringValues([periodStartDate, periodEndDate]);

                // Generate date-only strings for the allowedValues array in New York timezone
                const dateRangeValues = generateDateRange(periodStartDate, periodEndDate).map((dateStr) =>
                  moment.tz(dateStr, 'America/New_York').format('YYYY-MM-DD')
                );

                const newFilterConfig = {
                  ...tableConfig.filterConfig,
                  [selectedFilteringColumn.name]: {
                    allowedValues: dateRangeValues, // Store date-only values in New York timezone format
                    columnName: selectedFilteringColumn.name,
                    displayName: selectedFilteringColumn.displayName,
                  },
                };

                setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
                setSelectedFilteringColumn(null);
                setSelectedFilteringValues([]);
              }
            }}
            onCompleteSelection={() => {
              const startDate = moment(selectedFilteringValues[0]).format('MM/DD/YY');
              const endDate = moment(selectedFilteringValues[1]).format('MM/DD/YY');
              const chipLabel = `${selectedFilteringColumn.displayName || ''}: ${startDate} - ${endDate}`;

              handleAddFilterChip(
                selectedFilteringColumn.name,
                chipLabel, // Display formatted date range as chip label
                selectedFilteringValues
              );
              setSelectedFilteringColumn(null);
              setSelectedFilteringValues([]);
            }}
          />
        );
      }

      return (
        <Paper
          sx={{
            borderRadius: '8px!important',
            minWidth: '250px',
            height: '40px',
            paddingLeft: '10px',
            paddingRight: '16px',
          }}
        >
          <Autocomplete
            options={getUniqueValuesForColumn(selectedFilteringColumn)}
            multiple={true}
            isOptionEqualToValue={(option, value) => option === value}
            value={selectedFilteringValues} // Make sure the value reflects the state of clearing
            onChange={(event, newInputValue) => handleValueChange(event, newInputValue)}
            disableCloseOnSelect={true}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`Select a value for ${selectedFilteringColumn.displayName || selectedFilteringColumn?.name}`}
                fullWidth={true}
                onKeyDown={handleFilterValueAutocompleteKeyDown} // Handle "Tab" press for filter
                sx={{
                  backgroundColor: 'transparent',
                  '& .MuiOutlinedInput-root': {
                    padding: '4px',
                    paddingLeft: '8px',
                    '& fieldset': {
                      border: '1px solid lightgrey',
                      borderRadius: '8px',
                    },
                    fontSize: '12px',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '12px',
                  },
                }}
              />
            )}
          />
        </Paper>
      );
    };

    const getTableSortLabel = (columnConfig: ColumnConfig): React.JSX.Element => {
      return (
        <TableSortLabel
          active={isSorted(columnConfig.name)}
          direction={getColumnSortDirection(columnConfig.name)}
          onClick={() => onClickColumnSort(columnConfig.name)}
        >
          {columnConfig.displayName || columnConfig.name}
        </TableSortLabel>
      );
    };

    const isSorted = (columnName: string): boolean => {
      return -1 !== tableConfig.sortConfig.findIndex((columnSortConfig) => columnSortConfig.columnName === columnName);
    };

    const getColumnSortDirection = (columnName: string): SortDirection | undefined => {
      return tableConfig.sortConfig.find((columnSortConfig) => columnSortConfig.columnName === columnName)?.direction;
    };

    const getSortOrderForColumn = (columnName: string): number | undefined => {
      const foundIndex = tableConfig.sortConfig.findIndex(
        (columnSortConfig) => columnSortConfig.columnName === columnName
      );
      return foundIndex !== -1 ? foundIndex + 1 : undefined;
    };

    const headerCellStyle = {
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontWeight: '600',
    };

    const isDateMatch = (value1: string, value2: string): boolean => {
      const date1 = moment(value1).format('YYYY-MM-DD');
      const date2 = moment(value2).format('YYYY-MM-DD');
      return date1 === date2;
    };

    const handleDeleteDateFilterChip = (columnName) => {
      // Create a copy of the filter config
      const newFilterConfig = { ...tableConfig.filterConfig };

      // Delete the entire allowedValues for the specified date column
      delete newFilterConfig[columnName];

      // Update table config with the modified filter config
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const renderFilterChips = () => {
      return Object.keys(tableConfig.filterConfig).map((columnName) => {
        const filterConfig = tableConfig.filterConfig[columnName];
        const defaultFilter =
          predefinedViews.find((view) => view === tableConfig)?.filterConfig[columnName]?.allowedValues || [];

        const isDateColumn =
          filterConfig.columnName &&
          tableConfig.columns.some((col) => col.name === filterConfig.columnName && col.dataType === 'date');

        if (isDateColumn && filterConfig.allowedValues.length > 1) {
          // Display single chip for date range, but enable deletion of entire date range
          const startDate = moment(filterConfig.allowedValues[0]).format('MM/DD/YYYY');
          const endDate = moment(filterConfig.allowedValues[filterConfig.allowedValues.length - 1]).format(
            'MM/DD/YYYY'
          );
          return (
            <Chip
              key={`${columnName}-date-range`}
              label={`${filterConfig.displayName || filterConfig.columnName}: ${startDate} - ${endDate}`}
              onDelete={() => handleDeleteDateFilterChip(columnName)} // Use the new function
              sx={{
                backgroundColor: '#77A8F6',
                color: 'white',
                borderRadius: '10px!important',
                fontWeight: '700',
                padding: '8px',
                fontSize: '12px',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                  fontSize: '18px',
                },
              }}
            />
          );
        }

        // Render individual chips for non-date types or if only one date is in the range
        return filterConfig.allowedValues
          .filter((value) => !defaultFilter.includes(value))
          .map((value) => (
            <Chip
              key={`${columnName}-${value}`}
              label={`${filterConfig.displayName || filterConfig.columnName}: ${value}`}
              onDelete={() => handleDeleteFilterChip(columnName, value)}
              sx={{
                backgroundColor: '#77A8F6',
                color: 'white',
                borderRadius: '10px!important',
                fontWeight: '700',
                padding: '8px',
                fontSize: '12px',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                  fontSize: '18px',
                },
              }}
            />
          ));
      });
    };

    return (
      <div className="importal-table-and-search-container">
        {predefinedViews.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <ImportalButtonGroup style={{ height: '40px' }}>
                {predefinedViews.map((view, index) => (
                  <ImportalPrimaryButton
                    key={`one-${index}`}
                    style={{
                      fontSize: '12px',
                      padding: '0px 12px 0px 12px',
                      boxShadow: 'none',
                      fontWeight: tableConfig === view ? 'bold' : 'normal',
                      borderLeft: '1px solid #A9A9A9',
                    }}
                    text={`${view.viewName || ''} (${viewCounts[view.viewName as string] || 0})`}
                    onClick={(e) => handleViewClick(view, e)}
                  />
                ))}
              </ImportalButtonGroup>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '6px', alignItems: 'end', marginTop: '8px' }}>
              <Paper
                sx={{
                  borderRadius: '8px!important',
                  minWidth: '225px',
                  height: '40px',
                  paddingLeft: '10px',
                  paddingRight: '16px',
                }}
              >
                <Autocomplete
                  options={tableConfig.columns}
                  getOptionLabel={(option: ColumnConfig | string) => {
                    if (typeof option === 'string') return option;
                    return option.displayName || option.name;
                  }}
                  value={selectedFilteringColumn}
                  onChange={handleSelectedFilterColumnChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filter by column..."
                      fullWidth={true}
                      sx={{
                        backgroundColor: 'transparent',
                        '& .MuiOutlinedInput-root': {
                          padding: '4px',
                          paddingLeft: '8px',
                          '& fieldset': {
                            border: '1px solid lightgrey',
                            borderRadius: '8px',
                          },
                          fontSize: '12px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  )}
                />
              </Paper>
              {selectedFilteringColumn && selectedFilteringColumn.name && renderFilterValueField()}
              <div style={{ overflowY: 'auto', alignSelf: 'center', maxHeight: '40px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'end',
                    columnGap: '4px',
                    rowGap: '2px',
                  }}
                >
                  {renderFilterChips()}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'end' }}>
            {useSettings && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{
                    width: '36px',
                    height: '36px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={handleSettingsClick}
                >
                  <Settings sx={{ fontSize: '20px' }} />
                </IconButton>
              </div>
            )}
            {useSearch && (
              <Paper
                sx={{
                  minWidth: '300px',
                  borderRadius: '8px!important',
                  height: '40px',
                  paddingLeft: '10px',
                }}
              >
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  placeholder="Search..."
                  sx={{ height: '40px!important', input: { fontSize: 12 } }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ fontSize: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            )}
            {topRightButton}
          </div>
        </div>
        <Paper sx={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '4px' }}>
          <TableContainer className="importal-table-container">
            <div style={{ overflowX: 'scroll', maxWidth: '100%' }}>
              <Table className="importal-table">
                <TableHead className="importal-table-header">
                  <TableRow sx={{ borderRadius: '8px' }} className="importal-table-header-row">
                    {hasRowExpand && <TableCell className="importal-table-header-row-cell" id="expand-spacer" />}
                    {tableConfig.columns
                      .filter((fieldConfig) => fieldConfig.isVisible !== false)
                      .map((fieldConfig, index) => (
                        <HoverState key={`field-${index}`}>
                          {(isHovered, bindHover) => (
                            <TableCell
                              key={index}
                              sx={headerCellStyle}
                              className={`importal-table-header-row-cell ${fieldConfig.sortable && 'sorting'} ${
                                fieldConfig.filterable && 'filtering'
                              }`}
                              {...bindHover}
                            >
                              {fieldConfig.sortable ? (
                                <SortOrderBadge badgeContent={getSortOrderForColumn(fieldConfig.name)} color="primary">
                                  {getTableSortLabel(fieldConfig)}
                                </SortOrderBadge>
                              ) : (
                                fieldConfig.displayName || fieldConfig.name
                              )}
                            </TableCell>
                          )}
                        </HoverState>
                      ))}
                    {topRightElement && (
                      <TableCell
                        className="importal-table-header-row-cell"
                        sx={headerCellStyle}
                        style={{ textAlign: 'center' }}
                      >
                        {topRightElement}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAndFilteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((datum, index) => (rowGenerator || DefaultRowGenerator)(datum, index, tableConfig.columns))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[5, 10, 20, 50]}
              count={sortedAndFilteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ImportalTableSettingsModal
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              open={tableSettingsModalOpen}
              onClose={handleTableSettingsModalClose}
              onTableSettingsEdited={() => {}}
              tableData={sortedAndFilteredData}
              predefinedViews={predefinedViews}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
);

export default ImportalTable;
