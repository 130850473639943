import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, CircularProgress, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { datadogRum } from '@datadog/browser-rum';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { useAPI } from '@/api/APIContext';
import { AxiosError } from 'axios';
import WelcomeHeader from '@/shared-components/WelcomeHeader/WelcomeHeader';

export default function Login() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const activeUser = useActiveUser();
  const api = useAPI();

  const handleSignUpAccount = () => {
    navigate('/signup');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userToSubmit = {
      email: data.get('email'),
      password: data.get('password'),
    };
    loginUser(userToSubmit);
  };

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  const loginUser = (loginData) => {
    handleLoading(true);
    api
      .login(loginData)
      .then(async (response) => {
        datadogRum.addAction('login', {});
        api.setToken(response.data.token);

        await activeUser.refreshUser();
        handleLoading(false);
        navigate('/dashboard');
      })
      .catch(function (error) {
        console.error('error logging in');
        console.error(error);

        if ((error as AxiosError).response?.data) {
          //@ts-ignore
          const { error: message } = error.response.data;
          if (message === 'User must complete authentication before login') {
            navigate('/authenticate-phone');
          }
        }

        handleLoading(false);

        setErrorText(error.response.data.error.message);
      });
  };

  return (
    <div>
      <Container sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <img src="importal-2.png" height="90" width="140" alt="logo" />
      </Container>
      <div className="card-container-signup">
        <Card sx={{ padding: '32px', margin: '0 auto' }}>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <WelcomeHeader className="middle">
              <Typography variant="h4">Welcome back, let's get you logged in.</Typography>
            </WelcomeHeader>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth id="email" label="Email Address" name="email" autoComplete="email" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                  />
                </Grid>
              </Grid>
              <div onClick={handleForgotPasswordClick} className="forgot-password">
                Forgot Password?
              </div>
              <Grid className="error-text">{errorText}</Grid>
              <div className="login-buttons-container">
                <div className="signup-button-container">
                  <Button onClick={handleSignUpAccount} fullWidth variant="text">
                    Sign up
                  </Button>
                </div>
                <div className="login-button-container">
                  <Button type="submit" fullWidth variant="contained" disabled={isLoading}>
                    {isLoading ? <CircularProgress color="success" size={20} /> : 'Login'}
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        </Card>
      </div>
    </div>
  );
}
