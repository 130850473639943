import React, { useState } from 'react';
import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import {
  EntryManifestBolInfo,
  EntryManifestBolLineItem,
  EntryManifestContainerLineItem,
} from 'common/interfaces/entry';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  header: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    paddingBottom: '2px',
    borderBottom: '1px solid #D1D1D1',
    marginBottom: '8px',
  },
  fieldLabel: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'black',
    marginRight: '16px',
  },
  fieldValue: {
    fontSize: '12px',
    color: '#525256',
    marginRight: '16px',
  },
  itemsContainer: {
    marginTop: '16px',
  },
};

// Configurations for BOL Line Items in ManageItems
const bolLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'Master Bill #',
      viewComponent: (item) => (
        <>
          {item.masterScac}
          {item.masterBillNumber}
        </>
      ),
      editComponent: (item, setItem) => (
        <>
          {item.masterScac}
          {item.masterBillNumber}
        </>
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Master Bill #"
          value={item.masterBillNumber}
          onChange={(e) => setItem({ ...item, masterBillNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'House SCAC',
      viewComponent: (item) => <>{item.houseScac}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="House SCAC"
          value={item.houseScac}
          onChange={(e) => setItem({ ...item, houseScac: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="House SCAC"
          value={item.houseScac}
          onChange={(e) => setItem({ ...item, houseScac: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'House Bill #',
      viewComponent: (item) => <>{item.houseBillNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="House Bill #"
          value={item.houseBillNumber}
          onChange={(e) => setItem({ ...item, houseBillNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="House Bill #"
          value={item.houseBillNumber}
          onChange={(e) => setItem({ ...item, houseBillNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item) => <>{item.quantity}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="Quantity"
          value={item.quantity}
          onChange={(e) => setItem({ ...item, quantity: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Quantity"
          value={item.quantity}
          onChange={(e) => setItem({ ...item, quantity: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Units',
      viewComponent: (item) => <>{item.units}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="Units"
          value={item.units}
          onChange={(e) => setItem({ ...item, units: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Units"
          value={item.units}
          onChange={(e) => setItem({ ...item, units: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
  ],
};

// Configurations for Container Line Items in ManageItems
const containerLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'Container #',
      viewComponent: (item) => <>{item.containerNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="Container #"
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Container #"
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Container Size',
      viewComponent: (item) => <>{item.containerSize}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="Container Size"
          value={item.containerSize}
          onChange={(e) => setItem({ ...item, containerSize: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Container Size"
          value={item.containerSize}
          onChange={(e) => setItem({ ...item, containerSize: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Container Type',
      viewComponent: (item) => <>{item.containerType}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="Container Type"
          value={item.containerType}
          onChange={(e) => setItem({ ...item, containerType: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Container Type"
          value={item.containerType}
          onChange={(e) => setItem({ ...item, containerType: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
    {
      header: 'Seal Number',
      viewComponent: (item) => <>{item.sealNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          label="Seal Number"
          value={item.sealNumber}
          onChange={(e) => setItem({ ...item, sealNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          label="Seal Number"
          value={item.sealNumber}
          onChange={(e) => setItem({ ...item, sealNumber: e.target.value })}
          fullWidth
          size="small"
        />
      ),
    },
  ],
};

interface EntryManifestBolInfoProps {
  entryManifestBolInfo: EntryManifestBolInfo;
}

const EntryManifestBolInfoCard: React.FC<EntryManifestBolInfoProps> = ({ entryManifestBolInfo }) => {
  const [bolLineItems, setBolLineItems] = useState(entryManifestBolInfo.bolLineItems);
  const [containerLineItems, setContainerLineItems] = useState(entryManifestBolInfo.containerLineItems);

  // Handlers for updating items
  const handleBolItemsUpdated = (updatedItems: EntryManifestBolLineItem[]) => {
    setBolLineItems(updatedItems);
  };

  const handleContainerItemsUpdated = (updatedItems: EntryManifestContainerLineItem[]) => {
    setContainerLineItems(updatedItems);
  };

  return (
    <Card sx={styles.container}>
      <Typography sx={styles.header}>Manifest / BOL Info</Typography>
      <Grid container spacing={3} alignItems="center" sx={{ marginBottom: '16px' }}>
        <Grid item>
          <Typography sx={styles.fieldLabel}>Split Shipment & Non-AMS Bills</Typography>
          <Typography sx={styles.fieldValue}>{entryManifestBolInfo.splitShipmentAndOrNonAmsBills}</Typography>
        </Grid>
      </Grid>
      <Box sx={styles.itemsContainer}>
        <ManageItems<EntryManifestBolLineItem>
          manageItemsConfig={bolLineItemsConfig}
          items={bolLineItems}
          getDefaultItem={() => ({
            masterScac: '',
            masterBillNumber: '',
            houseScac: '',
            houseBillNumber: '',
            subhouseBillNumber: '',
            quantity: '',
            units: '',
          })}
          onItemsUpdated={handleBolItemsUpdated}
          itemName="Bill of Ladings"
          useDeleteConfirmationModal={true}
          parentLoadingStatus={LoadingStatus.NOT_LOADING}
        />
      </Box>
      <Box sx={styles.itemsContainer}>
        <ManageItems<EntryManifestContainerLineItem>
          manageItemsConfig={containerLineItemsConfig}
          items={containerLineItems}
          getDefaultItem={() => ({
            containerNumber: '',
            containerSize: '',
            containerType: '',
            sealNumber: '',
            poNumber: '',
            poDescriptor: '',
            weight: '',
            weightUnit: '',
            quantity: '',
            units: '',
          })}
          onItemsUpdated={handleContainerItemsUpdated}
          itemName="Containers"
          useDeleteConfirmationModal={true}
          parentLoadingStatus={LoadingStatus.NOT_LOADING}
        />
      </Box>
    </Card>
  );
};

export default EntryManifestBolInfoCard;
