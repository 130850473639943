import { Box, Checkbox, Tab, Tabs } from '@mui/material';
import React, { useState, useCallback, memo, ReactNode } from 'react';
import { TabPanelProps } from '@mui/joy';

export interface TabConfig {
  title: string;
  label: ReactNode;
  element: React.JSX.Element;
}

export interface VerticalTabsProps {
  tabsConfig: TabConfig[];
  inCard?: boolean;
  style?: React.CSSProperties;
  selectedTabIndex?: number;
  onTabChange?: (newIndex: number) => void;
}

const defaultStyles = {
  tabContainer: {
    display: 'flex',
    marginLeft: '-16px',
    marginTop: '30px',
    borderRadius: '16px',
  },
  tab: {
    textTransform: 'none',
  },
  activeTab: {
    backgroundColor: 'white',
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    marginLeft: '6px',
    marginTop: '6px',
    boxShadow: 'rgba(0, 0, 0, 0.07) 3px 0px 10px, rgba(0, 0, 0, 0.07) 0px 3px 10px, rgba(0, 0, 0, 0.07) 0px -3px 10px',
  },
  inactiveTab: {
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: 0,
    marginTop: '6px',
  },
  tabPanel: {
    boxShadow: `0px 8px 12px 0px rgba(76, 103, 100, 0.03), 
        0px -8px 12px 0px rgba(76, 103, 100, 0.03), 
        8px 0px 12px 0px rgba(76, 103, 100, 0.03), 
        -8px 0px 12px 0px rgba(76, 103, 100, 0.03)!important`,
    flex: 1,
    borderRadius: '16px',
  },
};

const CustomTabPanel = memo((props: TabPanelProps & { index: number; value: number; inCard?: boolean }) => {
  const { children, value, index, inCard = false, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={inCard ? defaultStyles.tabPanel : {}}>{children}</Box>}
    </div>
  );
});

export default function ImportalVerticalTabs({
  tabsConfig,
  inCard = false,
  style,
  selectedTabIndex,
  onTabChange,
}: VerticalTabsProps) {
  const [localSelectedTabIndex, setLocalSelectedTabIndex] = useState(selectedTabIndex || 0); // initialize to provided index or zero

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setLocalSelectedTabIndex(newValue);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginRight: '205px', ...style }}>
      <Box sx={defaultStyles.tabContainer}>
        <Tabs
          value={localSelectedTabIndex}
          onChange={handleTabChange}
          aria-label="vertical tabs"
          TabIndicatorProps={{ sx: { display: 'none' } }}
          orientation="vertical"
          variant="scrollable"
        >
          {tabsConfig.map((tabConfig, index) => (
            <Tab
              key={index}
              sx={{
                ...defaultStyles.tab,
                ...(selectedTabIndex === index ? defaultStyles.activeTab : defaultStyles.inactiveTab),
              }}
              label={tabConfig.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabsConfig.map((tabConfig, index) => (
        <CustomTabPanel key={index} value={localSelectedTabIndex} index={index} inCard={inCard}>
          {tabConfig.element}
        </CustomTabPanel>
      ))}
    </div>
  );
}
