import React, { useState } from 'react';
import './Settings.css';
import ChangePassword from '@/components/change-password/ChangePassword';
import CancelSubscription from '@/components/cancel-subscription/CancelSubscription';
import Profile from './Profile';
import Billing from './Billing';
import Plan from './Plan';
import { Typography, useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BackdropLoader from '@/components/snackbar-loader/SnackbarLoader';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import PlanAdmin from '@/pages/settings/PlanAdmin';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';

export default function Settings({ setSelectedModule }) {
  const theme = useTheme();
  const activePlan = useActivePlan();
  const activeBusiness = useActiveBusiness();
  const [openPasswordDialogue, setOpenPasswordDialogue] = useState(false);
  const [openSubscriptionDialogue, setOpenSubscriptionDialogue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onPasswordDialogue = () => {
    setOpenPasswordDialogue(!openPasswordDialogue);
  };

  const onSubscriptionDialogue = () => {
    setOpenSubscriptionDialogue(!openSubscriptionDialogue);
  };
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  const user = useActiveUser();

  return (
    <div className="setting-wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
      <BackdropLoader isLoading={isLoading} />

      <ChangePassword
        openDialogue={onPasswordDialogue}
        open={openPasswordDialogue}
        setSelectedModule={setSelectedModule}
        handleLoading={setIsLoading}
      />

      <CancelSubscription
        openDialogue={onSubscriptionDialogue}
        open={openSubscriptionDialogue}
        handleLoading={setIsLoading}
      />

      <div className="main-page-header">
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '26px' }}>Settings</Typography>
        <div className="universal-subheader">Update your profile, billing and subscription info.</div>
      </div>
      <Profile onPassworDialogue={onPasswordDialogue} handleLoading={setIsLoading} />

      <Plan onSubscriptionDialogue={onSubscriptionDialogue} handleLoading={setIsLoading} />
      {(activePlan.isUserPlanAdmin || !activePlan.hasPlanAdmin) && (
        <Elements stripe={stripePromise}>
          <Billing handleLoading={setIsLoading} isLoading={isLoading} />
        </Elements>
      )}

      {activePlan.isUserPlanAdmin && activeBusiness.business && <PlanAdmin></PlanAdmin>}
    </div>
  );
}
