import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Card, CardContent } from '@mui/material';

export interface ImportalStickySidebarStep {
  id: number;
  name: string;
  completed: boolean;
  selected: boolean;
}

type ImportalStickySidebarMenuProps = {
  steps: ImportalStickySidebarStep[];
  handleLineItemClick: (id: number, field: 'completed' | 'selected') => void;
};

const ImportalStickySidebarMenu: React.FC<ImportalStickySidebarMenuProps> = ({ steps, handleLineItemClick }) => (
  <Card sx={{ height: '100%', padding: '24px!important' }}>
    <CardContent sx={{ fontSize: '12px', padding: '0px!important' }}>
      <div style={{ fontWeight: '700', fontSize: '16px', paddingBottom: '2px', borderBottom: '1px solid #D1D1D1' }}>
        Summary
      </div>
      <div style={{ position: 'sticky', top: 0 }}>
        <List>
          {steps.map((step) => (
            <ListItem
              key={step.id}
              sx={{ paddingY: 0, marginY: 0 }} // Reduced top and bottom margin
            >
              <ListItemIcon sx={{ minWidth: '20px' }}>
                {step.completed ? (
                  <CheckCircleIcon sx={{ fontSize: '1rem' }} color="primary" />
                ) : (
                  <RadioButtonUncheckedIcon sx={{ fontSize: '1rem' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={step.name}
                primaryTypographyProps={{
                  fontSize: '12px',
                  fontWeight: step.selected ? 'bold' : 'normal', // Bold font if selected
                }}
                onClick={() => handleLineItemClick(step.id, 'selected')}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </CardContent>
  </Card>
);

export default ImportalStickySidebarMenu;
