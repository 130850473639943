import React, { useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import { IconButton, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import ProductTable from './ProductTable';
import UploadProductsDataModal from './UploadProductsDataModal';
import ActiveJobsTable, { ActiveJob } from './ActiveJobsTable';
import AddOrEditAProductModal from './AddOrEditAProductModal';
import { Product, ProductAction } from 'common/interfaces/product';
import { DataImport, DataImportStatus } from 'common/interfaces/dataimport';
import ConfirmModal from './ConfirmModal';
import './ProductLibrary.css';
import EditProductFieldsModal from './EditProductFieldsModal';
import { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';
import Menu from '@mui/material/Menu';
import ExportProductDataModal from '@/pages/product-library/ExportProductDataModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Settings from '@mui/icons-material/Settings';
import EditTableSettingsModal from '@/pages/product-library/EditTableSettingsModal';
import { DataExportProductCSV, DataExportStatus } from 'common/interfaces/dataExport';
import ImportalCard from '@/shared-components/ImportalCard';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import Dropzone from 'react-dropzone';
import DeleteConfirmationModal from '@/shared-components/DeleteConfirmationModal/DeleteConfirmationModal';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import complianceNotifications from '@/shared-components/complianceNotifications';
import { ComplianceNotification } from 'common/interfaces/complianceSummary';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { ProductFieldsConfig } from 'common/interfaces/productfields';

export default function ProductLibrary() {
  const api = useAPI();
  const theme = useTheme();

  const [products, setProducts] = useState<Product[]>([]);
  const [addOrEditAProductOpen, setAddOrEditAProductOpen] = useState(false);
  const [uploadProductsOpen, setUploadProductsOpen] = useState(false);
  const [exportProductDataOpen, setExportProductDataOpen] = useState(false);
  const [productLists, setProductLists] = useState<File[]>([]);

  const [activeJobs, setActiveJobs] = useState<ActiveJob[]>([]);

  const [dataImportForViewingInModal, setDataImportForViewingInModal] = useState<DataImport<any> | undefined>();
  const [productIDForViewingInModal, setProductIDForViewingInModal] = useState<string | undefined>();

  const [productIdForDeleteModal, setProductIdForDeleteModal] = useState<string | undefined>();
  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState(false);

  const [editProductFieldsOpen, setEditProductFieldsOpen] = useState(false);
  const [editTableSettingsOpen, setEditTableSettingsOpen] = useState(false);

  const [productFieldsConfig, setProductFieldsConfig] = useState<ProductFieldsConfig | undefined>(undefined);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  // TODO: add the type for the importalTable so it has the method i want
  const importalTableRef = React.createRef<ImportalTableHandles>();

  const activeBusiness = useActiveBusiness();
  const activeUser = useActiveUser();
  const [notifications, setNotifications] = useState<ComplianceNotification[]>([]);
  const [closeInfoAlert, setCloseInfoAlert] = useState(true);

  const getBusinessAndNotifications = async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      let businessId = activeBusiness.business!._id!.toString();
      const { data: notifications } = await api.getComplianceNotifications(businessId);

      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('Successfully retrieved Compliance Notifications');
      setNotifications(notifications);
    } catch (error) {
      setErrorText('Error getting compliance notifications');
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  const getProducts = () => {
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getProducts()
      .then(({ data }) => {
        setProducts(data);
        setSuccessText('Successfully retrieved Products');
        setLoadingStatus(LoadingStatus.SUCCESS);
      })
      .catch((err) => {
        console.error('error getting all products for user', err);
        setErrorText('Error getting product');
        setLoadingStatus(LoadingStatus.ERROR);
      });
  };

  useEffect(() => {
    getProducts();
  }, [api]);

  const handleFilesDropped = async (files: File[]) => {
    if (!(dataImportForViewingInModal && dataImportForViewingInModal?.status === DataImportStatus.DRAFT)) {
      const { data: dataImport } = await api.createNewDataImport();
      setDataImportForViewingInModal(dataImport);
    }

    setProductLists(files);
    setUploadProductsOpen(true);
  };

  const refreshProductFieldsConfig = () => {
    api
      .getProductFields()
      .then(({ data: productFieldsConfig }) => {
        setProductFieldsConfig(productFieldsConfig);
      })
      .catch((err) => {
        console.error('error getting product fields config', err);
      });
  };

  useEffect(refreshProductFieldsConfig, [api]);

  const checkForActiveJobs = async () => {
    setActiveJobs([]);
    let jobsToMonitor: ActiveJob[] = [];
    do {
      try {
        const { data: productUploads } = await api.getActiveProductUploads();

        jobsToMonitor = productUploads.map((productUpload) => ({
          type: 'Products Upload',
          status: productUpload.status,
        }));
      } catch (err) {
        console.error('error getting active product uploads', err);
      }

      try {
        const { data: calculationsInProgress } = await api.getActiveCalculations();

        const activeCalculationJobs = calculationsInProgress.map((calculation) => ({
          type: 'Products Compliance Calculation',
          status: 'PROCESSING',
        }));
        jobsToMonitor = [...jobsToMonitor, ...activeCalculationJobs];
      } catch (err) {
        console.error('error getting active product compliance summary calculations', err);
      }
      setActiveJobs(jobsToMonitor);

      await new Promise((resolve, reject) => setTimeout(resolve, 3000));
    } while (
      jobsToMonitor &&
      jobsToMonitor.length > 0 &&
      jobsToMonitor.some((job) => job.status === 'PROCESSING' || job.status === DataImportStatus.SUBMITTED_PENDING)
    );

    getProducts();
  };
  useEffect(() => {
    checkForActiveJobs();

    if (activeJobs && activeJobs.length > 0 && activeJobs.some((job) => job.status === 'PROCESSING')) {
      setTimeout(checkForActiveJobs, 4000);
    } else {
      getProducts();
    }
  }, [api]);

  const onClickUploadProductData = async () => {
    if (!(dataImportForViewingInModal && dataImportForViewingInModal?.status === DataImportStatus.DRAFT)) {
      const { data: dataImport } = await api.createNewDataImport();
      setDataImportForViewingInModal(dataImport);
    }
    setUploadProductsOpen(true);
  };

  const productUploadSubmitted = (dataImport: DataImport<any>) => {
    api
      .submitDataImport(dataImport._id.toString())
      .then((data) => {
        checkForActiveJobs();
      })
      .catch((err) => {
        console.error('error submitting data import for processing');
        console.error(err);
      });
  };

  const onClickAddProduct = async () => {
    setProductIDForViewingInModal(undefined);
    setAddOrEditAProductOpen(true);
  };

  const onClickEditProduct = (productID: string) => {
    setProductIDForViewingInModal(productID);
    setAddOrEditAProductOpen(true);
  };

  const onProductCreatedModifiedOrDeleted = (productId: string, action: ProductAction) => {
    switch (action) {
      case ProductAction.CREATED:
        api
          .getProductByID(productId)
          .then(({ data: newProduct }) => {
            setProducts([...products, newProduct]);
          })
          .catch((err) => {
            console.error('error getting product to update table after creation');
            console.error(err);
          });

        break;
      case ProductAction.MODIFIED:
        api
          .getProductByID(productId)
          .then(({ data: updatedProduct }) => {
            const index = products.findIndex((product: Product) => product._id!.toString() === productId);
            if (index !== -1) {
              let newProducts = [...products];
              newProducts[index] = updatedProduct; // Splice in the new product at the same index
              setProducts(newProducts);
            }
          })
          .catch((err) => {
            console.error('error getting product to update table after modification');
            console.error(err);
          });

        break;
      case ProductAction.DELETED:
        // TODO: interesting corner case with deleting.
        // if you are on a paginating page, and the product that was deleted is the only thing on that page
        // i.e. last page with one item,
        // then you would want to mess with the paginating controls while deleting the product
        // i.e. move the user to the last page with contents since the products list will shrink

        setProducts((prevProducts) => prevProducts.filter((product) => product._id!.toString() !== productId));
        break;
      default:
        console.log('no op');
    }
  };

  const onClickDeleteProduct = (productID: string) => {
    setProductIdForDeleteModal(productID);
    setShowingDeleteConfirmation(true);
  };

  const onConfirmDeleteProduct = () => {
    if (!productIdForDeleteModal) return;
    api
      .deleteProduct(productIdForDeleteModal)
      .then(() => {
        setShowingDeleteConfirmation(false);
        onProductCreatedModifiedOrDeleted(productIdForDeleteModal, ProductAction.DELETED);
      })
      .catch((err) => {
        console.error('error deleting product');
        console.error(err);
      });
  };

  const onDataExportSubmitted = async (exportName: string) => {
    if (importalTableRef.current) {
      try {
        const { data: createdProductDataExport } = await api.createProductExport({
          exportName: exportName,
          tableState: importalTableRef.current.getTableConfig(),
        });

        // @ts-ignore
        let dataExport: DataExportProductCSV = {};
        while (dataExport.status !== DataExportStatus.COMPLETED) {
          await new Promise((resolve, reject) => {
            setTimeout(resolve, 1000);
          });
          const { data } = await api.getDataExport(createdProductDataExport._id.toString());
          if (data.status === DataExportStatus.ERROR) {
            throw new Error('Data Export Failed During Processing');
          }
          dataExport = data;
        }

        return dataExport;
      } catch (err) {
        console.error('error creating product data export');
        console.error(err);
        throw err;
      }
    } else {
      console.error('no ref for ImportalTable');
    }
  };

  const onClickCalculateComplianceSummaries = async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      setLoadingText('Calculating compliance summaries...');
      await api.calculateComplianceSummaries();
      setLoadingText('Checking for active jobs...');
      await checkForActiveJobs();
      setLoadingText('Fetching products...');
      await getProducts();
      setLoadingText('Fetching business and notifications...');
      await getBusinessAndNotifications();
      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('Successfully completed all tasks!');
    } catch (err) {
      setErrorText('Error getting compliance notifications');
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  // Menu Component boiler plate
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteProductList = () => {
    api
      .deleteProducts()
      .then((response) => {
        setProducts([]);
        setDeleteConfirmOpen(false);
        handleMenuClose();
      })
      .catch((err) => {
        console.error('error deleting products', err);
      });
  };

  const handleOpenProductFieldsModal = () => {
    setEditProductFieldsOpen(true);
  };

  const handleCloseProductFieldsModal = () => {
    setEditProductFieldsOpen(false);
  };

  return (
    <>
      <div className="product-library-header">
        <div className="business-header-text">
          <Typography sx={{ color: theme.palette.primary.main, fontSize: '26px' }}>Product Library</Typography>
          <div className="universal-subheader">View your products and their corresponding compliance summary.</div>
        </div>
        <div id={'action-menu'} className="right-button-container">
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                setExportProductDataOpen(true);
                handleMenuClose();
              }}
            >
              Export Products
            </MenuItem>
            <MenuItem
              onClick={() => {
                onClickCalculateComplianceSummaries();
                handleMenuClose();
              }}
            >
              Recalculate Compliance Summaries
            </MenuItem>
            <MenuItem disabled={true}>
              Upload Products:
              <br />
              Drag a File onto the Library
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setDeleteConfirmOpen(true);
              }}
            >
              Delete Products
            </MenuItem>
          </Menu>
        </div>
      </div>

      <DeleteConfirmationModal
        open={deleteConfirmOpen}
        title={'Product List'}
        itemName={'product list'}
        onClose={setDeleteConfirmOpen}
        onDelete={handleDeleteProductList}
      />

      <AddOrEditAProductModal
        open={addOrEditAProductOpen}
        productID={productIDForViewingInModal}
        productFieldsConfig={productFieldsConfig}
        onProductCreatedOrModified={onProductCreatedModifiedOrDeleted}
        onClose={() => setAddOrEditAProductOpen(false)}
      />

      <UploadProductsDataModal
        open={uploadProductsOpen}
        onClose={() => setUploadProductsOpen(false)}
        onSubmit={productUploadSubmitted}
        dataImport={dataImportForViewingInModal}
        productFieldsConfig={productFieldsConfig}
        files={productLists}
      />

      <ExportProductDataModal
        open={exportProductDataOpen}
        onClose={() => setExportProductDataOpen(false)}
        onDataExportSubmitted={onDataExportSubmitted}
      />

      <EditProductFieldsModal
        open={editProductFieldsOpen}
        onClose={() => setEditProductFieldsOpen(false)}
        onFieldsEdited={refreshProductFieldsConfig}
        refreshProducts={getProducts}
      />

      <EditTableSettingsModal
        open={editTableSettingsOpen}
        onClose={() => setEditTableSettingsOpen(false)}
        onTableSettingsEdited={() => {}}
      />

      <ConfirmModal
        open={showingDeleteConfirmation}
        onClose={() => {
          setShowingDeleteConfirmation(false);
        }}
        onConfirm={onConfirmDeleteProduct}
        message="Are you sure you want to delete this product?"
        confirmButtonText={'Delete Product'}
        cancelButtonText={'Cancel'}
      />

      {activeJobs && activeJobs.length ? (
        <ImportalCard title="Active Jobs" style={{ marginBottom: '32px' }}>
          <ActiveJobsTable activeJobs={activeJobs} />
        </ImportalCard>
      ) : null}

      <Dropzone onDrop={(acceptedFiles) => handleFilesDropped(acceptedFiles)} noClick>
        {({ getRootProps, getInputProps }) => (
          <>
            <input {...getInputProps()} />
            <div {...getRootProps()}>
              {products.length === 0 ? (
                <ImportalCard
                  title={'Upload Your Products'}
                  topRightActionButton={
                    <Tooltip title="Configure Columns">
                      <IconButton onClick={() => setEditProductFieldsOpen(true)}>
                        <Settings></Settings>
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <div className="products-list-upload-container">
                    <div>
                      <div>
                        Looks like you don't have any products yet. Drag and drop your product list anywhere on the card
                        and we'll do the rest.
                      </div>
                    </div>
                  </div>
                </ImportalCard>
              ) : (
                <>
                  {notifications.length > 0 &&
                    closeInfoAlert &&
                    complianceNotifications(notifications, setCloseInfoAlert)}
                  <ProductTable
                    ref={importalTableRef}
                    products={products}
                    embeddedStyling={false}
                    productFieldsConfig={productFieldsConfig}
                    onClickEditProduct={onClickEditProduct}
                    onClickDeleteProduct={onClickDeleteProduct}
                    onClickSettings={() => {
                      setEditTableSettingsOpen(true);
                    }}
                    productActionsButton={
                      <ImportalPrimaryButton
                        style={{
                          width: '120px',
                          borderRadius: '8px',
                          height: '40px',
                          fontSize: '12px',
                        }}
                        onClick={(e) => handleMenuClick(e as React.MouseEvent<HTMLElement>)}
                        text="Actions"
                        endIcon={<ArrowDropDownIcon />}
                      />
                    }
                    openModal={handleOpenProductFieldsModal}
                    isProductModal={true}
                  />
                </>
              )}
            </div>
          </>
        )}
      </Dropzone>
      <DataImportLoader
        loadingState={{ loadingStatus }}
        loadingText={loadingText}
        successText={successText}
        errorText={errorText}
      />
    </>
  );
}
