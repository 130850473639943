import React, { useState } from 'react';
import { CircularProgress, Fab, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ImageIcon from '@mui/icons-material/Image';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import './UploadProductImages.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useAPI } from '@/api/APIContext';
import { Document, DocumentType } from 'common/interfaces/document';

const Input = styled('input')({
  display: 'none',
});

interface Props {
  onNextClicked: (photos: Document[]) => void;
}

export default function UploadProductImages({ onNextClicked }) {
  const api = useAPI();

  const [files, setFiles] = useState<any>([]);
  const [fileURLs, setFileURLs] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(false);

  const uploadPhoto = async (file: File) => {
    const newPhotoDocument: Document = {
      type: DocumentType.HTS_CLASSIFICATION_PHOTO,
    } as Document;

    try {
      const { data: document } = await api.createTempDocument(newPhotoDocument, file);
      return document;
    } catch (err) {
      console.error('error uploading photo', err);
      throw err;
    }
  };

  const handleImageRemoval = (index) => {
    let updatedFileURLs = [...fileURLs];
    let updatedFiles = [...files];
    updatedFileURLs.splice(index, 1);
    updatedFiles.splice(index, 1);
    setFileURLs(updatedFileURLs);
    setFiles(updatedFiles);
  };

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }

    let updatedFileURLs: any = [];
    const fileData = [...e.target.files].map((item) => item);
    fileData.forEach((file) => {
      let fileURL = URL.createObjectURL(file);
      updatedFileURLs.push(fileURL);
    });

    setFiles(fileData);
    setFileURLs(updatedFileURLs);
  };

  const renderImageSection = (files) => {
    if (files.length > 0) {
      return (
        <>
          {fileURLs &&
            fileURLs.map((item: any, index: number) => (
              <div key={index} className="image-preview-container">
                <img
                  src={`${item}`}
                  style={{ borderRadius: '4%', maxHeight: '160px', maxWidth: '160px' }}
                  srcSet={`${item}`}
                  alt={item}
                  loading="lazy"
                />
                <Fab
                  onClick={() => handleImageRemoval(index)}
                  color="primary"
                  sx={{
                    height: '25px',
                    minHeight: '0px',
                    width: '25px',
                    marginLeft: '-13px',
                    marginTop: '-10px',
                  }}
                  aria-label="add"
                >
                  <ClearIcon sx={{ fontSize: '16px' }} />
                </Fab>
              </div>
            ))}
        </>
      );
    } else {
      return (
        <div className="upload-hts-images">
          <Button fullWidth startIcon={<ImageIcon />} component="label">
            Upload
            <Input type="file" accept="*" hidden multiple onChange={handleFileUpload} />
          </Button>
        </div>
      );
    }
  };

  return (
    <div className="card-container product-image-container">
      <div className="importing-header-question">
        <Typography>Upload Product Images</Typography>
      </div>
      <div className="card-subtitle">
        <div className="unlading-descriptive-text">
          You can submit up to ten photos of your product. This will help our team get a better understanding of what
          you are importing.
        </div>
      </div>
      <div className="image-preview-section">{renderImageSection(files)}</div>
      <div className="product-image-next-container">
        <div className="next-button-container">
          <Button
            type="submit"
            fullWidth
            className="next-button-green"
            variant="contained"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => {
              setIsLoading(true);
              Promise.all(
                files.map((file) => {
                  return uploadPhoto(file);
                })
              )
                .then((photos: Document[]) => {
                  onNextClicked(photos);
                })
                .catch((err) => {
                  console.error('cannot go to next due to error', err);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            {isLoading ? <CircularProgress color={'inherit'} size={18} /> : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
}
