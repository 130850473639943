import React, { createContext, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ImportalTabs from '@/shared-components/ImportalTabs/ImportalTabs';
import CreateNewReport from '@/pages/reports/CreateNewReport';
import Summary from '@/pages/reports/Summary';
import GeneratedReports from '@/pages/reports/GeneratedReports';
import CustomReports from '@/pages/reports/CustomReports';
import { useAPI } from '@/api/APIContext';
import { Report } from 'common/interfaces/reports';
import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

export interface IReportsPageContext {
  reports: Report[];
  refreshReports: () => void;
}

export const ReportsPageContext = createContext<IReportsPageContext>({
  reports: [],
  refreshReports: () => {},
});

const { Provider } = ReportsPageContext;

export default function ReportsPage() {
  const api = useAPI();
  const [reports, setReports] = useState<Report[]>([]);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const refreshReports = () => {
    api
      .getReportsForUser()
      .then(({ data }) => {
        setReports(data);
      })
      .catch((err) => {
        console.error(err);
        console.error('error getting reports');
      });
  };

  useEffect(() => {
    refreshReports();
  }, []);

  return (
    <ImportalPage
      loadingStatus={loadingStatus}
      loadingText={loadingText}
      successText={successText}
      errorText={errorText}
      header={'Reporting & Analytics'}
      subheader="Generate any compliance data, reporting, or analytics at the click of a button"
    >
      <Box>
        <Provider
          value={{
            reports,
            refreshReports,
          }}
        >
          <ImportalTabs
            tabsConfig={[
              { title: 'Summary', element: <Summary /> },
              { title: 'Generated Reports', element: <GeneratedReports /> },
              { title: 'Custom Reports', element: <CustomReports /> },
              { title: 'New Report', element: <CreateNewReport /> },
            ]}
          />
        </Provider>
      </Box>
    </ImportalPage>
  );
}
