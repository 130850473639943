import { Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../api/APIContext';

interface DataImport {
  id: String;
  status: String;
  type: String;
}

export default function DataImports() {
  const api = useAPI();
  const [dataImports, setDataImports] = useState<DataImport[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    api
      .getDataImports()
      .then(({ data }) => {
        setDataImports(data);
      })
      .catch((error) => {
        // setError(error);
      });
  }, []);

  const navigate = useNavigate();
  const handleItemClick = useCallback(
    (id: string) => {
      navigate(`/data-import/${id}`);
    },
    [navigate]
  );

  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <>
      <Button title="New Data Import" onClick={handleOpen}>
        Add New Import
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Card>
          <CardContent>
            <input type="file" multiple onChange={handleFileUpload} />
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </CardContent>
        </Card>
      </Modal>
      <Card>
        <CardContent title="Data Imports">
          {dataImports.length === 0 && <p>No data imports available</p>}

          <ul>
            {dataImports.map((element: any) => {
              return <li key={element.id}>{element.properties.jsonataExpression}</li>;
            })}
          </ul>
        </CardContent>
      </Card>
    </>
  );
}
