import { useMatch } from 'react-router';
import { useAPI } from '@/api/APIContext';
import React, { useContext, useState } from 'react';
import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CreateSessionRequest, Link, SessionType, SessionTypeText } from 'common/interfaces/session';
import { Add, Check, Close } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Mode } from 'common/interfaces/business';
import './BusinessAdminTabLinksCard.css';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import { BusinessPageContext } from './BusinessPage';

export default function BusinessAdminTabLinksCard() {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);

  const match = useMatch({
    path: '/broker/dashboard/businesses/:businessId',
  });
  const api = useAPI();

  const { users, refreshUsers, planMetadata, plan, links, refreshLinks } = useContext(BusinessPageContext);
  const businessId: string | undefined = match?.params.businessId as string;
  const [tooltipVisible, setTooltipVisible] = useState(Array(links.length).fill(false));

  const [localLinks, setLocalLinks] = useState<Link[]>(links);

  const handleAddLink = () => {
    const updatedLinks: Link[] = [
      ...localLinks,
      {
        linkURL: '',
        visits: 0,
        user: '',
        mode: Mode.ADD,
        type: SessionType.CUSTOMS_ONBOARDING,
      },
    ];
    setLocalLinks(updatedLinks);
  };

  const handleLinkChange = (index: number, key: keyof Link, value: any) => {
    const updatedLinks = [...localLinks].map((link, idx) => (idx === index ? { ...link, [key]: value } : link));
    setLocalLinks(updatedLinks);
  };

  const handleEditRow = (indexToUpdate: number) => {
    const updatedLinks = [...localLinks].map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.EDIT } : link
    );
    setLocalLinks(updatedLinks);
  };

  const handleSaveRow = (indexToUpdate: number) => {
    const updatedLinks = [...localLinks].map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.VIEW } : link
    );
    setLocalLinks(updatedLinks);
  };

  const handleCancelEditRow = (indexToUpdate: number) => {
    const updatedLinks = [...localLinks].map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.VIEW } : link
    );
    setLocalLinks(updatedLinks);
  };

  const handleSaveLink = (indexToUpdate) => {
    const linkToUpdate = localLinks[indexToUpdate];
    setLoadingStatus(LoadingStatus.LOADING);

    if (linkToUpdate.mode === Mode.ADD) {
      const newSession: CreateSessionRequest = {
        user: linkToUpdate.user,
        business: businessId,
        sessionType: linkToUpdate.type,
      };

      api
        .createSession(newSession)
        .then((response) => {
          setLoadingStatus(LoadingStatus.SUCCESS);
          refreshLinks();
        })
        .catch((error) => {
          setLoadingStatus(LoadingStatus.ERROR);
        });
    } else if (linkToUpdate.mode === Mode.EDIT) {
      // Assume api.updateLink is a function that handles updating an existing link
      // api.updateLink(businessId, linkToUpdate);
    }

    const updatedLinks = localLinks.map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.VIEW } : link
    );

    setLocalLinks(updatedLinks);
  };

  const CopyableTableRow = ({ link, index, handleDeleteLink }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleCopyToClipboard = (text) => {
      const showTooltip = () => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 1400);
      };

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log('Text copied to clipboard');
            showTooltip();
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
          });
      } else {
        // Fallback for older browsers
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          console.log('Text copied to clipboard');
          showTooltip();
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    };

    return (
      <TableRow key={link.linkURL}>
        <TableCell>{SessionTypeText[link.type]}</TableCell>
        <TableCell>{link.email}</TableCell>
        <TableCell>
          <LightTooltip open={tooltipVisible} title={'Link copied to clipboard'}>
            <a
              className="link-container"
              style={{ fontSize: '14px!important', fontWeight: '700', color: '#388e3c' }}
              onClick={() => handleCopyToClipboard(link.linkURL)}
            >
              {link.linkURL}
            </a>
          </LightTooltip>
        </TableCell>
        <TableCell>{link.visits}</TableCell>
        <TableCell>
          <IconButton>
            <DeleteIcon onClick={() => handleDeleteLink(link, index)} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const handleDeleteLink = (link: Link, index: number) => {
    setLoadingStatus(LoadingStatus.LOADING);
    if (link.sessionId) {
      api.deleteSession(link.sessionId as string).then((response) => {
        setLoadingStatus(LoadingStatus.SUCCESS);
        refreshLinks();
      });
    } else {
      const newLinks = [...links];
      newLinks.splice(index, 1);
      setLocalLinks(newLinks);
    }
  };

  const renderRow = (link, index) => {
    if (link.mode === Mode.EDIT || link.mode === Mode.ADD) {
      return (
        <TableRow key={index}>
          <TableCell>
            <Select
              id="demo-simple-select"
              fullWidth
              onChange={(e) => handleLinkChange(index, 'type', e.target.value)}
              size="small"
              value={link.type || ''}
            >
              {Object.keys(SessionType).map((key) => {
                return (
                  <MenuItem key={key} value={SessionType[key]}>
                    {SessionTypeText[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </TableCell>
          <TableCell>
            <Select
              id="demo-simple-select"
              fullWidth
              size="small"
              value={link.user}
              onChange={(e) => handleLinkChange(index, 'user', e.target.value)}
            >
              {users?.map((businessUser) => (
                <MenuItem key={businessUser.email} value={businessUser.email}>
                  {businessUser.email}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>{link.visits}</TableCell>
          <TableCell>
            <IconButton onClick={() => handleCancelEditRow(index)}>
              <Close />
            </IconButton>
            <IconButton onClick={() => handleSaveLink(index)}>
              <Check />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return <CopyableTableRow key={link.linkURL} link={link} index={index} handleDeleteLink={handleDeleteLink} />;
    }
  };

  return (
    <>
      <Card className="my-shipments-card">
        <CardContent style={{ position: 'relative' }}>
          <div className="header-question">
            <Typography>Links</Typography>
          </div>
          <IconButton
            style={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: 'grey',
            }}
            onClick={handleAddLink}
          >
            <Add />
          </IconButton>
          <div className="header-question">
            {localLinks && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>Type</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>User</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>URL</TableCell>
                      <TableCell sx={{ fontWeight: '700', color: '#525256' }}>Visits</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="user-row">{localLinks.map((link, index) => renderRow(link, index))}</TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </CardContent>
      </Card>
      <DataImportLoader
        loadingState={{ loadingStatus }}
        loadingText={'Saving link...'}
        successText={'Successfully saved link'}
        errorText={'Error saving link'}
      />
    </>
  );
}
