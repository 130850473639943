import React, { useState } from 'react';
import { Button, Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './VesselSnackbar.css';

export default function VesselSnackbar({ handleClose, handleManifestModalOpen }) {
  return (
    <>
      <Card className="call-to-action-snackbar-container">
        <div className="icon-button-container">
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              right: 4,
              top: 4,
              color: 'white',
            }}
          >
            <CloseIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </div>
        <h3 style={{ fontSize: '13px' }}>Did you know?</h3>
        <div style={{ fontSize: '11px' }}>
          You can request that your vessel manifests be confidential? We'll file it on your behalf.
        </div>
        <div className="upgrade-container">
          <Button
            onClick={handleManifestModalOpen}
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: '12px',
              textTransform: 'none',
              borderColor: 'white',
              textDecoration: 'underline',
              backgroundColor: '#77A8F1',
            }}
            variant="text"
            className="upgrade-link"
            href="#"
          >
            Request it here
          </Button>
        </div>
      </Card>
    </>
  );
}
