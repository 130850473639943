import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import './VMCRPayment.css';
import ManifestContext from '../manifest-modal/ManifestProvider';
import CardPlan from '@/shared-components/OfferedPurchaseCard/CardPlan';

import VMCRReviewOrder from './VMCRReviewOrder';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { useAPI } from '@/api/APIContext';
import { OfferedPurchaseAny } from 'common/interfaces/plan';

export default function VMCRPayment({ handleNext, handleBack }) {
  const api = useAPI();
  const activePlan = useActivePlan();
  const { currentManifestRequest } = useContext(ManifestContext);

  const [activeStep, setActiveStep] = React.useState<String>('planDetails');

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [availablePurchases, setAvailablePurchases] = useState<OfferedPurchaseAny[]>([]);
  const [userSelectedPurchase, setUserSelectedPurchase] = useState<OfferedPurchaseAny | undefined>();

  const upgradeRef = useRef<HTMLButtonElement>(document.createElement('button'));

  const onPayClick = async () => {
    setIsLoading(true);

    api
      .createPurchase({
        plan: userSelectedPurchase!.key,
      })
      .then(function (response) {
        setIsLoading(false);
        handleNext();
        setActiveStep('paymentSuccess');
      })
      .catch(function (error) {
        console.error('error creating purchase vmcr payment');
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    api.getOfferedVMCRPurchases().then(({ data: vmcrOfferedPurchases }) => {
      setAvailablePurchases(vmcrOfferedPurchases);
    });
  }, []);

  const renderPaymentStep = (step): any => {
    switch (step) {
      case 'planDetails':
        return (
          <div className="grid-container">
            {availablePurchases?.map((planItem) => (
              <CardPlan
                key={`card-plan-${planItem.key}`}
                offeredPurchase={planItem}
                onSelected={() => {
                  setUserSelectedPurchase(planItem);
                  setActiveStep('reviewOrder');
                }}
              />
            ))}
          </div>
        );
      case 'reviewOrder':
        return (
          <VMCRReviewOrder
            setOpen={setOpenModal}
            open={openModal}
            upgradeRef={upgradeRef}
            plan={userSelectedPurchase?.key}
            setActiveStep={setActiveStep}
            singlePlanDetail={userSelectedPurchase}
          />
        );
      case 'paymentSuccess':
        handleNext();
        break;
    }
  };

  return (
    <div className="card-container">
      <div className="importing-card-container">
        <div className="vmcr-description-title">Your Payment</div>
        <div className="vmcr-business-info-container">{renderPaymentStep(activeStep)}</div>
        <div className="next-button-container">
          <Button fullWidth className="back-button-text" variant="text" onClick={handleBack}>
            Back
          </Button>
          <Button
            type="submit"
            fullWidth
            sx={{ width: '240px!important' }}
            className="next-button-green"
            onClick={onPayClick}
            variant="contained"
          >
            Submit Request and Pay
          </Button>
        </div>
      </div>
    </div>
  );
}
