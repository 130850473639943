import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardContent, IconButton, Typography, useTheme } from '@mui/material';
import ShowMoreMenu from './ShowMoreMenu';
import AddNewCard from './AddNewCard';
import cardLogo from '@/components/card-logo/CardLogo';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import './Billing.css';
import { useAPI } from '@/api/APIContext';
import CloseIcon from '@mui/icons-material/Close';
import '@/components/plans-and-pricing/PricingPlans.css';

const Billing = ({ handleLoading, isLoading }) => {
  const api = useAPI();
  const [cardDetails, setCardDetails] = useState([]);
  const [newCard, setNewCard] = useState(true);
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const getCardDetails = useCallback(() => {
    handleLoading(true);
    api
      .getSavedCardsForPlan()
      .then(function (response) {
        setCardDetails(response.data);
        response.data.length && setNewCard(false);
        handleLoading(false);
      })
      .catch(function (error) {
        console.error('error getting saved cards for plan');
        console.error(error);
        handleLoading(false);
      });
  }, [handleLoading]);

  useEffect(() => {
    getCardDetails();
  }, [getCardDetails]);

  const onDefaultClick = async (card: any) => {
    handleLoading(true);
    api
      .setActivePlanDefaultCard({ paymentMethodId: card.id })
      .then(function (response) {
        getCardDetails();
        handleLoading(false);
      })
      .catch(function (error) {
        console.error('error setting default card on plan');
        console.error(error);
        handleLoading(false);
      });
  };

  return (
    <Card className="settings-card-container">
      <CardContent>
        <div className="billing-header-container">
          <div className="header-question">
            <Typography>Billing</Typography>
          </div>
          <div className="next-button-container add-card-container">
            <Button variant="contained" color="info" className="next-button-green" onClick={handleClickOpen}>
              Add Card
            </Button>
          </div>
        </div>
        <SimpleDialog
          open={open}
          onClose={handleClose}
          getCardDetails={getCardDetails}
          cardDetails={cardDetails}
          setNewCard={setNewCard}
          handleLoading={handleLoading}
          isLoading={isLoading}
        />
        {!newCard && cardDetails.length && (
          <>
            <div className="universal-subheader">Saved Cards</div>
            <div>
              {cardDetails.map((item: any, index: number) => {
                return (
                  <div className="card-main-wrapper" key={index}>
                    <div className="card-content">
                      <div className="img-icon">{cardLogo(item.card.brand)}</div>
                      <div className="card-element">
                        <p>{item.billing_details.name}</p>
                        <div style={{ color: 'slategray' }}>
                          <span>**** **** **** </span>
                          <span>{`${item?.card?.last4} (${item.card.exp_month}/${item.card.exp_year})`}</span>
                        </div>
                      </div>
                    </div>
                    <div className="radio-btn">
                      {item?.isDefault ? (
                        <Typography sx={{ color: 'green' }}>Default</Typography>
                      ) : (
                        <Button onClick={() => onDefaultClick(item)}>Set as Default</Button>
                      )}
                      <ShowMoreMenu card={item} disable={item?.isDefault} />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {newCard && (
          <>
            <div className="universal-subheader no-cards-on-file">
              Looks like we don't have any cards on file! Hit the Add Card button to add one.
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

function SimpleDialog(props: any) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={handleClose} open={open}>
      <div className="card-modal-container">
        <DialogTitle>Add Card</DialogTitle>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <AddNewCard
          getCardDetails={props.getCardDetails}
          cardDetails={props.cardDetails}
          setNewCard={props.setNewCard}
          handleLoading={props.handleLoading}
          isLoading={props.isLoading}
          handleClose={handleClose}
        />
      </div>
    </Dialog>
  );
}

export default Billing;
