import * as React from 'react';
import { useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import ComplianceSummary from '@/components/compliance-tools/ComplianceSummary';
import CallToActionSnackbar from '@/components/call-to-action-snackbar/CallToActionSnackbar';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import DataImportLoader, { LoadingState, LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { ImportalStepper } from '@/shared-components/ImportalStepper/ImportalStepper';
import { ComplianceSummaryContext, CurrencyCode, Input, WeightUnit } from 'common/interfaces/complianceSummary';
import { useAPI } from '@/api/APIContext';
import { ComplianceSummaryRoute, TransportationType } from '@/api';
import FirstStepContainer from '@/shared-components/compliance-tools/FirstStepContainer';
import CargoValue from '@/shared-components/compliance-tools/CargoValue';
import CountryOfOrigin from '@/shared-components/compliance-tools/CountryOfOrigin';
import ModeOfTransportation from '@/shared-components/compliance-tools/ModeOfTransportation';
import { Code } from 'common/interfaces/code';
import { QuantityTotals } from '@/shared-components/compliance-tools/QuantityTotals';
import { getUnitOfMeasureForCode, quantityDataRequiredForDuty } from 'common/utilities/quantityDataRequiredForDuty';
import { AxiosError } from 'axios';
import LegalText from '@/shared-components/LegalText/LegalText';

export default function ComplianceOne() {
  const api = useAPI();
  const theme = useTheme();

  const activePlan = useActivePlan();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loadingState, setLoadingState] = useState<LoadingState>({ loadingStatus: LoadingStatus.NOT_LOADING });
  const [showUpgradeBanner, setShowUpgradeBanner] = useState(activePlan.isFreePlan);

  const [htsCode, setHTSCode] = useState<Code | undefined>();
  const [complianceSummaryInput, setComplianceSummaryInput] = useState<Input>({ htsno: '', countryOfOrigin: '' });
  const [complianceSummary, setComplianceSummary] = useState<ComplianceSummaryContext | undefined>();

  const totalSteps = () => {
    return steps.length;
  };

  const handleCloseCallToAction = () => {
    setShowUpgradeBanner(false);
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = async () => {
    if (activeStep === 3) {
      await doComplianceSearch(handleNext);
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      newCompleted[activeStep + 1] = false;
      setCompleted(newCompleted);
      return;
    } else {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      newCompleted[activeStep + 1] = false;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  const handleReset = () => {
    setHTSCode(undefined);
    setComplianceSummaryInput({ htsno: '', countryOfOrigin: '' });
    setComplianceSummary(undefined);
    setActiveStep(0);
    setCompleted({});
  };

  const doComplianceSearch = async (onSuccess: () => void) => {
    try {
      setLoadingState({ loadingStatus: LoadingStatus.LOADING });
      const { data } = await api.getComplianceSummary(ComplianceSummaryRoute.COMPLIANCE_ONE, complianceSummaryInput);
      setComplianceSummary(data);
      setLoadingState({ loadingStatus: LoadingStatus.SUCCESS });
      onSuccess();
    } catch (err) {
      console.error('error getting compliance summary');
      console.error(err);
      let axiosError = err as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const responseData = axiosError.response.data as any;
        console.error(responseData);
        if (responseData.errors && responseData.errors.length > 0) {
          setLoadingState({
            loadingStatus: LoadingStatus.ERROR,
            error: responseData.errors[0],
          });
        }
      } else {
        setLoadingState({
          loadingStatus: LoadingStatus.ERROR,
        });
      }
    }
  };

  interface Step {
    name: string;
    element: React.JSX.Element;
  }

  const steps: Step[] = [
    {
      name: 'HTS Code',
      element: (
        <FirstStepContainer
          htsCode={htsCode}
          setHTSCode={(htsCode?: Code) => {
            setHTSCode(htsCode);
            const quantityInfo = {};
            if (htsCode && quantityDataRequiredForDuty(htsCode)) {
              // @ts-ignore
              quantityInfo.totalUnitsMeasureOfQuantity = getUnitOfMeasureForCode(htsCode)!;
            }
            setComplianceSummaryInput({
              ...complianceSummaryInput,
              htsno: htsCode?.htsno || '',
              // @ts-ignore (ignoring string to decimal)
              totals: {
                ...complianceSummaryInput.totals,
                ...quantityInfo,
              },
            });
          }}
          totalWeight={complianceSummaryInput.totals?.totalWeight?.toString()}
          onTotalWeightSelected={(totalWeight?: string) => {
            setComplianceSummaryInput({
              ...complianceSummaryInput,
              totals: {
                ...complianceSummaryInput?.totals,
                // @ts-ignore (ignoring string to decimal)
                totalWeight: totalWeight,
                totalWeightUnits: WeightUnit.KG,
              },
            });
          }}
          quantityInfo={complianceSummaryInput.totals}
          onQuantityInfoSelected={(quantityInfo?: QuantityTotals) => {
            setComplianceSummaryInput({
              ...complianceSummaryInput,
              // @ts-ignore (ignoring string to decimal)
              totals: {
                ...complianceSummaryInput?.totals,
                ...quantityInfo,
              },
            });
          }}
          onNext={handleComplete}
        />
      ),
    },
    {
      name: 'Cargo Value',
      element: (
        <CargoValue
          cargoValue={complianceSummaryInput.totals?.totalValue?.toString() || ''}
          onCargoValueSet={(cargoValue?: any) => {
            setComplianceSummaryInput({
              ...complianceSummaryInput,
              totals: {
                ...complianceSummaryInput?.totals,
                totalValue: cargoValue,
                totalValueCurrency: CurrencyCode.USD,
              },
            });
          }}
          onNext={handleComplete}
          onBack={handleBack}
        />
      ),
    },
    {
      name: 'Country of Origin',
      element: (
        <CountryOfOrigin
          selectedCountryCode={complianceSummaryInput.countryOfOrigin}
          onCountryCodeSelected={(countryCode?: string) => {
            setComplianceSummaryInput({
              ...complianceSummaryInput,
              countryOfOrigin: countryCode || '',
            });
          }}
          onNext={handleComplete}
          onBack={handleBack}
        />
      ),
    },
    {
      name: 'Transportation Type',
      element: (
        <ModeOfTransportation
          modeOfTransportation={complianceSummaryInput.transportationType}
          onModeOfTransportationSelected={(tranportationType: TransportationType) => {
            setComplianceSummaryInput({
              ...complianceSummaryInput,
              transportationType: tranportationType,
            });
          }}
          onNext={handleComplete}
          onBack={handleBack}
        />
      ),
    },
    {
      name: 'Summary',
      element: <ComplianceSummary htsCode={htsCode} complianceSummary={complianceSummary!} handleReset={handleReset} />,
    },
  ];

  const getElements = () => {
    return steps.map((step) => step['element']);
  };

  return (
    <div className="content-step-wrapper">
      <div className="main-step-content">
        <div className="main-page-header">
          <Typography sx={{ color: theme.palette.primary.main, fontSize: '26px' }}>Compliance One</Typography>
        </div>
        <div className="universal-subheader">
          Our flagship all-in-one compliance tool will give your product a comprehensive compliance summary.
        </div>
        <ImportalStepper
          steps={[
            { label: 'HTS Code' },
            { label: 'Cargo Value' },
            { label: 'Country of Origin' },
            { label: 'Transportation Type' },
            { label: 'Summary' },
          ]}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completed={completed}
        />
        <DataImportLoader
          loadingState={loadingState}
          loadingText={'Compliance greatness loading...'}
          successText={'Locked and loaded!'}
          errorText={'Whoops! Looks like we had an issue.'}
        />
        {getElements()[activeStep]}
        <div className="need-help">
          {showUpgradeBanner && <CallToActionSnackbar handleClose={handleCloseCallToAction} />}
        </div>
      </div>
      <LegalText />
    </div>
  );
}
