import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ADDModal.css';

export interface SimpleDialogProps {
  row: any;
  open: boolean;
  onClose: (e?: any) => void;
}

export default function ADDModal(props: SimpleDialogProps) {
  const { onClose, open, row } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="contact-us-dialog-container">
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <div>
          <div>
            <div className="header-question">
              <Typography>Anti-Dumping Duties</Typography>
            </div>
            <div className="pga-description-subheader">
              The fair trade enforcement tariffs against foreign 'dumping'
            </div>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          </div>
          <div className="pga-description-title">Breaking it down</div>
          <div className="pga-description-subheader">
            Anti-dumping duties are tariffs imposed by the United States to counteract the practice of "dumping."
            Dumping occurs when foreign manufacturers export goods to the United States at prices below their fair
            market value or below what they would typically sell for in their home country. This can harm domestic
            industries by undercutting prices and creating unfair competition. These duties are dependent on a number of
            different variables, such as product classification and country of origin. These duties are one of the most
            commonly missed duties by importers. Our compliance experts will need to sit down with you to understand all
            of these different variables to get a precise duty amount for your product.{' '}
          </div>
          <div className="pga-questions-container">
            <div>Questions? We're here - set up a time with one of our experts.</div>
            <div className="next-button-container">
              <a
                style={{ textDecoration: 'none' }}
                target="_blank"
                href="https://calendly.com/alex-0rfp/30min"
                rel="noreferrer"
              >
                <Button sx={{ minWidth: '200px' }} variant="contained" className="next-button-green">
                  Set up a 1x1
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
