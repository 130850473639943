import React from 'react';
import { Card, CardContent, useTheme } from '@mui/material';
import './CustomsOnboardingPage.css';
import FullOnboarding from '@/components/full-onboarding/FullOnboarding';

export default function CustomsOnboardingPage() {
  const theme = useTheme();

  return (
    <div className="customs-onboarding-page-container">
      <div className="powered-by-importal-container">
        <div className="powered-by-importal-logo">
          <img src={process.env.PUBLIC_URL + '/IMPORTAL-2.png'} width="240" height="156" alt="logo" />
        </div>
      </div>

      <Card sx={{ minWidth: 275 }} className="card-container" style={{ alignSelf: 'center' }}>
        <CardContent>
          <FullOnboarding />
        </CardContent>
      </Card>
    </div>
  );
}
