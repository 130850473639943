import { Container, Typography } from '@mui/material';
import ImportalCard from '@/shared-components/ImportalCard';
import ReportList from '@/pages/reports/ReportList';
import { ImportalBreadCrumbs } from '@/shared-components/ImportalBreadCrumbs';
import React, { useContext, useEffect, useState } from 'react';
import { ReportsPageContext } from '@/pages/reports/ReportsPage';
import { Report, ReportType } from 'common/interfaces/reports';
import ReportDefault from '@/pages/reports/ReportDefault';
import ReportAdvanced from '@/pages/reports/ReportAdvanced';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';

export default function CustomReports() {
  const betaFeatures = useBetaFeatures();
  const { reports } = useContext(ReportsPageContext);
  const [customReports, setCustomReports] = useState<Report[]>(reports);
  const [selectedReport, setSelectedReport] = useState<Report | undefined>();

  useEffect(() => {
    const customReports = reports
      .filter((report) => report.reportType === ReportType.CUSTOM)
      .sort((a, b) => new Date(b.lastRun).getTime() - new Date(a.lastRun).getTime());
    setCustomReports(customReports);
  }, [reports]);

  const onReportSelected = (report?: Report) => {
    setSelectedReport(report);
  };

  const onClickAddReport = () => {};

  return (
    <Container maxWidth={'xl'} disableGutters>
      {/*TODO: first pass at bread crumbs , this one is state AND a visual element container, */}
      <ImportalCard title={'Custom Reports'}>
        <ImportalBreadCrumbs
          elements={[
            {
              render: ({ popItem, pushItem }) => (
                <>
                  <ReportList
                    reports={customReports}
                    onReportSelected={(report?: Report) => {
                      pushItem(report);
                    }}
                  />
                </>
              ),
              label: 'Custom Reports',
            },
            {
              render: ({ popItem, pushItem, item }) => (
                <ReportDefault
                  report={item}
                  onAdvancedClicked={(report: Report) => {
                    pushItem(report);
                  }}
                />
              ),
              label: (report: Report) => {
                return report.name;
              }, // could be a string or a function
            },
            {
              render: ({ popItem, pushItem, item }) => <ReportAdvanced report={item} />,
              label: 'Advanced',
            },
          ]}
        />
      </ImportalCard>
    </Container>
  );
}
