import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './ReferenceNumber.css';

interface Props {
  referenceNumber?: string;
  onReferenceNumberSet: (referenceNumber?: string) => void;
  onNext: () => void;
}

export default function ReferenceNumber({ referenceNumber, onReferenceNumberSet, onNext }) {
  const handleReference = (e: any) => {
    onReferenceNumberSet(e.target.value);
  };

  return (
    <Box>
      <div className="importing-header-question">
        <Typography>Do you have a reference number?</Typography>
      </div>
      <div className="optional-descriptive-text">
        <p>
          <strong>Optional:</strong> Add your internal reference number to easily track and monitor your import. If you
          don't have one, feel free to hit the Next button and we'll generate one on your behalf.
        </p>
      </div>
      <div className="cargo-value-text-field">
        <TextField
          required
          fullWidth
          placeholder="Example: RXZ7-AA239"
          id="reference"
          label=""
          onChange={handleReference}
          value={referenceNumber}
          name="reference-number"
        />
      </div>
      <div className="next-button-container">
        <Button
          onClick={onNext}
          fullWidth
          className="next-button-green"
          endIcon={<KeyboardArrowRightIcon />}
          variant="contained"
          disabled={!referenceNumber}
        >
          Next
        </Button>
      </div>
    </Box>
  );
}
