import { createContext } from 'react';
import { VMCR } from 'common/interfaces/vmcr';

export type IManifestContext = {
  currentManifestRequest: VMCR;
  setCurrentManifestRequest: (arg0: VMCR) => void;
};
const ManifestContext = createContext<IManifestContext>({
  currentManifestRequest: {},
  setCurrentManifestRequest: (manifestRequest) => {},
});

export default ManifestContext;
